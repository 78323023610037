import { Input } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

const EditableCell = ({ getValue, row, column, table, addRow = true }) => {
    const initValue = getValue();
    const { darkMode } = useSelector((state) => state.settings);
    const {
        focusedRow,
        setFocusedRow,
        numberCopy,
        setNumberCopy,
        updateData,
        updateTable,
        deleteRow,
        spin,
        type,
    } = table.options.meta;
    const [value, setValue] = useState(initValue);
    const numberRef = useRef(null);

    const onBlur = () => {
        if (value !== initValue) updateData(row.index, column.id, value);
    };

    const addNewRow = () => {
        updateData(row.index, column.id, value, addRow);
        setFocusedRow((fr) => fr + 1);
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onBlur();
            e.preventDefault();
            addNewRow();
        }
        if (
            e.key === 'Delete' &&
            value === '' &&
            table.options.data?.length > 1
        ) {
            e.preventDefault();
            const newIndex = row.index > 0 ? row.index - 1 : 0;
            deleteRow(row.index);
            setFocusedRow(newIndex);
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
            setNumberCopy(true);
        }
    };

    useEffect(() => {
        if (numberRef?.current) return;
        if (row.index === focusedRow) {
            numberRef?.current?.focus();
        }
    }, [focusedRow, row.index, numberRef]);

    useEffect(() => {
        setValue(initValue);
    }, [initValue]);

    useEffect(() => {
        if (row.index === 0) {
            setFocusedRow(0);
        } else {
            setFocusedRow(table.options.data.length - 1);
        }
    }, [row.index, setFocusedRow, table]);

    return (
        <Input
            as={numberCopy == false && NumberFormat}
            prefix={'+'}
            value={value}
            onChange={(e) => {
                e.preventDefault();
                let inputValue = e.target.value;

                if (inputValue.indexOf('+') !== 0) {
                    inputValue = '+' + inputValue.replace(/\+/g, '');
                }

                const number = inputValue.replace(/[^\d\s\n;:,\n]/g, '');
                updateTable(row.index, column.id, number, setValue);
            }}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            isNumericString={!numberCopy}
            allowNegative={false}
            decimalScale={0}
            fixedDecimalScale={!numberCopy}
            getInputRef={(el) => (numberRef.current = el)}
            ref={numberRef}
            autoFocus={row.index === focusedRow}
            name="number"
            variant="flushed"
            size="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            aria-label={`${type}-${column.id}-input-${row.index}`}
            borderColor={darkMode ? 'dark.tcap-borderGray' : 'light.text-weak'}
            _placeholder={{
                color: darkMode ? '#6C757D' : null,
            }}
            placeholder="Please enter number in E164 format."
            className={`${spin ? 'animate-[pulse_0.5s_cubic-bezier(0.4,0,0.6,1)_infinite]' : ''}`}
            pe={8}
            onPaste={(event) => {
                const numberData = event.clipboardData.getData('text');
                if (numberData.length > 0) {
                    event.clipboardData.getData('text');
                    setNumberCopy(true);
                }
            }}
        />
    );
};

export default EditableCell;
