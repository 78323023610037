import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Text from 'components/v4/Text';
import { IconComponent } from '../IconComponent';
const CALLING_DISABLED = 'Calling Disabled';

export default function TpmStatus({ isTrialExpired, cardStatus }) {
    const { darkMode } = useSelector((state) => state.settings);

    if (cardStatus?.tooltipIcon || isTrialExpired) {
        return (
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                <Text className="ml-1 truncate">
                    {isTrialExpired ? CALLING_DISABLED : cardStatus.statusLabel}
                </Text>

                <span className="mx-1 inline-flex flex-shrink-0 self-center">
                    <Tooltip
                        label={
                            isTrialExpired &&
                            cardStatus?.statusLabel !== CALLING_DISABLED
                                ? 'Your trial has expired and calling services have been disabled.  Please contact your provider. '
                                : cardStatus?.tooltip ||
                                  cardStatus?.statusDisplay
                                      ?.statusDisplayExtended
                        }
                        hasArrow
                        placement="bottom">
                        <IconButton
                            icon={
                                <IconComponent
                                    icon={
                                        isTrialExpired
                                            ? 'Warning'
                                            : cardStatus?.tooltipIcon
                                    }
                                    color={darkMode ? 'white' : 'black'}
                                />
                            }
                            size="xs"
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        />
                    </Tooltip>
                </span>
            </Box>
        );
    }

    return <Text>{cardStatus?.statusLabel}</Text>;
}
