import { getAdminPermission } from 'API/AdminUser';
import { useQuery } from '@tanstack/react-query';

export const useGetAdminPermission = (currentCompany, accountScope) => {
    return useQuery({
        queryKey: ['permissionSets', currentCompany],
        queryFn: () => getAdminPermission({ accountScope, currentCompany }),
        enabled: Boolean(currentCompany),
        onSuccess: (data) => {
            return data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        },
        refetchOnWindowFocus: false,
    });
};
