import { axios } from '@/services/axios';

export const changeAdminUser = async ({ formData, scope }) => {
    const res = await axios.put('/AdminUser', formData, {
        headers: {
            'X-RequestScope': scope,
        },
    });
    return res.data;
};

export const postForgotPassword = ({ username, host }) =>
    axios.post('/forgotpassword', { username, host });

export const postMfaReset = (id) =>
    axios.post(`AdminUser/MFA/reset/${id}`, null, {});

export const getAdminPermission = async ({ accountScope, currentCompany }) => {
    const res = await axios.get(
        `/permissionSets/${accountScope}/${currentCompany}`,
        {
            headers: {
                'X-RequestScope': accountScope,
            },
        },
    );
    return res.data;
};

export const getAdminUser = async ({ token, id }) => {
    const res = await axios.get(`/AdminUser/${id}`, {
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const deleteAdminUser = async ({ id, token, scope }) => {
    const res = await axios.delete(`/AdminUser/${id}`, {
        headers: {
            authorization: `Bearer ${token}`,
            'X-RequestScope': scope,
        },
    });
    return res;
};

export const getAllAdminUsers = async (currentCompany) => {
    const res = await axios.get(`/AdminUsers/${currentCompany}`);
    return res.data;
};

export const createAdminUser = async (formData) => {
    const res = await axios.post('/adminuser/', formData);
    return res.data;
};

export const getSSO = async (currentCompany) => {
    const res = await axios.get(`/sso/${currentCompany}`);
    return res.data;
};
