import { useRef, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditRounded } from '@material-ui/icons';
import Button from 'components/v4/Button';
import MaterialTable, {
    RefreshIcon,
} from '@/components/v4/MaterialTable/index';
import { useGetAllAdminUsers } from './new/queries';
import { loadingColumnsConstructor } from '@/components/tables/ReduxTable';
import walkthroughIds from '../walkthroughIds';
import useTableToolbar from 'hooks/useTableToolbar';

const AdminAccounts = () => {
    const tableRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const defaultColumns = [
        {
            title: 'Email',
            field: 'email',
            defaultSort: 'asc',
        },

        {
            title: 'First Name',
            field: 'firstName',
        },
        {
            title: 'Last Name',
            field: 'lastName',
        },
        {
            title: 'Permission',
            field: 'permissionSet',
        },
        {
            title: 'Last Logged In',
            field: 'lastLogin',
            type: 'datetime',
        },
        {
            title: 'Active',
            field: 'isActive',
            width: '5%',
            type: 'boolean',
        },
    ];

    const [tableData, setTableData] = useState({
        columns: defaultColumns,
        data: [],
    });

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { isLoading, refetch, isRefetching, isFetching } =
        useGetAllAdminUsers(currentCompany, setTableData, dispatch);

    const editAdminNavigate = (data) => {
        navigate(`${data.id}/edit`, { state: data });
    };

    const addAdminNavigate = async () => {
        navigate('/admins/create');
    };

    const { refreshFn } = useTableToolbar({
        refetch,
    });

    useEffect(() => {
        if (!(isFetching || isRefetching)) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(tableData.columns),
        [tableData.columns],
    );

    const dummyTable = [];
    const dummyTableLength = 12;
    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    const componentLoadingState = isLoading || isRefetching;

    const renderedColumns = componentLoadingState
        ? loadingColumns
        : defaultColumns;
    const renderedTableData = componentLoadingState
        ? dummyTable
        : tableData.data;

    const tableActions = [
        {
            icon: RefreshIcon,
            tooltip: 'Refresh Admin Users',
            isFreeAction: true,
            onClick: () => {
                tableRef.current &&
                    tableRef.current.dataManager.columns.forEach((item) => {
                        tableRef.current.onFilterChange(item.tableData.id, '');
                    });
                refreshFn();
            },
            hidden: !refreshFn,
        },
        (rowData) => ({
            icon: () => (
                <EditRounded
                    fontSize="small"
                    data-walkthroughid={`${walkthroughIds.ufTableEditBtn}/${rowData?.firstName}`}
                />
            ),
            tooltip: 'Edit Admin User',
            position: 'row',
            onClick: () => {
                editAdminNavigate(rowData);
            },
        }),
    ];

    const header = 'Admin Users';
    const buttonAdd = '+ Admin';
    const addButtonWalkthroughId = walkthroughIds.ufAddAdminUser;

    return (
        <MaterialTable
            tableRef={tableRef}
            data={renderedTableData}
            columns={renderedColumns}
            actions={tableActions}
            title={
                <Button
                    onClick={addAdminNavigate}
                    data-walkthroughid={addButtonWalkthroughId}>
                    {buttonAdd}
                </Button>
            }
            titleName={header}
            options={{
                search: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
            }}
            refreshTable={refreshFn}
        />
    );
};

export default AdminAccounts;
