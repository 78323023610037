import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TableAndModal } from '../../../components';
import config from '../../../config';
import { axios } from '../../../services/axios';

/*
 * Default Columns for TableAndModal Prop
 * title is what is displayed, field is what its getting from the API, lookup is specific rendering of the column
 */
const defaultColumns = [
    {
        title: 'Trunk Name',
        field: 'name',
    },
    {
        title: 'Trunk Type',
        field: 'trunkType.name',
    },
    {
        title: 'Channels',
        field: 'channels',
        defaultSort: 'asc',
    },
    {
        title: 'SIP Header',
        field: 'sipHeader',
    },
    {
        title: 'Status',
        field: 'status',
        lookup: config.trunks.statusJSON,
    },
    {
        title: 'Editable',
        field: 'trunkType.allowUserEdit',
        hidden: true,
    },
];
// Trunks Base page in telephony section
const Trunks = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const navigate = useNavigate();
    const [sbcAsAService, setSBCAsAService] = useState(false);

    // state for the currently selected company
    const { currentCompany: currCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    useEffect(() => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                companyID: currCompany,
            },
        });
    }, []);

    // Checks if the current company has SBC as a service as a service type when component renders
    useEffect(() => {
        axios.get(`/services/company/${currCompany}`).then((res) => {
            res.data.forEach((service) => {
                if (service.serviceType === 'SBCaaS') {
                    setSBCAsAService(true);
                }
            });
        });
    }, []);

    const editTrunkNavigate = (data) => {
        switch (data.trunkType.name) {
            case 'MS Direct Routing':
                navigate(`/trunks/edit/dr/${data.id}`);
                break;
            case 'MS Operator Connect':
                navigate(`/trunks/edit/oc/${data.id}`);
                break;
            case 'SIP Trunk':
                navigate(`/trunks/edit/sip/${data.id}`);
                break;
            case 'Carrier - External':
                navigate(`/trunks/edit/ext/${data.id}`);
                break;
            case 'Zoom Provider Exchange':
                navigate(`/trunks/edit/zoom/${data.id}`);
                break;
            default:
                return;
        }
    };

    const addTrunkNavigate = () => {
        navigate('/trunks/add');
    };

    /*
     * Table and Modal displaying trunk with add button/edit action in the table
     * dialogue stating trunk redundancy changes and confirming this
     * understanding this component requires understanding TableAndModal
     */
    return (
        <>
            <h1
                className="mb-2 text-[2.5rem] font-medium"
                aria-label="outer toolbar trunks title">
                Trunks
            </h1>
            <TableAndModal
                tableRef={tableRef}
                style={{ height: '100%' }}
                newPage
                tableFetch={config.trunks.tableFetch + currCompany}
                titleProps={
                    sbcAsAService && (
                        <Button
                            className="btn btn-primary btn-sm"
                            style={{ marginBottom: '5%' }}
                            onClick={addTrunkNavigate}>
                            {' '}
                            <AddRoundedIcon
                                fontSize="small"
                                style={{ marginBottom: '2%' }}
                            />{' '}
                            Trunks
                        </Button>
                    )
                }
                title="Trunks"
                noTitle
                defaultColumns={defaultColumns}
                groupable
                filterable
                additionalActions={[
                    (rowData) => ({
                        icon: () => {
                            return <EditRoundedIcon fontSize="small" />;
                        },
                        tooltip: 'Edit Trunk',
                        position: 'row',
                        hidden:
                            rowData.status === 9 ||
                            (rowData.trunkType?.allowUserEdit === false &&
                                rowData.trunkType?.headerValue === 'sip'),
                        onClick: () => editTrunkNavigate(rowData),
                    }),
                ]}
                maxBodyHeight={
                    window.innerHeight * config.plainView.tableHeight
                }
            />
        </>
    );
};
export default Trunks;
