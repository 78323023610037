import { EditIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Image, MenuItem, Text } from 'components/v4';
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import DashboardServiceCardStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardStatusbar';

import walkthroughIds from 'pages/services/walkthroughIds';

import mazdaLogo from 'assets/images/mazda.png';
import { useServicesDashboard } from 'context/ServicesDashboardContext';
import { ZOOM } from '../constants';
import ZoomStatus from './ZoomStatus';

export default function ZoomCard({ service }) {
    const classes = classNames({
        flash: service.changes === 'added',
    });

    const serviceStatus = useServicesDashboard();
    const cardStatus = serviceStatus[ZOOM];

    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState(service);
    const { requiredScope } = useSelector((state) => state.login);

    const { ufZoomEditServiceButton } = walkthroughIds.dashboardServices;

    return (
        <DashboardServiceCard
            className={classes}
            dataWalkthroughId={service.name}>
            <DashboardServiceCardStatusbar
                cardStatus={{
                    color: 'green',
                    animation: false,
                }}
            />

            <DashboardServiceCardHeader>
                <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                    Zoom PEX
                </Text>

                <DashboardServiceCardHeaderActionButton
                    dataWalkthroughId={service.serviceType}>
                    {requiredScope >= 40 && (
                        <MenuItem
                            icon={<EditIcon />}
                            onClick={() => {
                                // navigate(`/services/zoom/${service.id}/edit`);
                            }}
                            data-walkthroughid={ufZoomEditServiceButton}>
                            <Text marginBottom={'0'}>Edit Service</Text>
                        </MenuItem>
                    )}
                </DashboardServiceCardHeaderActionButton>
            </DashboardServiceCardHeader>
            <DashboardServiceCardContent>
                <Box>
                    <Image
                        src={mazdaLogo}
                        sx={{
                            maxWidth: '50px',
                            margin: '0 auto 15px auto',
                        }}
                    />

                    <Box className="flex flex-col">
                        {/* Status */}
                        <Box className="mb-[15px] flex justify-between">
                            <Text className="font-medium">Status</Text>
                            <ZoomStatus
                                service={data}
                                cardStatus={cardStatus}
                            />
                        </Box>
                    </Box>
                </Box>
            </DashboardServiceCardContent>
        </DashboardServiceCard>
    );
}
