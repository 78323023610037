import { Box, VStack } from '@chakra-ui/react';
import { Input } from '@/components/v4';
import { textBoxStyling } from '../formStyles';

const NameField = ({ field, ...props }) => (
    <Box
        sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'flex-start',
            paddingTop: '15px',
        }}>
        <VStack width={'100%'} alignItems={'normal'}>
            <Input
                {...field}
                {...props}
                sx={textBoxStyling}
                data-walkthroughid={props.walkthroughid}
            />
        </VStack>
    </Box>
);
export default NameField;
