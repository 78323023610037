import { useState } from 'react';
import { FormControl, Stack, FormLabel, Switch, Text } from '@chakra-ui/react';
import { Button } from '@/components/v4';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    formLabelStyling,
    stackStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../walkthroughIds';
import { FiTrash } from 'react-icons/fi';

const PermissionsManageAccess = ({
    permissionsSet,
    handleFormSwitchChange,
    darkMode,
    onOpen,
    permissionSetId,
}) => {
    const [isReadOnlyHovered, setReadOnlyHovered] = useState(false);
    const [isOptOutHovered, setOptOutIsHovered] = useState(false);
    const [isDeleteHovered, setDeleteIsHovered] = useState(false);
    const isDarkmodeColor = darkMode ? '#FFFFFF' : '#000000';

    return permissionSetId ? (
        <FormControl sx={{ margin: '0 !important' }}>
            <Stack direction={'row'} height={'35px'} sx={stackStyling()}>
                <FormLabel sx={formLabelStyling(darkMode)}>
                    Manage Permission Access
                </FormLabel>
                <Stack direction={'row'} spacing={2} paddingTop={4}>
                    <Stack
                        direction={'row'}
                        sx={{
                            paddingRight: 8,
                            paddingTop: 1,
                            paddingBottom: 6,
                            paddingLeft: '64px',
                        }}
                        onMouseEnter={() => setReadOnlyHovered(true)}
                        onMouseLeave={() => setReadOnlyHovered(false)}>
                        <Stack direction={'row'} spacing={0}>
                            <Switch
                                paddingLeft={1}
                                paddingRight={'7px'}
                                name="readOnly"
                                onChange={handleFormSwitchChange}
                                isChecked={permissionsSet?.readOnly}
                                checked={permissionsSet?.readOnly}
                                walkthroughids={
                                    walkthroughIds.permissions.ufReadOnly
                                }
                            />
                            <Text
                                fontSize="12px"
                                fontWeight={500}
                                color={
                                    !permissionsSet?.readOnly
                                        ? '#737373'
                                        : isDarkmodeColor
                                }>
                                Read Only
                            </Text>
                        </Stack>
                        {isReadOnlyHovered && (
                            <FieldToolTip
                                label="Sets access to read only"
                                ariaLabel="permissions-read-only-switch-tip"
                                bottom="2px"
                                left="20px"
                            />
                        )}
                    </Stack>
                    <Stack
                        direction={'row'}
                        fontSize={14}
                        sx={{
                            paddingRight: 8,
                            paddingTop: 1,
                            paddingBottom: 6,
                        }}
                        onMouseEnter={() => setOptOutIsHovered(true)}
                        onMouseLeave={() => setOptOutIsHovered(false)}>
                        <Stack direction={'row'} spacing={0}>
                            <Switch
                                paddingLeft={1}
                                paddingRight={'7px'}
                                name="optOutOfNew"
                                onChange={handleFormSwitchChange}
                                isChecked={permissionsSet?.optOutOfNew}
                                checked={permissionsSet?.optOutOfNew}
                                walkthroughids={
                                    walkthroughIds.permissions.ufOptOut
                                }
                            />
                            <Text
                                fontSize="12px"
                                fontWeight={500}
                                color={
                                    !permissionsSet?.optOutOfNew
                                        ? '#737373'
                                        : isDarkmodeColor
                                }>
                                Opt Out
                            </Text>
                        </Stack>
                        {isOptOutHovered && (
                            <FieldToolTip
                                label="Opt Out of New Permissions"
                                ariaLabel="permissions-opt-out-tip"
                                bottom="2px"
                                left="20px"
                            />
                        )}
                    </Stack>
                    <Stack
                        direction={'row'}
                        onMouseEnter={() => setDeleteIsHovered(true)}
                        onMouseLeave={() => setDeleteIsHovered(false)}
                        sx={{ paddingRight: 10, paddingBottom: 6 }}>
                        <Button
                            leftIcon={
                                <FiTrash
                                    fontSize={24}
                                    color={isDarkmodeColor}
                                />
                            }
                            variant="link"
                            fontSize="12px"
                            paddingTop="6px"
                            data-walkthroughid={
                                walkthroughIds.permissions.ufDeleteBtn
                            }
                            colorScheme={isDarkmodeColor}
                            onClick={onOpen}>
                            Delete
                        </Button>
                        {isDeleteHovered && (
                            <FieldToolTip
                                label="Deletes Permission Set"
                                ariaLabel="permissions-delete-set-tip"
                                top="2px"
                                left="22px"
                            />
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </FormControl>
    ) : null;
};

export default PermissionsManageAccess;
