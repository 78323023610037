// TODO: refactor authentication routes in this route folder
import userPath from './users/userPath';
import servicePath from './services/servicePath';
import planPath from './plans/planPath';
import numberPath from './numbers/numberPath';

/** @const {Object.<string, string>} path - route path for TCAP pages */
export default {
    /**
     * Route for creating a new service / entity.
     * @type {'create'}
     */
    CREATE: 'create',

    /**
     * Route for editing a service / entity with a specific ID.
     * @type {':id/edit'}
     */
    EDIT: ':id/edit',
    ...servicePath,
    ...userPath,
    ...planPath,
    ...numberPath,
};
