import { axios } from '@/services/axios';

const savePermissionsSet = async ({ formData, scope }) => {
    const res = await axios.put('/permissionset', formData, {
        headers: {
            'X-RequestScope': scope,
        },
    });
    return res.data;
};

export default savePermissionsSet;
