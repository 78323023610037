import appConfig from '../../config.json';

export const IGNORED_MESSAGE = 'Ignored, no changes detected.';
export const SUCCESS_MESSAGE = 'Success.';
export const CSV_TYPE = 'text/csv';
export const CSV_TYPE_ERROR = 'File must be .csv type';



export const ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const TRUNK_USAGE_VALUES = [
    'CallingUserAssignment',
    'FirstPartyAppAssignment',
];
export const TEMPLATE_FILENAME = 'AddressUpdateTemplate.csv';

export const VERIFICATION_CHECKS = [
    {
        label: 'Received CSV',
        check: false,
        current: true,
        completionCounter: 0,
        total: 1, // Just the file upload
    },
    {
        label: 'Verifying Addresses',
        check: false,
        current: false,
        completionCounter: 0,
        total: 2, // Format and Data type checks
    },
    {
        label: 'Verifying Country Code',
        check: false,
        current: false,
        completionCounter: 0,
        total: 1, // API call
    },
];

export const DEFAULT_VERIFICATION_RESULT = {
    passed: {
        columns: [],
        data: [],
    },
    failed: {
        columns: [],
        data: [],
    },
};




export const INVALID_FILENAME = 'InvalidNumbers.csv';
export const FAILED_FILENAME = 'FailedNumbers.csv';



export const haveErrorsFilter = (v) => v.errors.length > 0;
export const isIgnoredFilter = (v) => v.ignored;



export const additionalOutbound = [
    {
        telephoneNumber: 'Restricted',
    },
];

export const succeedAPIColumns = [
    {
        title: 'description',
        field: 'description',
    },
    {
        title: 'streetHouseNumber1',
        field: 'streetHouseNumber1',
    },
    {
        title: 'country',
        field: 'country',
    },
];

export const failedAPIColumns = [
    {
        title: 'description',
        field: 'description',
    },
    {
        title: 'streetHouseNumber1',
        field: 'streetHouseNumber1',
    },
    {
        title: 'country',
        field: 'country',
    },
];

export const NO_DATA_FOUND_ERROR = "We didn't detect any changes.";
export const CSV_LIMIT_REACHED_ERROR = `Upload file contains more than ${appConfig.csvLimit} records.`;
export const CSV_LIMIT_REACHED_DESCRIPTION_ERROR = `If you need to add more than ${appConfig.csvLimit} entries, create and upload additional data files.`;



export const INVALID_CSV_CONTENT_OR_HEADERS = "Invalid CSV content or headers";
export const COUNTRY_CODE_INVALID_ERROR = "Country code is invalid";
export const API_ERROR = "An API error occurred";
export const passedColumns = [
    {
        title: 'Description',
        field: 'description',
    },
    {
        title: 'streetHouseNumber1',
        field: 'streetHouseNumber1',
    },

    {
        title: 'country',
        field: 'country',
    },
    {
        title: 'companyName',
        field: 'companyName',
        render: (rowData) => rowData.companyName !== null && rowData.companyName !== '' ? rowData.companyName : null,
    },
    {
        title: 'buildingType',
        field: 'buildingType',
        render: (rowData) => rowData.buildingType !== null && rowData.buildingType !== '' ? rowData.buildingType : null,
    },
    {
        title: 'building1stNumber',
        field: 'building1stNumber',
        render: (rowData) => rowData.building1stNumber !== null && rowData.building1stNumber !== '' ? rowData.building1stNumber : null,
    },
    {
        title: 'building1stSuffix',
        field: 'building1stSuffix',
        render: (rowData) => rowData.building1stSuffix !== null && rowData.building1stSuffix !== '' ? rowData.building1stSuffix : null,
    },
    {
        title: 'building2ndNumber',
        field: 'building2ndNumber',
        render: (rowData) => rowData.building2ndNumber !== null && rowData.building2ndNumber !== '' ? rowData.building2ndNumber : null,
    },
    {
        title: 'building2ndSuffix',
        field: 'building2ndSuffix',
        render: (rowData) => rowData.building2ndSuffix !== null && rowData.building2ndSuffix !== '' ? rowData.building2ndSuffix : null,
    },
    {
        title: 'buildingFloorType',
        field: 'buildingFloorType',
        render: (rowData) => rowData.buildingFloorType !== null && rowData.buildingFloorType !== '' ? rowData.buildingFloorType : null,
    },
    {
        title: 'buildingFloorNumber',
        field: 'buildingFloorNumber',
        render: (rowData) => rowData.buildingFloorNumber !== null && rowData.buildingFloorNumber !== '' ? rowData.buildingFloorNumber : null,
    },
    {
        title: 'buildingFloorNumberSuffix',
        field: 'buildingFloorNumberSuffix',
        render: (rowData) => rowData.buildingFloorNumberSuffix !== null && rowData.buildingFloorNumberSuffix !== '' ? rowData.buildingFloorNumberSuffix : null,
    },
    {
        title: 'buildingProperty',
        field: 'buildingProperty',
        render: (rowData) => rowData.buildingProperty !== null && rowData.buildingProperty !== '' ? rowData.buildingProperty : null,
    },
    {
        title: 'buildingLocation',
        field: 'buildingLocation',
        render: (rowData) => rowData.buildingLocation !== null && rowData.buildingLocation !== '' ? rowData.buildingLocation : null,
    },
    {
        title: 'streetHouseNumber1Suffix',
        field: 'streetHouseNumber1Suffix',
        render: (rowData) => rowData.streetHouseNumber1Suffix !== null && rowData.streetHouseNumber1Suffix !== '' ? rowData.streetHouseNumber1Suffix : null,
    },
    {
        title: 'streetHouseNumber2',
        field: 'streetHouseNumber2',
        render: (rowData) => rowData.streetHouseNumber2 !== null && rowData.streetHouseNumber2 !== '' ? rowData.streetHouseNumber2 : null,
    },
    {
        title: 'streetHouseNumber2Suffix',
        field: 'streetHouseNumber2Suffix',
        render: (rowData) => rowData.streetHouseNumber2Suffix !== null && rowData.streetHouseNumber2Suffix !== '' ? rowData.streetHouseNumber2Suffix : null,
    },
    {
        title: 'streetName1',
        field: 'streetName1',
        render: (rowData) => rowData.streetName1 !== null && rowData.streetName1 !== '' ? rowData.streetName1 : null,
    },
    {
        title: 'streetType1',
        field: 'streetType1',
        render: (rowData) => rowData.streetType1 !== null && rowData.streetType1 !== '' ? rowData.streetType1 : null,
    },
    {
        title: 'streetSuffix1',
        field: 'streetSuffix1',
        render: (rowData) => rowData.streetSuffix1 !== null && rowData.streetSuffix1 !== '' ? rowData.streetSuffix1 : null,
    },
    {
        title: 'streetName2',
        field: 'streetName2',
        render: (rowData) => rowData.streetName2 !== null && rowData.streetName2 !== '' ? rowData.streetName2 : null,
    },
    {
        title: 'streetType2',
        field: 'streetType2',
        render: (rowData) => rowData.streetType2 !== null && rowData.streetType2 !== '' ? rowData.streetType2 : null,
    },
    {
        title: 'streetSuffix2',
        field: 'streetSuffix2',
        render: (rowData) => rowData.streetSuffix2 !== null && rowData.streetSuffix2 !== '' ? rowData.streetSuffix2 : null,
    },
    {
        title: 'locality',
        field: 'locality',
        render: (rowData) => rowData.locality !== null && rowData.locality !== '' ? rowData.locality : null,
    },
    {
        title: 'district',
        field: 'district',
        render: (rowData) => rowData.district !== null && rowData.district !== '' ? rowData.district : null,
    },
    {
        title: 'state',
        field: 'state',
        render: (rowData) => rowData.state !== null && rowData.state !== '' ? rowData.state : null,
    },
    {
        title: 'postcode',
        field: 'postcode',
        render: (rowData) => rowData.postcode !== null && rowData.postcode !== '' ? rowData.postcode : null,
    },
    {
        title: 'locationId',
        field: 'locationId',
        render: (rowData) => rowData.locationId !== null && rowData.locationId !== '' ? rowData.locationId : null,
    },
    {
        title: 'civicAddressID',
        field: 'civicAddressID',
        render: (rowData) => rowData.civicAddressID !== null && rowData.civicAddressID !== '' ? rowData.civicAddressID : null,
    },
    {
        title: 'latitude',
        field: 'latitude',
        render: (rowData) => rowData.latitude !== null && rowData.latitude !== '' ? rowData.latitude : null,
    },
    {
        title: 'longitude',
        field: 'longitude',
        render: (rowData) => rowData.longitude !== null && rowData.longitude !== '' ? rowData.longitude : null,
    },
    {
        title: 'googlePlaceID',
        field: 'googlePlaceID',
        render: (rowData) => rowData.googlePlaceID !== null && rowData.googlePlaceID !== '' ? rowData.googlePlaceID : null,
    },
];

export const failedColumns = [
    {
        title: 'Description',
        field: 'description',
    },
    {
        title: 'streetHouseNumber1',
        field: 'streetHouseNumber1',
    },
    {
        title: 'country',
        field: 'country',
    },
    {
        title: 'companyName',
        field: 'companyName',
        render: (rowData) => rowData.companyName !== null && rowData.companyName !== '' ? rowData.companyName : null,
    },
    {
        title: 'buildingType',
        field: 'buildingType',
        render: (rowData) => rowData.buildingType !== null && rowData.buildingType !== '' ? rowData.buildingType : null,
    },
    {
        title: 'building1stNumber',
        field: 'building1stNumber',
        render: (rowData) => rowData.building1stNumber !== null && rowData.building1stNumber !== '' ? rowData.building1stNumber : null,
    },
    {
        title: 'building1stSuffix',
        field: 'building1stSuffix',
        render: (rowData) => rowData.building1stSuffix !== null && rowData.building1stSuffix !== '' ? rowData.building1stSuffix : null,
    },
    {
        title: 'building2ndNumber',
        field: 'building2ndNumber',
        render: (rowData) => rowData.building2ndNumber !== null && rowData.building2ndNumber !== '' ? rowData.building2ndNumber : null,
    },
    {
        title: 'building2ndSuffix',
        field: 'building2ndSuffix',
        render: (rowData) => rowData.building2ndSuffix !== null && rowData.building2ndSuffix !== '' ? rowData.building2ndSuffix : null,
    },
    {
        title: 'buildingFloorType',
        field: 'buildingFloorType',
        render: (rowData) => rowData.buildingFloorType !== null && rowData.buildingFloorType !== '' ? rowData.buildingFloorType : null,
    },
    {
        title: 'buildingFloorNumber',
        field: 'buildingFloorNumber',
        render: (rowData) => rowData.buildingFloorNumber !== null && rowData.buildingFloorNumber !== '' ? rowData.buildingFloorNumber : null,
    },
    {
        title: 'buildingFloorNumberSuffix',
        field: 'buildingFloorNumberSuffix',
        render: (rowData) => rowData.buildingFloorNumberSuffix !== null && rowData.buildingFloorNumberSuffix !== '' ? rowData.buildingFloorNumberSuffix : null,
    },
    {
        title: 'buildingProperty',
        field: 'buildingProperty',
        render: (rowData) => rowData.buildingProperty !== null && rowData.buildingProperty !== '' ? rowData.buildingProperty : null,
    },
    {
        title: 'buildingLocation',
        field: 'buildingLocation',
        render: (rowData) => rowData.buildingLocation !== null && rowData.buildingLocation !== '' ? rowData.buildingLocation : null,
    },
    {
        title: 'streetHouseNumber1Suffix',
        field: 'streetHouseNumber1Suffix',
        render: (rowData) => rowData.streetHouseNumber1Suffix !== null && rowData.streetHouseNumber1Suffix !== '' ? rowData.streetHouseNumber1Suffix : null,
    },
    {
        title: 'streetHouseNumber2',
        field: 'streetHouseNumber2',
        render: (rowData) => rowData.streetHouseNumber2 !== null && rowData.streetHouseNumber2 !== '' ? rowData.streetHouseNumber2 : null,
    },
    {
        title: 'streetHouseNumber2Suffix',
        field: 'streetHouseNumber2Suffix',
        render: (rowData) => rowData.streetHouseNumber2Suffix !== null && rowData.streetHouseNumber2Suffix !== '' ? rowData.streetHouseNumber2Suffix : null,
    },
    {
        title: 'streetName1',
        field: 'streetName1',
        render: (rowData) => rowData.streetName1 !== null && rowData.streetName1 !== '' ? rowData.streetName1 : null,
    },
    {
        title: 'streetType1',
        field: 'streetType1',
        render: (rowData) => rowData.streetType1 !== null && rowData.streetType1 !== '' ? rowData.streetType1 : null,
    },
    {
        title: 'streetSuffix1',
        field: 'streetSuffix1',
        render: (rowData) => rowData.streetSuffix1 !== null && rowData.streetSuffix1 !== '' ? rowData.streetSuffix1 : null,
    },
    {
        title: 'streetName2',
        field: 'streetName2',
        render: (rowData) => rowData.streetName2 !== null && rowData.streetName2 !== '' ? rowData.streetName2 : null,
    },
    {
        title: 'streetType2',
        field: 'streetType2',
        render: (rowData) => rowData.streetType2 !== null && rowData.streetType2 !== '' ? rowData.streetType2 : null,
    },
    {
        title: 'streetSuffix2',
        field: 'streetSuffix2',
        render: (rowData) => rowData.streetSuffix2 !== null && rowData.streetSuffix2 !== '' ? rowData.streetSuffix2 : null,
    },
    {
        title: 'locality',
        field: 'locality',
        render: (rowData) => rowData.locality !== null && rowData.locality !== '' ? rowData.locality : null,
    },
    {
        title: 'district',
        field: 'district',
        render: (rowData) => rowData.district !== null && rowData.district !== '' ? rowData.district : null,
    },
    {
        title: 'state',
        field: 'state',
        render: (rowData) => rowData.state !== null && rowData.state !== '' ? rowData.state : null,
    },
    {
        title: 'postcode',
        field: 'postcode',
        render: (rowData) => rowData.postcode !== null && rowData.postcode !== '' ? rowData.postcode : null,
    },
    {
        title: 'locationId',
        field: 'locationId',
        render: (rowData) => rowData.locationId !== null && rowData.locationId !== '' ? rowData.locationId : null,
    },
    {
        title: 'civicAddressID',
        field: 'civicAddressID',
        render: (rowData) => rowData.civicAddressID !== null && rowData.civicAddressID !== '' ? rowData.civicAddressID : null,
    },
    {
        title: 'latitude',
        field: 'latitude',
        render: (rowData) => rowData.latitude !== null && rowData.latitude !== '' ? rowData.latitude : null,
    },
    {
        title: 'longitude',
        field: 'longitude',
        render: (rowData) => rowData.longitude !== null && rowData.longitude !== '' ? rowData.longitude : null,
    },
    {
        title: 'googlePlaceID',
        field: 'googlePlaceID',
        render: (rowData) => rowData.googlePlaceID !== null && rowData.googlePlaceID !== '' ? rowData.googlePlaceID : null,
    }, {
        title: 'companyName',
        field: 'companyName',
        render: (rowData) => rowData.companyName !== null && rowData.companyName !== '' ? rowData.companyName : null,
    },
    {
        title: 'buildingType',
        field: 'buildingType',
        render: (rowData) => rowData.buildingType !== null && rowData.buildingType !== '' ? rowData.buildingType : null,
    },
    {
        title: 'building1stNumber',
        field: 'building1stNumber',
        render: (rowData) => rowData.building1stNumber !== null && rowData.building1stNumber !== '' ? rowData.building1stNumber : null,
    },
    {
        title: 'building1stSuffix',
        field: 'building1stSuffix',
        render: (rowData) => rowData.building1stSuffix !== null && rowData.building1stSuffix !== '' ? rowData.building1stSuffix : null,
    },
    {
        title: 'building2ndNumber',
        field: 'building2ndNumber',
        render: (rowData) => rowData.building2ndNumber !== null && rowData.building2ndNumber !== '' ? rowData.building2ndNumber : null,
    },
    {
        title: 'building2ndSuffix',
        field: 'building2ndSuffix',
        render: (rowData) => rowData.building2ndSuffix !== null && rowData.building2ndSuffix !== '' ? rowData.building2ndSuffix : null,
    },
    {
        title: 'buildingFloorType',
        field: 'buildingFloorType',
        render: (rowData) => rowData.buildingFloorType !== null && rowData.buildingFloorType !== '' ? rowData.buildingFloorType : null,
    },
    {
        title: 'buildingFloorNumber',
        field: 'buildingFloorNumber',
        render: (rowData) => rowData.buildingFloorNumber !== null && rowData.buildingFloorNumber !== '' ? rowData.buildingFloorNumber : null,
    },
    {
        title: 'buildingFloorNumberSuffix',
        field: 'buildingFloorNumberSuffix',
        render: (rowData) => rowData.buildingFloorNumberSuffix !== null && rowData.buildingFloorNumberSuffix !== '' ? rowData.buildingFloorNumberSuffix : null,
    },
    {
        title: 'buildingProperty',
        field: 'buildingProperty',
        render: (rowData) => rowData.buildingProperty !== null && rowData.buildingProperty !== '' ? rowData.buildingProperty : null,
    },
    {
        title: 'buildingLocation',
        field: 'buildingLocation',
        render: (rowData) => rowData.buildingLocation !== null && rowData.buildingLocation !== '' ? rowData.buildingLocation : null,
    },
    {
        title: 'streetHouseNumber1Suffix',
        field: 'streetHouseNumber1Suffix',
        render: (rowData) => rowData.streetHouseNumber1Suffix !== null && rowData.streetHouseNumber1Suffix !== '' ? rowData.streetHouseNumber1Suffix : null,
    },
    {
        title: 'streetHouseNumber2',
        field: 'streetHouseNumber2',
        render: (rowData) => rowData.streetHouseNumber2 !== null && rowData.streetHouseNumber2 !== '' ? rowData.streetHouseNumber2 : null,
    },
    {
        title: 'streetHouseNumber2Suffix',
        field: 'streetHouseNumber2Suffix',
        render: (rowData) => rowData.streetHouseNumber2Suffix !== null && rowData.streetHouseNumber2Suffix !== '' ? rowData.streetHouseNumber2Suffix : null,
    },
    {
        title: 'streetName1',
        field: 'streetName1',
        render: (rowData) => rowData.streetName1 !== null && rowData.streetName1 !== '' ? rowData.streetName1 : null,
    },
    {
        title: 'streetType1',
        field: 'streetType1',
        render: (rowData) => rowData.streetType1 !== null && rowData.streetType1 !== '' ? rowData.streetType1 : null,
    },
    {
        title: 'streetSuffix1',
        field: 'streetSuffix1',
        render: (rowData) => rowData.streetSuffix1 !== null && rowData.streetSuffix1 !== '' ? rowData.streetSuffix1 : null,
    },
    {
        title: 'streetName2',
        field: 'streetName2',
        render: (rowData) => rowData.streetName2 !== null && rowData.streetName2 !== '' ? rowData.streetName2 : null,
    },
    {
        title: 'streetType2',
        field: 'streetType2',
        render: (rowData) => rowData.streetType2 !== null && rowData.streetType2 !== '' ? rowData.streetType2 : null,
    },
    {
        title: 'streetSuffix2',
        field: 'streetSuffix2',
        render: (rowData) => rowData.streetSuffix2 !== null && rowData.streetSuffix2 !== '' ? rowData.streetSuffix2 : null,
    },
    {
        title: 'locality',
        field: 'locality',
        render: (rowData) => rowData.locality !== null && rowData.locality !== '' ? rowData.locality : null,
    },
    {
        title: 'district',
        field: 'district',
        render: (rowData) => rowData.district !== null && rowData.district !== '' ? rowData.district : null,
    },
    {
        title: 'state',
        field: 'state',
        render: (rowData) => rowData.state !== null && rowData.state !== '' ? rowData.state : null,
    },
    {
        title: 'postcode',
        field: 'postcode',
        render: (rowData) => rowData.postcode !== null && rowData.postcode !== '' ? rowData.postcode : null,
    },
    {
        title: 'locationId',
        field: 'locationId',
        render: (rowData) => rowData.locationId !== null && rowData.locationId !== '' ? rowData.locationId : null,
    },
    {
        title: 'civicAddressID',
        field: 'civicAddressID',
        render: (rowData) => rowData.civicAddressID !== null && rowData.civicAddressID !== '' ? rowData.civicAddressID : null,
    },
    {
        title: 'latitude',
        field: 'latitude',
        render: (rowData) => rowData.latitude !== null && rowData.latitude !== '' ? rowData.latitude : null,
    },
    {
        title: 'longitude',
        field: 'longitude',
        render: (rowData) => rowData.longitude !== null && rowData.longitude !== '' ? rowData.longitude : null,
    },
    {
        title: 'googlePlaceID',
        field: 'googlePlaceID',
        render: (rowData) => rowData.googlePlaceID !== null && rowData.googlePlaceID !== '' ? rowData.googlePlaceID : null,
    },
    {
        title: 'Failure reasons',
        field: 'failureReasons',
        width: '40%',
        render: (rowData) => {
            if (Array.isArray(rowData.failureReasons) && rowData.failureReasons.length > 0) {
                const errorString = rowData.failureReasons.join('\n');
                return (
                    <span style={{ whiteSpace: 'pre-line' }}>
                        {' '}
                        {errorString}{' '}
                    </span>
                );
            }
            return null;
        },
    },
   
];
export const buildColumns = (data, includeFailureReasons = false) => {
    if (!data || data.length === 0) {
        return includeFailureReasons
            ? [
                  {
                      title: 'Failure reasons',
                      field: 'failureReasons',
                      width: '40%',
                      render: (rowData) => {
                          if (Array.isArray(rowData.failureReasons) && rowData.failureReasons.length > 0) {
                              const errorString = rowData.failureReasons.join('\n');
                              return (
                                  <span style={{ whiteSpace: 'pre-line' }}>
                                      {' '}
                                      {errorString}{' '}
                                  </span>
                              );
                          }
                          return null;
                      },
                  },
              ]
            : [];
    }

    const firstItemKeys = Object.keys(data[0]);
    const columns = [];

    firstItemKeys.forEach((key) => {
        if (key !== 'failureReasons' && key !== 'countryId' && key !== 'companyID' && key !=='countryID' && key!=='id' && key !=='modifiedTS' && key!=='createTS') { // Ignore countryId and companyID
            let hasValidValue = false;
            for (const row of data) {
                if (row[key] !== null && row[key] !== '') {
                    hasValidValue = true;
                    break;
                }
            }
            if (hasValidValue) {
                columns.push({
                    title: key,
                    field: key,
                    render: (rowData) => (rowData[key] !== null && rowData[key] !== '' ? rowData[key] : null),
                });
            }
        }
    });

    if (includeFailureReasons) {
        columns.push({
            title: 'Failure reasons',
            field: 'failureReasons',
            width: '40%',
            render: (rowData) => {
                if (Array.isArray(rowData.failureReasons) && rowData.failureReasons.length > 0) {
                    const errorString = rowData.failureReasons.join('\n');
                    return (
                        <span style={{ whiteSpace: 'pre-line' }}>
                            {' '}
                            {errorString}{' '}
                        </span>
                    );
                }
                return null;
            },
        });
    }

    return columns;
};
// export const buildFailedColumns = (data) => {

//     if (!data || data.length === 0) {
//         return [
//             {
//                 title: 'Description',
//                 field: 'description',
//             },
//             {
//                 title: 'streetHouseNumber1',
//                 field: 'streetHouseNumber1',
//             },
//             {
//                 title: 'country',
//                 field: 'country',
//             },
//             {
//                 title: 'Failure reasons',
//                 field: 'failureReasons',
//                 width: '40%',
//                 render: (rowData) => {
//                     if (Array.isArray(rowData.failureReasons) && rowData.failureReasons.length > 0) {
//                         const errorString = rowData.failureReasons.join('\n');
//                         return (
//                             <span style={{ whiteSpace: 'pre-line' }}>
//                                 {' '}
//                                 {errorString}{' '}
//                             </span>
//                         );
//                     }
//                     return null;
//                 },
//             },
//         ];
//     }

//     const firstItemKeys = Object.keys(data[0]);
//     const columns = [
//         {
//             title: 'Description',
//             field: 'description',
//         },
//         {
//             title: 'streetHouseNumber1',
//             field: 'streetHouseNumber1',
//         },
//         {
//             title: 'country',
//             field: 'country',
//         },
//     ];

//     firstItemKeys.forEach((key) => {
//         if (key !== 'description' && key !== 'streetHouseNumber1' && key !== 'country' && key !== 'failureReasons') {
//             let hasValidValue = false;
//             for(const row of data){
//                 if(row[key] !== null && row[key] !== ''){
//                     hasValidValue = true;
//                     break;
//                 }
//             }
//             if(hasValidValue){
//                 columns.push({
//                     title: key,
//                     field: key,
//                     render: (rowData) => rowData[key] !== null && rowData[key] !== '' ? rowData[key] : null,
//                 });
//             }
//         }
//     });

//     columns.push(
//         {
//             title: 'Failure reasons',
//             field: 'failureReasons',
//             width: '40%',
//             render: (rowData) => {
//                 if (Array.isArray(rowData.failureReasons) && rowData.failureReasons.length > 0) {
//                     const errorString = rowData.failureReasons.join('\n');
//                     return (
//                         <span style={{ whiteSpace: 'pre-line' }}>
//                             {' '}
//                             {errorString}{' '}
//                         </span>
//                     );
//                 }
//                 return null;
//             },
//         },
//     );

//     return columns;
// };
// export const buildColumns = (data, includeFailureReasons = false) => {
//     if (!data || data.length === 0) {
//         return includeFailureReasons
//             ? [
//                   {
//                       title: 'Failure reasons',
//                       field: 'failureReasons',
//                       width: '40%',
//                       render: (rowData) => {
//                           if (Array.isArray(rowData.failureReasons) && rowData.failureReasons.length > 0) {
//                               const errorString = rowData.failureReasons.join('\n');
//                               return (
//                                   <span style={{ whiteSpace: 'pre-line' }}>
//                                       {' '}
//                                       {errorString}{' '}
//                                   </span>
//                               );
//                           }
//                           return null;
//                       },
//                   },
//               ]
//             : [];
//     }

//     const firstItemKeys = Object.keys(data[0]);
//     const columns = [];

//     firstItemKeys.forEach((key) => {
//         if (key !== 'failureReasons') { // Skip failureReasons here
//             let hasValidValue = false;
//             for (const row of data) {
//                 if (row[key] !== null && row[key] !== '') {
//                     hasValidValue = true;
//                     break;
//                 }
//             }
//             if (hasValidValue) {
//                 columns.push({
//                     title: key,
//                     field: key,
//                     render: (rowData) => (rowData[key] !== null && rowData[key] !== '' ? rowData[key] : null),
//                 });
//             }
//         }
//     });

//     if (includeFailureReasons) {
//         columns.push({
//             title: 'Failure reasons',
//             field: 'failureReasons',
//             width: '40%',
//             render: (rowData) => {
//                 if (Array.isArray(rowData.failureReasons) && rowData.failureReasons.length > 0) {
//                     const errorString = rowData.failureReasons.join('\n');
//                     return (
//                         <span style={{ whiteSpace: 'pre-line' }}>
//                             {' '}
//                             {errorString}{' '}
//                         </span>
//                     );
//                 }
//                 return null;
//             },
//         });
//     }

//     return columns;
// };