import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';

function DeleteAssociatedAccModal({ isOpen, onClose, handleSubmit, status }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent as="form" onSubmit={handleSubmit}>
                <ModalHeader>Delete Associated Account</ModalHeader>
                <ModalBody>
                    Removing an Associated Account will prevent any Operator
                    Numbers under the account from being accessible. Are you
                    sure you want to remove this Associated Account?
                </ModalBody>
                <ModalFooter justifyContent="space-evenly">
                    <Button onClick={onClose} variant="outline" w={128}>
                        Close
                    </Button>
                    <Button
                        type="submit"
                        colorScheme="red"
                        w={128}
                        isDisabled={status === 'loading'}
                        _focus={{
                            boxShadow: '0 0 0 1px var(--chakra-colors-red-500)',
                        }}>
                        {status === 'loading' ? 'Removing...' : 'Remove'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default DeleteAssociatedAccModal;
