// Dashboards
import CallStats from 'pages/dashboards/callStats';

// Accounts
import Admins from 'pages/accounts/admins/Index';
import Permissions from 'pages/accounts/permissions/Index';
import PermissionsSetsEdit from 'pages/accounts/permissions/PermissionsSetsEdit';
import Templates from 'pages/accounts/templates/Index';

import {
    ResourceAccountsRoutes,
    UsersRoutes,
} from '@/routes/users/index.routes';

// Automation
import { Engine, Jobs, Triggers } from 'pages/automation';

// Billing
import PlansRoutes from '@/routes/plans/index.routes';
import { InternationalRatesList, Invoices } from 'pages/billing/';

// Organisations
import { Companies, Partners, WholesalerIndex } from 'pages/organisations/';
import Addresses from 'pages/organisations/addresses';

// Services
import { OperatorProfiles } from 'pages/services';
import ServiceRoutes from 'pages/services/new/routes/index.routes';
import ServiceDashboard from 'pages/services/new/ServiceDashboard';
import ServiceProfiles from 'pages/services/serviceprofiles/new';

// Approval codes
import ApprovalCodes from 'pages/automation/approval-codes';

// Telephony
import {
    Calls,
    Carriers,
    Dial,
    NumberBlocks,
    Regions,
    SpecialNumbers,
    Trunks,
    TrunkTypes,
    VoicePolicies,
} from 'pages/telephony';

// Settings
import ProtectedNoMatch from 'components/v4/ProtectedNoMatch';
import AutoAttendantGuide from 'pages/accounts/users/guides/AutoAttendantGuide';
import VirtualUserGuide from 'pages/accounts/users/guides/VirtualUserGuide';
import Reload from 'pages/reload';
import Settings from 'pages/settings';
import CSVNumbersUpdate from 'pages/telephony/numbers/csv';
// import AdminsEdit from 'pages/accounts/admins/adminUsers/AdminsEdit';
import AdminsCreate from 'pages/accounts/admins/adminUsers/new/AdminsCreate';
import AdminsEdit from 'pages/accounts/admins/adminUsers/new/AdminsEdit';
import AddTrunk from 'pages/telephony/trunks/new/AddTrunk';
import EditDRTrunk from 'pages/telephony/trunks/new/EditDRTrunk';
import EditExtTrunk from 'pages/telephony/trunks/new/EditExtTrunk';
import EditOCTrunk from 'pages/telephony/trunks/new/EditOCTrunk';
import EditSipTrunk from 'pages/telephony/trunks/new/EditSipTrunk';

// new organisations
import Countries from 'pages/countries';
import AddCountry from 'pages/countries/AddCountry';
import EditCountry from 'pages/countries/EditCountry';
import { CompanyAdd } from 'pages/organisations/companies/new/CompanyAdd';
import CompanyTable from 'pages/organisations/companies/new/Index';

import { ServicesDashboardProvider } from 'context/ServicesDashboardContext';
import ApiUserCreate from 'pages/accounts/admins/apiUsers/create/ApiUserCreate';
import ApiUserEdit from 'pages/accounts/admins/apiUsers/edit/ApiUserEdit';
import ServiceCategories from 'pages/services/serviceCategories';
import AddServiceCategory from 'pages/services/serviceCategories/AddServiceCategory';
import EditServiceCategory from 'pages/services/serviceCategories/EditServiceCategory';

// Settings Sidebar
import { ErrorPage } from '@/lib/data-router/component';
import NumbersRoutes from '@/routes/numbers/index.routes';
import VisibilityGroupsRoutes from '@/routes/visibilityGroups/index.routes';
import Preferences from 'pages/settingsSidebar/preferences/Index';
import Security from 'pages/settingsSidebar/security/Index';
import EditZoomTrunk from 'pages/telephony/trunks/new/EditZoomTrunk';
import CSVAddressUpdate from 'pages/organisations/addresses/csv';
import { Pex } from 'pages/accounts';

/**
 * @param {import('@tanstack/react-query').QueryClient} [queryClient]
 * @returns {import('react-router-dom').RouteObject[]}
 */
// eslint-disable-next-line no-unused-vars
const authenticatedRoutes = (queryClient = {}) => [
    // Other
    { path: '*', element: <ProtectedNoMatch /> },
    { path: '/settings', element: <Settings />, hidden: true },
    {
        path: '/numbers/csv-numbers',
        element: <CSVNumbersUpdate />,
        hidden: true,
    },
    { path: '/virtualuserguide', element: <VirtualUserGuide />, hidden: true },
    { path: '/guide', element: <AutoAttendantGuide />, hidden: true },
    { path: '/reload/*', element: <Reload />, hidden: true },
    // Dashboards
    {
        path: '/dashboard-services',
        element: (
            <ServicesDashboardProvider>
                <ServiceDashboard />
            </ServicesDashboardProvider>
        ),
    },
    {
        path: '/call-stats',
        element: <CallStats />,
    },
    // Accounts
    {
        path: '/admins',
        element: <Admins />,
    },
    {
        path: '/pex-import',
        element: <Pex />,
    },
    { path: '/admins/create', element: <AdminsCreate /> },
    {
        path: '/admins/:adminId/edit',
        element: <AdminsEdit />,
    },
    {
        path: '/admins/api/',
        element: <ApiUserCreate />,
    },
    {
        path: '/admins/api/:apiUserId/edit',
        element: <ApiUserEdit />,
    },
    {
        path: '/permissions',
        element: <Permissions />,
    },
    {
        path: '/admins/permissions/:permissionSetId/edit',
        element: <PermissionsSetsEdit />,
    },
    {
        path: '/resource-accounts/*',
        element: <ResourceAccountsRoutes />,
    },
    {
        path: '/users/*',
        element: <UsersRoutes />,
    },
    // Automation
    {
        // No route needed for API
    },
    {
        element: <Triggers />,
        path: '/triggers',
    },
    { element: <Jobs />, path: '/jobs' },
    { element: <ApprovalCodes />, path: '/approval-codes' },
    // Billing
    {
        path: '/international-rates',
        element: <InternationalRatesList />,
    },
    {
        path: '/invoices',
        element: <Invoices />,
    },
    {
        path: '/plans/*',
        element: <PlansRoutes />,
    },
    // {
    //     path: '/plans',
    //     element: <Plans />,
    // },
    // Organisations
    {
        path: '/new/companies',
        element: <CompanyTable />,
    },
    {
        path: '/new/companies/add',
        element: <CompanyAdd />,
    },
    {
        path: '/companies',
        element: <Companies />,
    },
    {
        path: '/partners',
        element: <Partners />,
    },
    {
        path: '/addresses/csv-address',
        element: <CSVAddressUpdate />,
        hidden: true,
    },
    {
        path: '/addresses',
        element: <Addresses />,
    },
    {
        path: '/service-profiles',
        element: <ServiceProfiles />,
    },
    {
        path: '/visibility-groups/*',
        id: 'visibility-groups',
        children: [...VisibilityGroupsRoutes(queryClient)],
        errorElement: (
            <div className="-mt-20 h-[calc(100vh-80px)]">
                <ErrorPage />
            </div>
        ),
    },
    {
        path: '/services/*',
        element: <ServiceRoutes />,
    },
    // Telephony
    {
        path: '/calls',
        element: <Calls />,
    },
    {
        path: '/dial-plans',
        element: <Dial />,
        // loader: dialLoader,
    },

    {
        path: '/numbers/*',
        id: 'numbers',
        children: NumbersRoutes,
    },
    {
        path: '/number-blocks',
        element: <NumberBlocks />,
    },
    {
        path: '/trunks',
        element: <Trunks />,
    },
    {
        path: '/trunks/add',
        element: <AddTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/trunks/edit/sip/:id',
        element: <EditSipTrunk />,
        errorElement: <ServiceDashboard />,
    },
    {
        path: '/trunks/edit/ext/:id',
        element: <EditExtTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/trunks/edit/zoom/:id',
        element: <EditZoomTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/trunks/edit/dr/:id',
        element: <EditDRTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/trunks/edit/oc/:id',
        element: <EditOCTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/voice-policies',
        element: <VoicePolicies />,
    },
    // GA
    {
        path: '/templates',
        element: <Templates />,
    },
    {
        path: '/service-categories',
        element: <ServiceCategories />,
    },
    {
        path: '/service-categories/add',
        element: <AddServiceCategory />,
    },
    {
        path: '/service-categories/edit/:serviceCategoryId',
        element: <EditServiceCategory />,
    },
    {
        path: '/engines',
        element: <Engine />,
    },
    {
        path: '/wholesalers',
        element: <WholesalerIndex />,
    },
    {
        path: '/carriers',
        element: <Carriers />,
    },
    {
        path: '/regions-and-locations',
        element: <Regions />,
    },
    {
        path: '/countries',
        element: <Countries />,
    },
    {
        path: '/countries/add',
        element: <AddCountry />,
    },
    {
        path: '/countries/edit/:id',
        element: <EditCountry />,
    },
    {
        path: '/special-numbers',
        element: <SpecialNumbers />,
    },
    {
        path: '/trunk-types',
        element: <TrunkTypes />,
    },
    {
        path: '/operator-connect-profiles',
        element: <OperatorProfiles />,
    },
    {
        path: '/security',
        element: <Security />,
    },
    {
        path: '/preferences',
        element: <Preferences />,
    },
];

export default authenticatedRoutes;
