export default {
    dashboardServices: {
        ufTpmEditServiceButton: '/dashboard-services/tpm/button/1',
        ufTpmManageAccountButton: '/dashboard-services/tpm/button/2',
        ufZoomEditServiceButton: '/dashboard-services/zoom/button/1',
        ufNewServiceProfileCard: '/dashboard-services/new-service/div',
    },
    appAuth: {
        ufManagedBy: '/dashboard-services/appAuth/select/managedBy',
        ufTenantId: '/dashboard-services/appAuth/input/tenantId',
        ufServiceAdminAccount:
            '/dashboard-services/appAuth/input/serviceAdminAccount',
        ufMaxAccessFailures:
            '/dashboard-services/appAuth/input/maxAccessFailures',
        ufAccessFailureWindow:
            '/dashboard-services/appAuth/input/accessFailureWindow',
        ufSelectiveSync: '/dashboard-services/appAuth/checkbox/selectiveSync',
        ufUserSync: '/dashboard-services/appAuth/checkbox/userSync',
        ufGroupName: '/dashboard-services/appAuth/input/groupName',
        ufGroupNameInput: '/dashboard-services/appAuth/input/groupNameInput',
        ufUsageLocation: '/dashboard-services/appAuth/tags/usageLocation',
        ufUsageLocationTags:
            '/dashboard-services/appAuth/tags/usageLocationTags',
        ufCloseButton: '/dashboard-services/appAuth/button/close',
        ufUpdateButton: '/dashboard-services/appAuth/button/update',
    },
    serviceProfiles: {
        ufEditButton: 'service-profiles/table/button/edit',
        ufAddServiceProfile: '/service-profiles/button/add',
        ufSelectProfileType: '/service-profiles/select/profileType',
        ufSelectVisibleScope: '/service-profiles/select/visibleScope',
        ufCloseButton: '/service-profiles/button/close',
        ufUpdateButton: '/service-profiles/button/update',
        ufServiceNameInput: '/service-profiles/input/serviceName',
        ufOperatorsSelect: '/service-profiles/select/operators',
        ufCallingProfilesSelect: '/service-profiles/select/callingProfiles',
        ufRegionsSelect: '/service-profiles/select/regions',
        ufRegionTable: '/service-profiles/table/regions',
        ufVoicePoliciesSelect: '/service-profiles/select/voicePolicies',
        ufDialPlansSelect: '/service-profiles/select/dialPlans',
        ufAuthPolicySelect: '/service-profiles/select/authPolicy',
        ufMaxChannelsInput: '/service-profiles/input/maxChannels',
        ufMaxRedundancyLevelSelect:
            '/service-profiles/select/maxRedundancyLevel',
    },
    serviceCategories: {
        ufAddServiceCategory: 'service-categories/button/add',
        ufTableServiceCategoryEdit: 'service-categories/button/edit',
        ufTab: 'service-categories/tab/',
        ufID: 'service-categories/input/id',
        ufName: 'service-categories/input/name',
        ufDescription: 'service-categories/input/description',
        ufImage: 'service-categories/input/image',
        ufBack: 'service-categories/button/back',
        ufCreate: 'service-categories/button/create',
        ufSave: 'service-categories/button/save',
        ufClose: 'service-categories/button/close',
        ufDelete: 'service-categories/button/delete',
    },
    selectiveSync: {
        ufGroupName: 'selective-sync/input/groupName',
        ufGroupNameInput: 'selective-sync/input/groupNameInput',
        ufUsageLocation: 'selective-sync/tags/usageLocation',
        ufUsageLocationTags: 'selective-sync/tags/usageLocationTags',
        ufUpdateButton: 'selective-sync/button/updateButton',
    },
};
