import React, { useEffect, useState } from 'react';
import Button from '../../../components/v4/Button';
import Heading from '../../../components/v4/Heading';
import AddressFields from './AddressFields';
import useAddress from '../../../hooks/useAddress';
import { Box } from '@chakra-ui/react';
import { axios } from '../../../services/axios';
import Alert from '../../../components/v4/Alert';
import AlertDescription from '../../../components/v4/AlertDescription';
import AlertIcon from '../../../components/v4/AlertIcon';
import Text from '../../../components/v4/Text';
import Input from '../../../components/v4/Input';
import InputGroup from '../../../components/v4/InputGroup';
import InputRightElement from '../../../components/v4/InputRightElement';

import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';

import { SearchIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';

import walkthroughIds from '../walkthroughIds';
import { toast } from 'react-toastify';

export default function AddressEdit({ setAddressAction, rowData }) {
    const { addressFields, setAddressField, addressDescription } =
        useAddress(rowData);
    const companyName = useSelector(
        (state) => state.navigationLists.currentCompanyData?.name,
    );
    const companyID = useSelector(
        (state) => state.navigationLists.currentCompanyData?.id,
    );
    const { addresses } = walkthroughIds;

    const [countries, setCountries] = useState([]);
    useEffect(async () => {
        try {
            const res = await axios.get('/countries/list');
            setCountries(res.data);
        } catch (e) {
            toast.error('Unable to retrieve countries');
        }
    }, []);

    function onAddressEdit(e) {
        e.preventDefault();
        let compName
        if (addressFields.companyName === undefined || addressFields.companyName === null) {
            compName = companyName
        } else {
            compName = addressFields.companyName
        }
        axios
            .put(`/address`, {
                companyID: companyID,
                companyName: compName,
                ...addressFields,
            })
            .then((response) => {
                setAddressAction('Get');
            })
            .catch((error) => {
                toast.error(
                    error?.response?.data?.message ||
                    'There was an error editing this address. Please make sure you have filled in "Street House Number 1".',
                );
            });
    }

    return (
        <Box as="form" onSubmit={onAddressEdit}>
            {addressDescription}
            <Heading as="h2" sx={{ marginBottom: '25px' }}>
                Edit Address
            </Heading>

            <Box
                sx={{
                    height: '1px',
                    width: '100%',
                    margin: '25px 0',
                    background: 'gray.400',
                }}></Box>
            <Box sx={{ marginBottom: '25px' }}></Box>
            <AddressFields
                fields={addressFields}
                fieldHandler={setAddressField}
                countries={countries}
            />

            <Box
                sx={{
                    height: '1px',
                    width: '100%',
                    marginBottom: '25px',
                    background: 'gray.400',
                }}></Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxW: '140px',
                    margin: '0 auto',
                }}>
                <Button
                    variant="outline"
                    sx={{ background: 'white' }}
                    onClick={() => setAddressAction('Get')}
                    data-walkthroughid={addresses.ufBackButton}>
                    Back
                </Button>
                <Button
                    ml={4}
                    type="submit"
                    data-walkthroughid={addresses.ufSaveButton}>
                    Save
                </Button>
            </Box>
        </Box>
    );
}
