import React from 'react';
import {
    Box,
    Heading,
    Stack,
    Divider,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Card } from 'components/v4/Card';
import { breadCrumbStyling, headingStyling, stackStyling } from './formStyles';
import { useNavigate, Link } from 'react-router-dom';

const FormBase = ({
    breadCrumbTrail,
    header,
    initialValues,
    finalFormSubmit,
    backlink,
    ufBackBtn,
    ufSaveBtn,
    children,
    extraCards,
}) => {
    const { darkMode } = useSelector((state) => state.settings);
    const navigate = useNavigate();
    const navBack = () => {
        if (Array.isArray(backlink)) {
            navigate(backlink[0], backlink[1]);
        } else {
            navigate(backlink);
        }
    };

    return (
        <Box>
            <Breadcrumb sx={breadCrumbStyling}>
                {breadCrumbTrail?.map((crumb, index) => (
                    <BreadcrumbItem key={index}>
                        {Array.isArray(crumb.path) ? (
                            <BreadcrumbLink
                                as={Link}
                                to={crumb.path[0]}
                                state={crumb.path[1]}>
                                {crumb.label}
                            </BreadcrumbLink>
                        ) : (
                            <BreadcrumbLink as={Link} to={crumb.path}>
                                {crumb.label}
                            </BreadcrumbLink>
                        )}
                    </BreadcrumbItem>
                ))}
            </Breadcrumb>
            <Heading sx={headingStyling}>{header}</Heading>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(value) => finalFormSubmit(value)}
                validateOnChange>
                {({ errors, touched, handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={30}>
                            <Card
                                borderRadius={15}
                                padding={'0px 30px'}
                                maxWidth={895}
                                ml={0}
                                bg={darkMode ? 'dark.tcap' : null}>
                                <Stack sx={{ margin: '0 !important' }}>
                                    {React.Children.map(
                                        children({ errors, touched, values }),
                                        (child, index) => (
                                            <React.Fragment key={index}>
                                                {child}
                                                {index <
                                                    children({
                                                        errors,
                                                        touched,
                                                        values,
                                                    }).length -
                                                        1 && (
                                                    <Divider
                                                        borderColor="#F5F5F5"
                                                        borderWidth="1.4px"
                                                        sx={{
                                                            margin: '0 !important',
                                                        }}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ),
                                    )}
                                </Stack>
                            </Card>
                            {!!extraCards &&
                                extraCards.map((item, index) => (
                                    <Card
                                        key={index}
                                        borderRadius={15}
                                        maxWidth={895}
                                        ml={0}
                                        bg={darkMode ? 'dark.tcap' : null}>
                                        {item}
                                    </Card>
                                ))}
                            <Stack direction={'row'} sx={stackStyling()}>
                                <div
                                    className="edit-form-buttons"
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginBottom: 10,
                                    }}>
                                    <Button
                                        type="submit"
                                        data-walkthroughid={ufSaveBtn}
                                        className="h-10 min-w-[5rem] font-semibold disabled:cursor-not-allowed"
                                        sx={{ width: 142 }}
                                        onClick={handleSubmit}>
                                        Save
                                    </Button>
                                    <div
                                        style={{
                                            marginLeft: 30,
                                            display: 'inline',
                                        }}>
                                        <Button
                                            className="secondary h-10 min-w-[5rem] font-semibold"
                                            variant="outline"
                                            sx={{ width: 142 }}
                                            data-walkthroughid={ufBackBtn}
                                            onClick={navBack}>
                                            Back
                                        </Button>
                                    </div>
                                </div>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default FormBase;
