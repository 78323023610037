import config from '../../config.json';

const getDefaultInvoiceType = () => {
    return Number(sessionStorage.getItem('invoiceType'));
};

const initTableState = {
    loading: true,
    filtering: false,
    grouping: false,
    uploading: '',
    loadFields: false,
    alphabet: 'All',
    invoiceType: getDefaultInvoiceType(),
    indexing: false,
    intervalIDs: [], //when you set interval for autoreloads, keep track of them here
    originalData: [],
    buffer: {
        //for SSR buffering
        backendError: null,
        total: 0,
        limit: config.ssrTable.rowsPerPage,
        nextPage: 0,
        fetchedPages: [],
    },
};

export const tableReducerTypes = (tableName = '') => {
    return function (table = initTableState, action) {
        switch (action.type) {
            case `${tableName}OPEN_CHILD_TABLE`:
                return {
                    ...initTableState,
                    parent: action.payload.append
                        ? [...(table.state?.data ?? []), action.payload]
                        : table.state?.data,
                    child: action.payload,
                    invoiceType: table.invoiceType,
                };
            case `${tableName}CHANGE_PARENT_TABLE`:
                return {
                    ...table,
                    parent: action.payload,
                };
            case `${tableName}OPEN_CHILD_COMPONENTS`:
                return {
                    ...table,
                    child: action.payload,
                };
            case `${tableName}RESET_CHILD_TABLE`:
                return {
                    ...initTableState,
                    invoiceType: table.invoiceType,
                };
            case `${tableName}CHANGE_CHILD_TABLE`:
                return {
                    ...initTableState,
                    parent: table.parent,
                    child: action.payload,
                };
            case `${tableName}SWITCH_CHILD_TABLE`:
                return {
                    ...initTableState,
                    parent: table.parent,
                    child: table?.child,
                };
            case `${tableName}CHANGE_TABLE_ORIGINAL_DATA`:
                return {
                    ...table,
                    originalData: action.payload,
                };
            case `${tableName}CHANGE_TABLE_ALPHABET_FILTER`:
                return {
                    ...table,
                    alphabet: action.payload,
                };
            case `${tableName}RESET_TABLE`:
                return { ...initTableState };
            case 'CHANGE_TAB_VIEW':
                return { ...initTableState };
            case 'CHANGE_CURRENT_SCOPE':
                return { ...initTableState, invoiceType: table.invoiceType };
            case 'RESET_TABLE_MULTICHILD':
                return { ...initTableState };
            case `${tableName}CHANGE_TABLE_ERROR`:
                return {
                    ...table,
                    error: action.payload,
                    loading: false,
                };
            case `${tableName}CHANGE_TABLE_FILTERING`:
                return {
                    ...table,
                    filtering: !table.filtering,
                };
            case `${tableName}CHANGE_TABLE_GROUPING`:
                return {
                    ...table,
                    grouping: !table.grouping,
                };
            case 'INIT_WEB_WORKER_STATE':
                return {
                    ...initTableState,
                    child: table.child,
                    parent: table.parent,
                };
            case `${tableName}CHANGE_TABLE_LOADING`:
                return {
                    ...table,
                    loading: !table.loading,
                };
            case `${tableName}CHANGE_TABLE_INDEXING`:
                return {
                    ...table,
                    indexing: !table.indexing,
                };
            case `${tableName}CHANGE_TABLE_INDEX`:
                return {
                    ...table,
                    index: action.payload,
                };
            case `${tableName}CHANGE_TABLE_STATE`:
                return {
                    ...table,
                    state: action.payload,
                };
            case `${tableName}CHANGE_TABLE_INVOICE_TYPE`:
                sessionStorage.setItem('invoiceType', action.payload);
                return {
                    ...table,
                    invoiceType: Number(action.payload),
                };
            case 'REDIRECT':
                return {
                    ...initTableState,
                };
            case 'LOGOUT':
                return { ...initTableState };
            default:
                return table;
        }
    };
};
