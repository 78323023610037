import { axios } from '../../services/axios';

const getCompanies = async (currentPartner, currentScope) => {
    const res = axios.get('/Companies/DropDown', {
        params: {
            partnerID: currentPartner,
        },
        headers: {
            'X-RequestScope': currentScope,
        },
    });

    return res;
};

export default getCompanies;
