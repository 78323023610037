import { useMutation } from '@tanstack/react-query';
import deletePermissionsSet from '@/API/PermissionsSets/deletePermissionsSet';

import { toast } from 'react-toastify';

const useDeletePermissionsSet = (
    permissionsSetId,
    token,
    accountScope,
    dispatch,
    navigate,
) => {
    return useMutation({
        mutationFn: async () => {
            try {
                const deletePermissions = await deletePermissionsSet({
                    id: permissionsSetId,
                    token,
                    scope: accountScope,
                }).then(() => {
                    navigate('/admins', { state: { index: 2 } });
                    dispatch({ type: 'RESET_TABLE' });
                    toast.success('Permissions Set Deleted');
                });

                return deletePermissions;
            } catch (error) {
                toast.error(
                    error?.response?.data?.errors?.AdminUserID[0] ||
                        error.message ||
                        'Failed to delete Permissions Set',
                );
                throw new Error(
                    error?.response?.data?.errors?.AdminUserID[0] ||
                        error.message ||
                        'Failed to delete Permissions Set',
                );
            }
        },
    });
};

export default useDeletePermissionsSet;
