import { Box, HStack, Image, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import ServiceProfileCard from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCard';
import ServiceProfileCardTitle from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardTitle';
import walkthroughIds from 'pages/services/walkthroughIds';

import mobileLogo from 'assets/images/mobile.png';
import zoomCircleLogo from 'assets/images/zoom-circle.png';
import mazdaLogo from 'assets/images/mazda.png';
import ServiceProfileCardImage from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardImage';

function ZoomService({ serviceProfile, serviceWizard, id }) {
    const { ufNewServiceProfileCard } = walkthroughIds.dashboardServices;
    const { currentPartner } = useSelector((state) => state.navigationLists);
    return (
        <ServiceProfileCard
            key={id}
            pointerEvents={serviceWizard.isCreatingService ? 'none' : 'auto'}
            onClick={() => serviceWizard.createService(serviceProfile)}
            data-walkthroughid={`${ufNewServiceProfileCard}/${id}`}>
            {serviceWizard.isCreatingService && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        left: '0',
                        top: '0',
                        background: 'white',
                        opacity: '0.7',
                    }}>
                    {serviceWizard.selectedServiceProfile === 'ZoomPEX' && (
                        <Spinner
                            thickness="4px"
                            emptyColor="gray.200"
                            color="brand.500"
                            size="xl"
                        />
                    )}
                </Box>
            )}
            <ServiceProfileCardImage src={mazdaLogo} />
            <ServiceProfileCardTitle>Zoom PEX</ServiceProfileCardTitle>
            <Text>
                Connect with cloud peering partners, promote the discovery of
                new providers and enable self-service of phone numbers from your
                selective partners.
            </Text>
            {currentPartner == '100d5296-0c38-4192-1c43-08dbec03eb47' && (
                <HStack mt={'auto'}>
                    <Tooltip label="Zoom">
                        <Image boxSize={'5'} src={zoomCircleLogo} />
                    </Tooltip>
                    <Tooltip label="Mobile Calling">
                        <Image boxSize={'5'} src={mobileLogo} />
                    </Tooltip>
                </HStack>
            )}
        </ServiceProfileCard>
    );
}

export default ZoomService;
