import { Suspense, lazy } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';

import { useDeepMerge } from 'hooks/useDeepMerge';
import { LoadingIndicator } from 'components/v4';

import path from '@/routes/constants';

const Plans = lazy(() => import('pages/billing/plans/Index'));
const CSVCallingRates = lazy(() => import('pages/billing/plans/edit/csv/'));
const CSVNumberBlockRates = lazy(
    () => import('pages/billing/plans/edit/csv-number-block-rate'),
);

export default function PlanRoutes() {
    const { theme } = useDeepMerge();
    const { CSVCALLINGRATES, CSVNUMBERBLOCKRATES } = path;
    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <Suspense fallback={<LoadingIndicator />}>
                <Routes>
                    <Route index element={<Plans />} />
                    <Route
                        path={CSVCALLINGRATES}
                        element={<CSVCallingRates />}
                    />
                    <Route
                        path={CSVNUMBERBLOCKRATES}
                        element={<CSVNumberBlockRates />}
                    />
                </Routes>
            </Suspense>
        </ChakraProvider>
    );
}
