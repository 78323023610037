import { Box, ChakraProvider, Text } from '@chakra-ui/react';
import { IoMdRefresh as RefreshIcon } from 'react-icons/io';
import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import getCompany from 'API/Company/getCompany';
import { useDispatch, useSelector } from 'react-redux';
// import getCompanyServices from '../../../../API/getCompanySevices';
import chakratheme from '../../../../chakratheme';
import useServiceWizard from '../../../../hooks/useServiceWizard';
import objChangeDetection from '../../../../utils/objChangeDetection';
import ServiceWizard from '../ServiceWizard';
import styles from '../ServiceWizard/styles';
import ServiceWizardMsAuth from '../ServiceWizardMsAuth';
import ServiceWizardSyncUsers from '../ServiceWizardSyncUsers';
import DashboardServices from './ServiceDashboard';

import { Error } from '@/components/index';
import { nullError } from 'components/error';
import {
    Button,
    LoadingIndicator,
    Heading,
    BoxWithBorder,
} from 'components/v4';
import { useDashboardMenu } from 'context/DashboardMenuContext';
import { DefinedAttributesProvider } from 'pages/services/serviceprofiles/vendorDefined/DefinedAttributesContext';
import AddVendorDefined from './Services/VendorDefined/AddVendorDefined';
import { useDashboardServices } from 'hooks/useCustomQuery';
import { useServicesDashboardDispatch } from 'context/ServicesDashboardContext';
import { initServiceData } from './Services/constants';

export const QUERY_CACHE_TIME = 1; // cache time set to 15 secs. (converted to milliseconds)
export const REFETCH_INTERVAL = 15 * 1000; // refetch interval set to 15 secs. (converted to milliseconds)

export default function ServiceDashboardPage() {
    // Service Wizard Flow
    const serviceWizard = useServiceWizard();
    const { currentCompany } = useSelector((state) => state.navigationLists);
    const { darkMode } = useSelector((state) => state.settings);
    const dispatch = useDispatch();
    const dispatchService = useServicesDashboardDispatch();

    // Data
    const [theData, setTheData] = useState(null);
    const [thePrevData, setThePrevData] = useState([]);
    const { isQueryDisabled, setIsQueryDisabled } = useDashboardMenu();

    const {
        data,
        error,
        isLoading: isServicesLoading,
    } = useDashboardServices({
        currentCompany,
        options: {
            cacheTime: QUERY_CACHE_TIME,
            refetchInterval: REFETCH_INTERVAL,
            enabled: !isQueryDisabled,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                for (const service of data) {
                    if (
                        initServiceData.serviceTypes.includes(
                            service?.serviceType,
                        )
                    ) {
                        dispatchService({
                            type: `${service?.serviceType}/loaded_status`,
                            payload: {
                                serviceType: service?.serviceType,
                                service,
                            },
                        });
                    }
                }
            },
        },
    });
    const { data: companyData } = useQuery(
        ['company', serviceWizard.flow, currentCompany],
        () => getCompany(currentCompany),
        {
            cacheTime: QUERY_CACHE_TIME,
            refetchInterval: REFETCH_INTERVAL,
            enabled: !isQueryDisabled,
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        if (data?.length) {
            // Set previous
            setThePrevData(data);

            // Modify
            const result = objChangeDetection(thePrevData || [], data);

            setTheData(result);
        } else {
            setThePrevData([]);
            setTheData([]);
        }
    }, [data]);

    useEffect(() => {
        if (companyData) {
            serviceWizard.setCompany(companyData.data);
            dispatch({
                type: 'CHANGE_CURRENT_COMPANY_DATA',
                payload: companyData.data,
            });
        }
    }, [companyData]);

    // Fix for slow redux state
    if (error?.message || typeof data === 'undefined') {
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <LoadingIndicator />
        </ChakraProvider>;
    }

    // User would need to click on refresh button if idle for more than 2 minutes
    useEffect(() => {
        if (!isQueryDisabled) {
            const timer = setTimeout(() => {
                setIsQueryDisabled(true);
            }, 120000);

            return () => {
                clearTimeout(timer);
            };
        }

        // reset refresh on navigation
        return () => {
            setIsQueryDisabled(false);
        };
    }, [isQueryDisabled]);

    const handleRefresh = () => {
        setIsQueryDisabled(false);
    };

    if (!currentCompany) {
        return <Error error={nullError} />;
    }

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            {serviceWizard.flow === 'Start' && (
                <Box sx={styles.container}>
                    <Heading
                        as="h2"
                        className={darkMode ? 'text-white' : 'text-gray-700'}>
                        Services Dashboard
                    </Heading>

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '15px',
                        }}
                    />

                    <Text width={'800px'} color={darkMode ? 'white' : 'black'}>
                        Welcome to the Services Dashboard. Here you can view and
                        manage your services.
                    </Text>

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '15px',
                            marginTop: '15px',
                        }}
                    />

                    <BoxWithBorder my={0} />

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '15px',
                            marginTop: '15px',
                        }}
                    />

                    <DashboardServices
                        serviceWizard={serviceWizard}
                        services={theData}
                        companyData={companyData?.data}
                        isServicesLoading={isServicesLoading}
                    />

                    {isQueryDisabled && (
                        <Button
                            borderRadius="2xl"
                            onClick={handleRefresh}
                            style={{
                                position: 'fixed',
                                bottom: 50,
                                right: 50,
                            }}
                            leftIcon={
                                <RefreshIcon size={20} className="text-white" />
                            }>
                            <span className="flex items-center text-white">
                                Refresh
                            </span>
                        </Button>
                    )}
                </Box>
            )}

            {serviceWizard.flow === 'CreateService' && (
                <ServiceWizard serviceWizard={serviceWizard} />
            )}

            {serviceWizard.flow === 'SelectiveSyncUsers' && (
                <ServiceWizardSyncUsers serviceWizard={serviceWizard} />
            )}

            {serviceWizard.flow === 'SelectiveSyncUsersEdit' && (
                <ServiceWizardSyncUsers serviceWizard={serviceWizard} edit />
            )}

            {serviceWizard.flow === 'MsAuth' && (
                <ServiceWizardMsAuth serviceWizard={serviceWizard} />
            )}

            {serviceWizard.flow === 'VendorDefined' && (
                <DefinedAttributesProvider>
                    {' '}
                    <AddVendorDefined serviceWizard={serviceWizard} />
                </DefinedAttributesProvider>
            )}
        </ChakraProvider>
    );
}
