export const defaultColumns = [
    {
        title: 'Name',
        field: 'name',
        defaultSort: 'asc',
    },
    { title: 'Description', field: 'description' },
    { title: 'Members', field: 'assignedMembers' },
];

export const initMember = {
    email: '',
    isOwner: false,
};

const dummyTable = [];
const dummyTableLength = 12;
for (let i = 0; i < dummyTableLength; i++) {
    const obj = {
        title: `Title ${i + 1}`,
        field: `Field ${i + 1}`,
    };
    dummyTable.push(obj);
}

export { dummyTable };
