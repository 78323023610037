import { useEffect } from 'react';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@/components/v4';
import { FormLabel, Input, Button } from 'components/v4';
import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react';

import walkthroughIds from './walkthroughIds';

export const CopyPermissionsSetModal = ({
    isOpen,
    onClose,
    finalRef,
    data,
    copyOnClick,
    copyName,
    setCopyName,
    isCopyingPermissionsSet,
}) => {
    const handleNameChange = (e) => {
        setCopyName(e.target.value);
    };

    useEffect(() => {
        setCopyName(`Copy of ${data?.name}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Modal
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Copy Permission Sets: {data?.name}</ModalHeader>
                <ModalBody>
                    <FormControl>
                        <FormLabel>Copy as</FormLabel>
                        <Input
                            value={copyName}
                            onChange={handleNameChange}
                            disabled={isCopyingPermissionsSet}
                            data-walkthroughid={
                                walkthroughIds.permissions.ufCopyInput
                            }
                        />
                        {copyName ? (
                            <FormErrorMessage>{copyName}</FormErrorMessage>
                        ) : (
                            <FormHelperText>{copyName}</FormHelperText>
                        )}
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="ghost"
                        bg="transparent"
                        mr={3}
                        colorScheme="black"
                        onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        variant="solid"
                        isLoading={isCopyingPermissionsSet}
                        onClick={copyOnClick}>
                        Copy
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CopyPermissionsSetModal;
