import NumberFormat from 'react-number-format';

/*
 * A series of number format methods
 */
const NumberFormatCustom = (props) => {
    const { inputRef, onChange, allowNegative = false, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange &&
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
            }}
            allowNegative={allowNegative}
            thousandSeparator
            isNumericString
            decimalScale={6}
            fixedDecimalScale={true}
        />
    );
};

/*
 * A series of number format methods
 */
export const NegativeSixDP = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange &&
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
            }}
            thousandSeparator
            isNumericString
            decimalScale={6}
            fixedDecimalScale={true}
        />
    );
};

export const Dollar = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange &&
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
            }}
            allowNegative={false}
            thousandSeparator
            isNumericString
            prefix="$"
            decimalScale={2}
            fixedDecimalScale={true}
        />
    );
};

export const FloatAllowNegative = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange &&
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
            }}
            allowNegative={true}
            thousandSeparator
            isNumericString
            decimalScale={6}
            fixedDecimalScale={true}
        />
    );
};

export const FloatSurchargePercentage = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange &&
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
            }}
            allowNegative={true}
            thousandSeparator
            isNumericString
            decimalScale={6}
            fixedDecimalScale={true}
        />
    );
};

export const FloatSurcharge = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange &&
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
            }}
            allowNegative={true}
            thousandSeparator
            isNumericString
            decimalScale={6}
            fixedDecimalScale={true}
        />
    );
};
export const NonNegativeInteger = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange &&
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
            }}
            allowNegative={false}
            thousandSeparator
            isNumericString
            decimalScale={0}
            fixedDecimalScale={true}
        />
    );
};

export const PhoneNumber = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange &&
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
            }}
            allowNegative={false}
            isNumericString
            decimalScale={0}
            fixedDecimalScale={true}
        />
    );
};

export default NumberFormatCustom;
