import { Box, VStack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { dropDownFormStyle } from '../formStyles';
import { forwardRef } from 'react';

const ChakraSelect = forwardRef((props, ref) => (
    <Select selectedOptionColor="brand" ref={ref} {...props} />
));
ChakraSelect.displayName = 'ChakraSelect';

const DropDown = ({
    field,
    data,
    form: { setValues, values, validateField, touched, setTouched },
    ...props
}) => {
    return (
        <Box
            flex="1"
            sx={{
                paddingTop: '15px',
            }}>
            <VStack alignItems={'normal'}>
                <ChakraSelect
                    {...field}
                    {...props}
                    minWidth={'410px'}
                    width={'100%'}
                    data-walkthroughid={props.walkthroughId}
                    value={data?.find((item) => item.id === props.currentId)}
                    options={data}
                    placeholder={props.placeholder}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    chakraStyles={dropDownFormStyle(props.darkMode)}
                    onChange={(item) => {
                        props.handleSelectChange(item, props.name);
                        setValues({
                            ...values,
                            permissionSetID: item.id,
                        }).then(() => {
                            validateField(props.name);
                        });
                    }}
                    onBlur={() => {
                        setTouched({
                            ...touched,
                            permissionSetID: true,
                        });
                    }}
                />
            </VStack>
        </Box>
    );
};

export default DropDown;
