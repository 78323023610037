import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import walkthroughIds from '../../walkthroughIds';

const DeleteModal = ({
    isDeleteOpen,
    onDeleteClose,
    apiUser,
    isDeleteBusy,
    apiUserLoading,
    deleteAPIUser,
}) => {
    return (
        <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} isCentered>
            <ModalOverlay />
            <ModalContent minW={'30%'}>
                <ModalHeader>Delete API User: {apiUser?.name}</ModalHeader>
                <ModalBody>
                    Are you sure you would like to delete this API user? This
                    will permanently deauthorize the API key and cause any apps
                    using it to break. This cannot be undone.
                </ModalBody>

                <ModalFooter>
                    <Button
                        isDisabled={isDeleteBusy}
                        mr={3}
                        variant="ghost"
                        bg="transparent"
                        colorScheme="black"
                        onClick={() => onDeleteClose()}
                        data-walkthroughid={
                            walkthroughIds.adminApiUsers.ufClose
                        }>
                        Close
                    </Button>
                    <Button
                        isDisabled={apiUserLoading}
                        variant="solid"
                        colorScheme="red"
                        isLoading={isDeleteBusy}
                        onClick={() => deleteAPIUser()}
                        data-walkthroughid={
                            walkthroughIds.adminApiUsersAdmin.ufModalDelete
                        }>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteModal;
