import useEditPath from '@/hooks/useEditPath';

function useTableActions({ onDeleteVgModalOpen, onLeaveVgModalOpen, setVg }) {
    const { handleEditBtn } = useEditPath();

    const handleDeleteVg = (rowData) => {
        onDeleteVgModalOpen();
        setVg(rowData);
    };
    const handleLeaveVg = (rowData) => {
        onLeaveVgModalOpen();
        setVg(rowData);
    };

    return {
        handleEditBtn,
        handleDeleteVg,
        handleLeaveVg,
    };
}

export default useTableActions;
