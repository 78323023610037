/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { outlinedInputClasses } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@chakra-ui/react';

const drawerWidth = 240;
const drawerCollapse = 64;

export function useThemeHelper() {
    const {
        darkMode,
        mixedMode: lightMode,
        colorScheme,
    } = useSelector((state) => {
        return { ...state.settings };
    });

    const { isMenuCollapse } = useSelector((state) => ({
        isMenuCollapse: state.menu.collapse,
    }));
    const { brand } = useTheme().colors;
    //Styles created to be used for react functional components
    const useStyles = makeStyles((theme) => {
        return {
            root: {
                display: 'flex',
            },
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                background: 'var(--chakra-colors-dark-bgDark)',
                borderBottomColor: '#fafafa',
            },
            drawer: {
                width: !isMenuCollapse ? drawerWidth : drawerCollapse,
                flexShrink: 0,
            },
            drawerPaper: {
                width: !isMenuCollapse ? drawerWidth : drawerCollapse,
                background: 'var(--chakra-colors-dark-bgDark)',
                boxShadow:
                    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
                overflowX: 'hidden',
            },
            drawerContainer: {
                overflow: 'auto',
            },
            content: {
                flexGrow: 1,
                padding: theme.spacing(3),
                paddingTop: 0,
                marginTop: 80,
            },
        };
    });

    const theme = useMemo(
        () =>
            createTheme({
                typography: {
                    button: {
                        textTransform: 'none',
                    },
                },
                palette: {
                    type: darkMode ? 'dark' : 'light',
                    primary: {
                        ...brand,
                        main: darkMode ? '#039be5' : '#03a9f4',
                    },
                    secondary: {
                        main: '#8cc440',
                    },
                },
                props: {
                    MuiBreadcrumbs: {
                        separator: '>',
                    },
                    MuiCircularProgress: {
                        disableShrink: true,
                    },
                    MuiAlert: {
                        variant: 'filled',
                    },
                    MuiCheckbox: {
                        color: 'secondary',
                    },
                    MuiTextField: {
                        variant: 'outlined',
                        size: 'small',
                    },
                    MuiTabs: {
                        indicatorColor: `var(--chakra-colors-${colorScheme}-400)`,
                    },
                    MuiInputLabel: {
                        shrink: true,
                    },
                    MuiSelect: {
                        variant: 'outlined',
                    },
                    MuiChip: {
                        size: 'small',
                    },
                    MuiOutlinedInput: {
                        notched: true,
                    },
                },
                overrides: {
                    MuiTableCell: {
                        head: {
                            backgroundColor: darkMode
                                ? null
                                : '#f5f5f5 !important',
                        },
                    },
                    MuiTab: {
                        root: {
                            '&$selected': {
                                color: `var(--chakra-colors-${colorScheme}-400)!important`,
                            },
                        },
                    },
                    MuiTablePagination: {
                        root: {
                            border: 'none',
                        },
                        toolbar: {
                            background: darkMode ? 'transparent' : null,
                        },
                    },
                    MuiFormControlLabel: {
                        label: {
                            color: darkMode ? 'white' : null,
                        },
                    },
                    MuiTextField: {
                        root: {
                            padding: '5px',
                            paddingInline: 0,
                            '& label.Mui-focused': {
                                color: `var(--chakra-colors-${colorScheme}-500)`,
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: `var(--chakra-colors-${colorScheme}-500)`,
                            },
                        },
                    },
                    MuiInputLabel: {
                        root: {
                            padding: '5px',
                        },
                    },
                    MuiTabs: {
                        indicatorColor: `var(--chakra-colors-${colorScheme}-50)`,
                    },
                    // override light mode
                    MuiAutocomplete: {
                        option: {
                            '&[aria-selected="true"]': {
                                backgroundColor: `var(--chakra-colors-${colorScheme}-500)`,
                                color: 'white',
                            },
                            '&[data-focus="true"]': {
                                color: 'inherit',
                                backgroundColor: darkMode
                                    ? 'rgba(255, 255, 255, 0.26)'
                                    : 'var(--chakra-colors-gray-100)',
                            },
                        },
                    },
                    MuiOutlinedInput: {
                        root: {
                            '&:hover $notchedOutline': {
                                borderColor: 'var(--chakra-colors-gray-300)',
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]:
                                {
                                    borderColor: `var(--chakra-colors-${colorScheme}-500)!important`,
                                },
                            '&': {
                                borderRadius: '0.375rem',
                            },
                        },
                    },
                },
            }),
        [],
    );

    const lightModeTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    type: 'dark',
                    primary: {
                        ...brand,
                    },
                },
                props: {
                    MuiTextField: {
                        variant: 'outlined',
                        size: 'small',
                    },
                },
                overrides: {
                    MuiTableCell: {
                        head: {
                            backgroundColor: darkMode
                                ? null
                                : '#f5f5f5 !important',
                        },
                    },
                    MuiTab: {
                        root: {
                            '&$selected': {
                                color: `var(--chakra-colors-${colorScheme}-400)!important`,
                            },
                        },
                    },
                    MuiTablePagination: {
                        toolbar: {
                            background: darkMode ? '#121212' : null,
                        },
                    },
                    MuiFormControlLabel: {
                        label: {
                            color: darkMode ? 'white' : null,
                        },
                    },
                    MuiTextField: {
                        root: {
                            padding: '5px',
                            paddingInline: 0,
                        },
                    },
                    MuiInputLabel: {
                        root: {
                            padding: '5px',
                        },
                    },
                    MuiTabs: {
                        indicatorColor: `var(--chakra-colors-${colorScheme}-50)`,
                    },
                    MuiAutocomplete: {
                        option: {
                            '&[aria-selected="true"]': {
                                backgroundColor: `var(--chakra-colors-${colorScheme}-500)`,
                                color: 'white',
                            },
                        },
                    },
                    MuiOutlinedInput: {
                        root: {
                            '&:hover $notchedOutline': {
                                borderColor: 'var(--chakra-colors-gray-300)',
                            },
                        },
                    },
                },
            }),
        [],
    );

    const classes = useStyles();

    return {
        classes,
        mixedMode: lightMode,
        lightModeTheme,
        theme,
    };
}
