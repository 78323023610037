export const initialState = {
    companyId: '',
    companyName: '',
    id: '',
    name: '',
    permissions: [],
    requiredScope: 20,
    requiredScopeName: 'Admin',
    readOnly: false,
    optOut: false,
};
