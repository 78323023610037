import {
    buildColumns,
    ERROR_MESSAGE,
} from './constants'
import { axios } from 'services/axios';
import { useCallback, useState } from 'react';

export default function useCSVAddressBulkUpdate() {
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [succeed, setSucceed] = useState([]);
    const [counter, setCounter] = useState(0);
    const [errorCounter, setErrorCounter] = useState(0);
    const [uploadPayloads, setUploadPayloads] = useState([]);
    const [customSucessColumns, setCustomSuccessColumns] =  useState()

    //entry point to start API call
    const processSubmission = async (payloads) => {
        setLoading(true);
        // const finalPayloads = transformSubmitData(payloads);
        handleBulkUpdate(payloads);
    };

    //after API calls, set UI State
    const handleFinishBulkUpdate = ({ errors, responses }) => {
        setError(errors);
        setSucceed(responses);
        const result = buildColumns(responses.map((res) => res.data), false)
        setCustomSuccessColumns(result)
        buildColumns(errors, true)
    };

    //handle api calls
    const handleBulkUpdate = async (payloads) => {
        const errors = [];
        const responses = [];
        setUploadPayloads(payloads);
        const addressPayload=payloads.map(obj => {
            // eslint-disable-next-line no-prototype-builtins
            if (typeof obj === 'object' && obj !== null && obj.hasOwnProperty('tableData')) {
              const newObj = { ...obj }; // Create a shallow copy to avoid modifying the original
              delete newObj.tableData;
              return newObj;
            } else {
              return obj; // Return the original object if it doesn't have tableData or is not an object
            }
        })
        addressPayload.forEach(async (payload, index) => {
            try {
                //api call
                const response = await axios.post('/address/', payload);
                //then store the success response
                responses.push(response);
            } catch (e) {
                //put to failed table
                errors.push({
                    ...payload,
                    apiErrors:
                        `${e?.message}\n${e?.response.data?.message}\n${e?.response?.status}` ||
                        ERROR_MESSAGE,
                });
                //count as error
                setErrorCounter((prev) => prev + 1);
            } finally {
                //set UI counter
                setCounter((prev) => prev + 1);
                if (index === payloads.length - 1) {
                    //setState for Failed & Succeed table
                    handleFinishBulkUpdate({ errors, responses });
                }
            }
        });
    };

    /**
     * handle UI button - start api call when payload is ready.
     */
    const handlePayloadChange = useCallback((payloads) => {
        processSubmission(payloads);
        
    }, []);

    /**
     * handle UI button - export API calls that failed to CSV.
     */
  

    

    return {
        handlePayloadChange,
        loading,
        counter,
        errorCounter,
        error,
        succeed,
        customSucessColumns,
        total: uploadPayloads.length,
    };
}
