import {
    Button,
    Flex,
    Icon,
    ModalCloseButton,
    SkeletonCircle,
    Skeleton,
    VStack,
} from '@chakra-ui/react';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';

import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@/components/v4';

export default function EditNumbersModal(props) {
    const { isOpen, onClose, status, modalTitle, numberList } = props;
    const isSuccessful = status === 'success';
    const isLoading = status === 'loading';
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{modalTitle}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack
                        maxH="300px"
                        overflowY="auto"
                        spacing={2}
                        align="stretch">
                        {numberList?.map((number, index) => (
                            <Flex
                                key={`${number}-${index}`}
                                alignItems="center">
                                {isLoading ? (
                                    <>
                                        <SkeletonCircle
                                            size="4"
                                            aria-label="Loading number"
                                        />
                                        <Skeleton ml={2} minW="200px">
                                            <Text>{number}</Text>
                                        </Skeleton>
                                    </>
                                ) : (
                                    <>
                                        <Icon
                                            boxSize={4}
                                            aria-label={`${isSuccessful ? 'Checked' : 'Fail'} number`}
                                            color={`${isSuccessful ? 'green' : 'red'}.500`}
                                            as={
                                                isSuccessful
                                                    ? FaCircleCheck
                                                    : FaCircleXmark
                                            }
                                        />
                                        <Text ml={2}>{number}</Text>
                                    </>
                                )}
                            </Flex>
                        ))}
                    </VStack>
                </ModalBody>
                <ModalFooter justifyContent="center">
                    <Button colorScheme="messenger" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
