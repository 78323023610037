/* eslint-disable */
import React, { useState, useEffect, useRef, useReducer } from 'react';
// import { Button } from 'react-bootstrap';
import { Breadcrumbs, Link } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { Alert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
    AdminForm,
    BillingForm,
    Approval,
    CustomFieldsForm,
    CompanyEdit,
} from '..';
import TableAndModal, {
    modalTitle,
    onModalError,
} from '../../../components/tables/TableAndModal';
import Script from '../../scripts';
import { LoadingFieldDropdown, TabView } from '../../../components';
import config from '../../../config.json';
import { axios } from '../../../services/axios';
import { COMPANY } from '../sharedComponents/BillingForm';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    CANNOT_DELETE_MODE,
    LOADING_DELETE_MODE,
} from 'components/tables/ReduxModal';
import CheckRounded from '@material-ui/icons/CheckRounded';
import CircularProgress from '@material-ui/core/CircularProgress';

import walkthroughIds from '../walkthroughIds';
import EntityCustomFieldsForm from '../sharedComponents/EntityCustomFieldsForm';
import Services from 'pages/accounts/users/services/Services';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { ServicesProvider } from 'pages/accounts/users/services/ServicesContext';

import { Button } from 'components/v4';
import { useQuery } from '@tanstack/react-query';
import { useServices } from 'pages/accounts/users/services/ServicesContext';

// default columns for the table
const defaultColumns = [
    {
        title: 'Friendly Company Name',
        defaultSort: 'asc',
        field: 'name',
    },
    {
        title: 'Legal Entity Name',
        defaultSort: 'asc',
        field: 'entityName',
    },
    {
        title: 'Status',
        field: 'status',
        width: '4%',
        lookupDescription: config.companies.statusNavbarJSON,
    },
    {
        title: 'Active',
        field: 'isActive',
        type: 'boolean',
        width: '4%',
    },
    {
        title: 'On Trial',
        field: 'isTrial',
        type: 'boolean',
        width: '4%',
    },
    {
        title: 'Initial Domain',
        field: 'initialDomain',
    },
    {
        title: 'Last Pairing',
        field: 'lastSuccessfulPair',
        type: 'datetime',
        hidden: true,
    },
    {
        title: 'Default Plan',
        field: 'defaultPlan',
        width: '12%',
        render: (rowData) => {
            if (typeof rowData.defaultPlan === 'string') {
                return rowData.defaultPlan;
            }
            return;
        },
    },
    {
        title: 'Trial On Hold',
        field: 'trialHold',
        type: 'boolean',
        width: '4%',
    },
    ...config.table.custom,
    {
        title: 'Service Countries',
        field: 'serviceCountries',
        hiddenByColumnsButton: true,
        hidden: true,
    },
    {
        title: 'Company ID',
        field: 'id',
        hiddenByColumnsButton: true,
        hidden: true,
    },
];

/*
 * Company Base component for the 'Companies' Tab of TCAP
 * Tab view is ComapnyStandardForm, AdminForm, CustomFieldsForm and BillingForm
 */
const Companies = (props) => {
    //voice policies field in edit general, when you add regions it will auto add voice policies
    const dispatch = useDispatch();
    const tableRef = useRef();

    const location = useLocation();
    const { theme } = useDeepMerge();

    const [voicePoliciesAvailable, setVoicePoliciesAvailable] = useState([]);
    const [, force] = useState(null);

    // modal Data from state
    const { modalData, modalMode, modalState } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
            modalLoadFields: state.modal.loadFields,
            modalShow: state.modal.show,
        };
    });

    // table data from state
    const { state: tableState } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    //powershell script for companies
    const { script } = useSelector((state) => {
        return {
            script: { ...state.script },
        };
    });

    // view data from state
    const { breadcrumb, modalTab } = useSelector((state) => {
        return {
            ...state.view,
        };
    });

    // navidation data from state
    const { currentPartner, currentCompanyData, currentWholesaler } =
        useSelector((state) => {
            return { ...state.navigationLists };
        });
    const { general } = walkthroughIds.companies;
    const { approval } = walkthroughIds;

    const navigate = useNavigate();

    // set state function to simplify change handlers
    const setState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };

    const openApprovalModal = () => {
        if (location?.state?.approval) {
            setTimeout(() => {
                dispatch({
                    type: 'OPEN_MODAL',
                    payload: {
                        mode: 'Approval',
                        data: currentCompanyData,
                    },
                });
            }, 1000);
        }
    };

    const redirectToNewCompany = (result) => {
        dispatch({
            type: 'CHANGE_CURRENT_COMPANY',
            payload: result?.data?.id,
        });
        navigate('/dashboard-services');
        window.location.reload();
    };

    const { data: servicesCompany } = useQuery({
        queryKey: ['companyID', modalData?.id],
        queryFn: async () => {
            const res = await axios.get(
                `company/${modalData?.id}/definedattributes`,
            );
            return res.data;
        },
        enabled: Boolean(modalData?.id),
        onSuccess: (data) => {
            dispatch({
                type: 'CHANGE_CURRENT_COMPANY_DATA',
                payload: {
                    ...currentCompanyData,
                    definedAttributes: data,
                },
            });
        },
        // refetchOnWindowFocus: false,
    });
    useEffect(() => {
        openApprovalModal();
    }, [location]);

    // useEffect to set the avilible voice policies based on the regions value
    useEffect(() => {
        if (
            Array.isArray(modalState?.regions) &&
            modalState?.regions.filter((v) => v.id).length >= 1
        ) {
            axios
                .get(
                    `/VoicePolicies/Regions${
                        '?Regions=' +
                        modalState?.regions
                            .filter((v) => v.id)
                            .map((v) => {
                                if (typeof v === 'string') {
                                    return v;
                                }
                                return v?.id;
                            })
                            .join('&Regions=')
                    }`,
                )
                .then((res) => {
                    setVoicePoliciesAvailable(res.data);
                })
                .catch((e) => {
                    onModalError(e);
                });
        } else {
            setVoicePoliciesAvailable([]);
        }
    }, [modalState?.regions]);

    // resets the modal and the table
    const dispatchHandler = () => {
        dispatch({
            type: 'RESET_MODAL',
        });
        dispatch({
            type: 'RESET_TABLE',
        });
    };

    // handle billing note update
    const billingNoteHandler = () => {
        if (modalState.companyBillingSettings.billingNotes?.length) {
            modalState.companyBillingSettings.billingNotes.forEach((note) => {
                if (note.id) {
                    // edit logic
                    axios.put('/billingnote', { ...note });
                } else {
                    // add logic
                    axios.post('/billingnote', { ...note });
                }
            });
        }

        // delete logic
        if (modalState.deletedNoteIDs) {
            modalState.deletedNoteIDs.forEach((note) => {
                if (note.id) {
                    axios.delete('/billingnote/' + note.id);
                }
            });
        }
    };

    //useEffect to create json enum for default columns
    //cases 0,3,20 are different status, 0 = ok, 3 = pending aproval(purple), 20 = red. error, default yellow.
    useEffect(() => {
        if (tableState?.data?.length > 0) {
            const statusEnum = tableState?.data?.reduce((prev, current) => {
                if (!prev[current.status]) {
                    switch (current.status) {
                        case 0:
                            prev[current.status] = (
                                <Tooltip
                                    title={
                                        config.companies.statusNavbarJSON[
                                            current.status
                                        ]
                                    }>
                                    <FiberManualRecordRoundedIcon
                                        style={{
                                            color: 'green',
                                            fontSize: 'small',
                                        }}
                                    />
                                </Tooltip>
                            );
                            break;
                        case 3: //for pending approval
                            prev[current.status] = (
                                <Tooltip
                                    title={
                                        config.companies.statusNavbarJSON[
                                            current.status
                                        ]
                                    }>
                                    <FiberManualRecordRoundedIcon
                                        style={{
                                            color: 'purple',
                                            fontSize: 'small',
                                        }}
                                    />
                                </Tooltip>
                            );
                            break;
                        case 20:
                            prev[current.status] = (
                                <Tooltip
                                    title={
                                        config.companies.statusNavbarJSON[
                                            current.status
                                        ]
                                    }>
                                    <FiberManualRecordRoundedIcon
                                        style={{
                                            color: 'red',
                                            fontSize: 'small',
                                        }}
                                    />
                                </Tooltip>
                            );
                            break;
                        default:
                            prev[current.status] = (
                                <Tooltip
                                    title={
                                        config.companies.statusNavbarJSON[
                                            current.status
                                        ]
                                    }>
                                    <FiberManualRecordRoundedIcon
                                        style={{
                                            color: '#c1c92a',
                                            fontSize: 'small',
                                        }}
                                    />
                                </Tooltip>
                            );
                            break;
                    }
                }
                return prev;
            }, {});

            //After makes json enum, put it in store
            dispatch({
                type: 'CHANGE_TABLE_STATE',
                payload: {
                    ...tableState,
                    columns: defaultColumns.map(({ field, ...rest }) => {
                        if (field === 'status') {
                            return {
                                field,
                                ...rest,
                                lookup: statusEnum,
                                customSort: (l, r) => {
                                    const leftValue =
                                        typeof l === 'object'
                                            ? (l.status ?? 0)
                                            : (l ?? 0);
                                    const rightValue =
                                        typeof r === 'object'
                                            ? (r.status ?? 0)
                                            : (r ?? 0);
                                    return leftValue - rightValue;
                                },
                            };
                        }
                        return {
                            field,
                            ...rest,
                        };
                    }),
                },
            });

            force();
        }
    }, [tableState?.data]);

    // list item component
    const listItemContent = (v) => (
        <li>
            <CloseRoundedIcon
                fontSize="small"
                style={{
                    color: 'red',
                }}
            />
            {v}
        </li>
    );

    // function to render the modal based on the mode
    const modalContent = () => {
        switch (modalMode) {
            case 'Delete':
                return (
                    <Alert style={{ marginTop: 5 }} severity="error">
                        Are you sure you want to delete {modalState?.name} ?
                        This will de-activate {modalState?.name} , set the
                        latest invoice to finalizing, and stop all future
                        billing.
                    </Alert>
                );
            case 'Approval':
                return <Approval />;
            case 'Convert':
                return (
                    <Alert style={{ marginTop: 5 }} severity="error">
                        Are you sure you want to convert {modalState?.name} from
                        trial?
                        <br /> This will: <br />
                        <ul>
                            <li>Set the current invoice to 'ready to post'</li>
                            <li>Remove and de-provision trial numbers</li>
                            <li>
                                Charge setup fees based on the currently
                                assigned plan
                            </li>
                        </ul>
                    </Alert>
                );
            case LOADING_DELETE_MODE:
                return (
                    <CircularProgress
                        style={{ marginTop: '2.5%', marginLeft: '50%' }}
                    />
                );
            case CANNOT_DELETE_MODE:
                return (
                    <>
                        {modalState?.isNextButtonDisabled
                            ? 'Can not delete this Company, as it is still in use.'
                            : 'You can delete this Company.'}
                        <ul className="couldNotDelete">
                            {modalState?.issues?.active?.message ? (
                                <li>
                                    <CloseRoundedIcon
                                        fontSize="small"
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                    {modalState?.issues?.active?.message}
                                </li>
                            ) : (
                                <li>
                                    <CheckRounded
                                        fontSize="small"
                                        style={{
                                            color: 'green',
                                        }}
                                    />
                                    {'This Company is not active.'}
                                </li>
                            )}
                            {modalState?.issues?.users?.message ? (
                                <li>
                                    <CloseRoundedIcon
                                        fontSize="small"
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                    {modalState?.issues?.users?.message}
                                    {modalState?.issues?.users?.response && (
                                        <ul className="couldNotDelete">
                                            {modalState?.issues?.users?.response.map(
                                                listItemContent,
                                            )}
                                        </ul>
                                    )}
                                </li>
                            ) : (
                                <li>
                                    <CheckRounded
                                        fontSize="small"
                                        style={{
                                            color: 'green',
                                        }}
                                    />
                                    {
                                        'There are no Users With Numbers assigned in this Company.'
                                    }
                                </li>
                            )}
                            {modalState?.issues?.resourceAccounts?.message ? (
                                <li>
                                    <CloseRoundedIcon
                                        fontSize="small"
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                    {
                                        modalState?.issues?.resourceAccounts
                                            ?.message
                                    }
                                    {modalState?.issues?.resourceAccounts
                                        ?.response && (
                                        <ul className="couldNotDelete">
                                            {modalState?.issues?.resourceAccounts?.response.map(
                                                listItemContent,
                                            )}
                                        </ul>
                                    )}
                                </li>
                            ) : (
                                <li>
                                    <CheckRounded
                                        fontSize="small"
                                        style={{
                                            color: 'green',
                                        }}
                                    />
                                    {
                                        'There are no Resource Accounts With Numbers assigned in this Company.'
                                    }
                                </li>
                            )}
                            {modalState?.issues?.numberBlocks?.message ? (
                                <li>
                                    <CloseRoundedIcon
                                        fontSize="small"
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                    {modalState?.issues?.numberBlocks?.message}
                                    {modalState?.issues?.numberBlocks
                                        ?.response && (
                                        <ul className="couldNotDelete">
                                            {modalState?.issues?.numberBlocks?.response.map(
                                                listItemContent,
                                            )}
                                        </ul>
                                    )}
                                </li>
                            ) : (
                                <li>
                                    <CheckRounded
                                        fontSize="small"
                                        style={{
                                            color: 'green',
                                        }}
                                    />
                                    {
                                        'There are no Number Blocks assigned to this Company.'
                                    }
                                </li>
                            )}
                        </ul>
                    </>
                );
            default:
                return (
                    <>
                        <TabView
                            centered
                            tabLabels={
                                modalMode === 'Add'
                                    ? ['General']
                                    : [
                                          'General',
                                          'Billing & Invoice',
                                          'Custom',
                                          servicesCompany?.length > 0
                                              ? 'Services'
                                              : null,
                                          'Admin',
                                      ]
                            }
                            contents={[
                                <CompanyEdit
                                    voicePoliciesAvailable={
                                        voicePoliciesAvailable
                                    }
                                    setState={setState}
                                />,
                                <BillingForm mode={COMPANY} />,
                                <EntityCustomFieldsForm mode={COMPANY} />,
                                servicesCompany?.length > 0 ? (
                                    <ChakraProvider
                                        theme={theme}
                                        resetCSS={true}>
                                        <Box mt="1%">
                                            <ServicesProvider>
                                                <Services
                                                    companyID={modalData?.id}
                                                />
                                            </ServicesProvider>
                                        </Box>
                                    </ChakraProvider>
                                ) : null,
                                <AdminForm
                                    mode={COMPANY}
                                    setState={setState}
                                />,
                            ]}
                            insideModal={true}
                        />
                    </>
                );
        }
    };

    // for script view
    if (breadcrumb !== 'all') {
        return (
            <>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        color="inherit"
                        href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch({
                                type: 'CHANGE_BREADCRUMB_VIEW',
                                payload: 'all',
                            });
                            dispatch({
                                type: 'RESET_SCRIPT',
                            });
                        }}>
                        All Companies
                    </Link>
                    <div style={{ minWidth: 500 }}>
                        <LoadingFieldDropdown
                            dropDownValue="id"
                            displayField="name"
                            searchable
                            staticEnum={tableState?.data?.filter(
                                ({ status }) => {
                                    return status === 2 || status === 20;
                                },
                            )}
                            onChange={(v) => {
                                dispatch({
                                    type: 'OPEN_SCRIPT',
                                    payload: { id: v },
                                });
                            }}
                            onDisplayDropdown={(value) => {
                                if (typeof value === 'string') {
                                    const { name, status } =
                                        tableState?.data?.find(
                                            (v) => v.id === value,
                                        );
                                    return (
                                        name +
                                        ' - ' +
                                        config.companies.statusNavbarJSON[
                                            status
                                        ]
                                    );
                                }
                                const { name, status } = value;
                                return (
                                    name +
                                    ' - ' +
                                    config.companies.statusNavbarJSON[status]
                                );
                            }}
                            noEmptyOption
                            fieldValue={script.rowData?.id}
                        />
                    </div>
                </Breadcrumbs>
                <br />
                <Script viewCurrentCompany />
            </>
        );
    }

    // default return table with companies in it
    return (
        <>
            <TableAndModal
                tableRef={tableRef}
                onTableFetch={() => {
                    return axios.get('/companies/List', {
                        params: {
                            wholesalerID: currentWholesaler,
                            partnerID: currentPartner,
                        },
                    });
                }}
                // sort table data for walkthrough id
                onTableSucceed={(data) => {
                    return data.sort(function (a, b) {
                        var nameA = a.name?.toLowerCase(),
                            nameB = b.name?.toLowerCase();
                        if (nameA < nameB)
                            //sort string ascending
                            return -1;
                        if (nameA > nameB) return 1;
                        return 0; //default return value (no sorting)
                    });
                }}
                modalFetch={
                    modalMode === 'Approval'
                        ? `/company/${modalData?.id}/approval/`
                        : `/company/${modalData?.id}`
                }
                onModalSucceed={(res) => {
                    if (modalMode === 'Delete' || modalMode === 'Convert') {
                        dispatch({
                            type: 'RESET_MODAL',
                        });
                        return;
                    }
                    if (modalMode === 'Approval') {
                        dispatch({
                            type: 'CHANGE_CURRENT_COMPANY_DATA',
                            payload: { ...currentCompanyData, ...res.data },
                        });
                        // update the approval modal to close
                        dispatch({
                            type: 'ON_MODAL_SUBMIT_SUCCEED',
                        });
                        dispatch({
                            type: 'CHANGE_TABLE_LOADING',
                        });
                    }
                }}
                modalDelete={`/company/${modalData?.id}`}
                modalConvert={`/company/convertfromtrial/${modalData?.id}`}
                overrideModalAdd={async () => {
                    let compCreated = false;
                    let compData = null;
                    let compCreatedError =
                        "The company has been created, but could not be approved. Please come back to the Companies menu for approval at later time. We are redirecting you to the new company's  dashboard. ";
                    let couldNotCreateError =
                        'Could not Create Company at this time. Please try again later.';
                    try {
                        const createCompResp = await axios.post('/company', {
                            ...modalState,
                            trunkLocationID: 'MEL',
                            enabled: true, //hardcode for partners
                        });

                        compCreated = true;
                        compData = createCompResp;
                        if (modalState?.createWithTrial) {
                            const companyApprovalData = await axios.get(
                                `/company/${createCompResp?.data?.id}/approval/`,
                            );
                            if (companyApprovalData.data?.canApprove) {
                                const companyApprovalWithTrialResp =
                                    await axios.put(
                                        `/company/${createCompResp?.data?.id}/approval?trial=true`,
                                        companyApprovalData.data,
                                    );
                            } else {
                                alert(compCreatedError);
                            }
                        }
                        redirectToNewCompany(createCompResp);
                    } catch (e) {
                        alert(
                            compCreated
                                ? compCreatedError
                                : couldNotCreateError,
                        );
                        if (compCreated) {
                            redirectToNewCompany(compData);
                        }
                    }
                }}
                modalApproval={
                    modalState?.withTrialURL
                        ? '/company/' + modalData?.id + '/approval?trial=true'
                        : '/company/' + modalData?.id + '/approval'
                }
                modalValidate={() => {
                    if (modalMode === 'Approval') {
                        return true;
                    }
                    const newErrorState = {
                        name:
                            (!modalState?.name ||
                                modalState?.name?.length < 1) &&
                            'Field is required.',
                        partnerID:
                            !modalState?.isPartner &&
                            (!modalState?.partnerID ||
                                modalState?.partnerID?.length < 1) &&
                            'Field is required.', //TODO CHANGE!
                        voicePolicies:
                            modalState?.voicePolicies?.some(({ id }) => {
                                return !voicePoliciesAvailable.find(
                                    (vpItem) => vpItem.id === id,
                                );
                            }) && 'Some Voice Policies are not valid.',
                        attribute1:
                            modalState?.attribute1?.length > 100 &&
                            'Maximum field length is 100 characters. ',
                        attribute2:
                            modalState?.attribute2?.length > 100 &&
                            'Maximum field length is 100 characters. ',
                        attribute3:
                            modalState?.attribute3?.length > 100 &&
                            'Maximum field length is 100 characters. ',
                    };

                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !(
                        newErrorState.name ||
                        newErrorState.partnerID ||
                        newErrorState.attribute1 ||
                        newErrorState.attribute2 ||
                        newErrorState.attribute3 ||
                        newErrorState.voicePolicies
                    );
                }}
                modalButtons={
                    (modalMode === 'Approval' && (
                        <>
                            <Button
                                className={
                                    modalState?.canApprove
                                        ? undefined
                                        : 'disabledPointerButton'
                                }
                                // variant={
                                //     modalState?.canApprove
                                //         ? 'primary'
                                //         : 'secondary'
                                // }
                                colorScheme={
                                    modalState?.canApprove ? null : 'secondary'
                                }
                                isDisabled={!modalState?.canApprove}
                                onClick={() => {
                                    setState({ withTrialURL: false });
                                    dispatch({
                                        type: 'ON_MODAL_SUBMIT',
                                        payload: true,
                                    });
                                }}
                                data-walkthroughid={approval.ufApproveButton}>
                                Approve
                            </Button>
                            <Button
                                className={
                                    modalState?.canApprove
                                        ? undefined
                                        : 'disabledPointerButton'
                                }
                                // variant={
                                //     modalState?.canApprove
                                //         ? 'primary'
                                //         : 'secondary'
                                // }
                                colorScheme={
                                    modalState?.canApprove ? null : 'secondary'
                                }
                                isDisabled={!modalState?.canApprove}
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    setState({ withTrialURL: true });
                                    dispatch({
                                        type: 'ON_MODAL_SUBMIT',
                                        payload: true,
                                    });
                                }}
                                data-walkthroughid={
                                    approval.ufApproveAsTrialButton
                                }>
                                Approve As Trial
                            </Button>
                        </>
                    )) ||
                    (modalMode === 'Add' && (
                        <>
                            <Button
                                className="min-w-[5rem] disabled:cursor-not-allowed"
                                disabled={!modalState?.defaultPlanID}
                                // variant={'primary'}
                                onClick={() => {
                                    dispatch({
                                        type: 'ON_MODAL_SUBMIT',
                                        payload: true,
                                    });
                                }}
                                data-walkthroughid={general.ufCreateButton}>
                                Create
                            </Button>
                            <Button
                                className="disabled:cursor-not-allowed"
                                disabled={!modalState?.defaultPlanID}
                                // variant={'primary'}
                                style={{ marginLeft: 24 }}
                                onClick={() => {
                                    setState({ createWithTrial: true });
                                    dispatch({
                                        type: 'ON_MODAL_SUBMIT',
                                        payload: true,
                                    });
                                }}
                                data-walkthroughid={
                                    general.ufCreateAsTrialButton
                                }>
                                Create As Trial
                            </Button>
                        </>
                    )) ||
                    (modalMode === CANNOT_DELETE_MODE && (
                        <Button
                            // variant="danger"
                            colorScheme="red"
                            isDisabled={modalState?.isNextButtonDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch({
                                    type: 'OPEN_MODAL',
                                    payload: {
                                        mode: 'Delete',
                                        uploading: false,
                                        loading: false,
                                        data: modalData,
                                        state: modalState,
                                    },
                                });
                            }}
                            data-walkthroughid={general.ufDeleteButton}>
                            Delete
                        </Button>
                    ))
                }
                //when you edit, set the initial state of the edit
                additionalActions={[
                    (rowData) => ({
                        icon: ({ disabled }) => {
                            if (disabled) {
                                return;
                            }
                            return (
                                <EditRoundedIcon
                                    fontSize="small"
                                    data-walkthroughid={`${general.ufEditButton}/${rowData?.tableData.id}`}
                                />
                            );
                        },
                        tooltip: 'Edit Companies',
                        position: 'row',
                        onClick: (event, rowData) => {
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Edit',
                                    data: rowData,
                                    state: {
                                        isPartner: !rowData.partnerID,
                                    },
                                },
                            });
                        },
                    }),
                ]}
                rowActions={{
                    approvable: true,
                    viewableScript: {
                        onClick: function (event, rowData) {
                            dispatch({
                                type: 'OPEN_SCRIPT',
                                payload: rowData,
                            });
                            dispatch({
                                type: 'CHANGE_BREADCRUMB_VIEW',
                                payload: 'scripts',
                            });
                        },
                        disabled: (rowData) =>
                            !(
                                rowData.o365Sync &&
                                (rowData.status === 2 || rowData.status === 20)
                            ),
                    },
                }}
                dynamicScope={{ minimumScope: 40 }}
                // modalUpdate={`/company/`}
                overrideModalUpdate={() => {
                    axios
                        .put('/company/', modalState)
                        .then((res) => {
                            // NOTE: add more API call to this Promise
                            Promise.all([billingNoteHandler()]).then(
                                (_result) => {
                                    dispatchHandler();
                                },
                            );
                        })
                        .catch((err) => {
                            onModalError(err);
                        });
                }}
                modalUpload={(freshData, state) => {
                    if (state?.trialEndLocal) {
                        return {
                            ...state,
                            trialEnd: new Date(
                                state?.trialEndLocal,
                            )?.toUTCString(),
                            trunkLocationID: 'MEL',
                            trialEndLocal: undefined,
                        };
                    }
                    return {
                        ...state,
                        trunkLocationID: 'MEL',
                        enabled: true, //hardcode for partners
                    };
                }}
                modalInitState={{
                    partnerID: currentPartner,
                    isActive: true,
                    o365Sync: true,
                    selectiveSyncPolicy: 0,
                    noNeedPrompt: true,
                }}
                title="Companies"
                defaultColumns={defaultColumns}
                add
                groupable
                filterable
                maxBodyHeight={
                    window.innerHeight * config.plainView.tableHeight
                }
                modalTitle={modalTitle(
                    modalData,
                    'name',
                    ' Company ',
                    modalMode === CANNOT_DELETE_MODE &&
                        !modalState?.isNextButtonDisabled
                        ? 'Delete'
                        : modalMode,
                )}
                noSaveButton={
                    modalTab == 3 &&
                    modalContent().props.children.props?.tabLabels[modalTab] ===
                        'Services'
                }
                newPage
                modalContent={modalContent()}
            />
        </>
    );
};

export default Companies;
