export const customAutoCompleteStyle = (colorScheme) => ({
    '[class*="MuiInputBase-root"]': {
        borderRadius: '0.375rem',
    },
    '[class*="MuiFormControl-root"]': {
        paddingInline: 0,
    },
    '[role="progressbar"]': {
        width: '1rem!important',
        height: '1rem!important',
        mr: '0.75rem',
    },
    '& [class*="MuiAutocomplete-popupIndicator"]': {
        transform: 'none',
        '& span > svg': {
            transitionDuration: '.4s',
        },
        '&[aria-label="Close"] span > svg': {
            transform: 'rotate(-180deg)',
        },
        '&[aria-label="Open"] span > svg': {
            transform: 'rotate(0deg)',
        },
    },
    '.MuiSelect-root': {
        '&.MuiSelect-select:focus': {
            backgroundColor: 'inherit',
        },
    },
    '[class*="MuiFormLabel-root"].Mui-focused, ': {
        color: `${colorScheme}.500`,
        '[class*="MuiOutlinedInput"].Mui-focused [class*="MuiOutlinedInput-notchedOutline"] ':
            {
                borderColor: `${colorScheme}.500`,
            },
    },
});

export const customPaperStyle = (dark, scrollBarStyle) => ({
    background: dark[0],
    color: '#FFF',
    '& ul': {
        ...scrollBarStyle(true),
    },
    borderRadius: '0.375rem',
    border: `1px solid ${dark[1]}`,
    '&[class*="MuiAutocomplete-paper"]': {
        margin: '6px 0',
    },
    boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
});
