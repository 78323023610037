import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'components/v4';

import walkthroughIds from '../../walkthroughIds';

const ResetModal = ({
    isResetOpen,
    onResetClose,
    isResetBusy,
    apiUserLoading,
    handleReset,
}) => {
    return (
        <Modal isOpen={isResetOpen} onClose={onResetClose} isCentered>
            <ModalOverlay />
            <ModalContent minW={'30%'}>
                <ModalHeader>Reset API Key</ModalHeader>
                <ModalBody>
                    This action will invalidate your current API key and may
                    result in API calls from your application failing until you
                    switch to the new API key. Any external data asociated with
                    the current API key will be transferred to the new API key.
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant="ghost"
                        bg="transparent"
                        isDisabled={isResetBusy}
                        mr={3}
                        colorScheme="black"
                        onClick={() => onResetClose()}
                        data-walkthroughid={
                            walkthroughIds.adminApiUsers.ufClose
                        }>
                        Close
                    </Button>
                    <Button
                        isDisabled={apiUserLoading}
                        variant="solid"
                        colorScheme="red"
                        isLoading={isResetBusy}
                        onClick={() => handleReset()}
                        data-walkthroughid={
                            walkthroughIds.adminApiUsersAdmin.ufModalReset
                        }>
                        Reset
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ResetModal;
