import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initialState } from './constants';
import { LoadingIndicator } from '@/components/v4';
import walkthroughIds from './walkthroughIds';
import { useNavigate, useParams } from 'react-router-dom';
import useGetPermissionSetById from './queries/useGetPermissionSetById';
import useEditPermissionsSet from './queries/useEditPermissionsSet';
import useDeletePermissionsSet from './queries/useDeletePermissionsSet';
import useGetCompanyList from './queries/useGetCompanyList';
import FormBase from '@/components/forms/new/FormBase';
import { useDisclosure } from '@chakra-ui/react';
import PermissionsList from './PermissionsList';
import DeletePermissionsSetModal from './DeletePermissionsSetModal';
import { canEditPermission } from './utils';

import PermissionsCompany from './formFields/PermissionsCompany';
import PermissionsSetName from './formFields/PermissionsSetName';
import PermissionsManageAccess from './formFields/PermissionsManageAccess';

const PermissionsSetsEdit = () => {
    const { ufBackBtn, ufSaveBtn } = walkthroughIds.permissions;
    const { darkMode } = useSelector((state) => state.settings);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { permissionSetId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = useRef(null);
    const currentPartner = useSelector(
        (state) => state.navigationLists?.currentPartner,
    );

    const { currentCompany, currentCompanyData } = useSelector(
        (state) => state.navigationLists,
    );

    const { currentScope, requiredScope, token, ...rest } = useSelector(
        (state) => {
            return { ...state.login };
        },
    );

    const [permissionsSet, setPermissionsSet] = useState(initialState);
    const [oldPermissionsSet, setOldPermissionsSet] = useState({
        ...initialState,
        companyId: currentCompany,
    });
    const [companies, setCompanies] = useState([]);
    const [permissionsSubmitData, setPermissionsSubmitData] = useState({});

    const {
        status: permissionsDataStatus,
        isFetching,
        isRefetching,
        refetch,
    } = useGetPermissionSetById(
        permissionSetId,
        setPermissionsSet,
        setOldPermissionsSet,
    );

    const {
        isFetching: isFetchingCompanies,
        isRefetching: isRefetchingCompanies,
    } = useGetCompanyList(currentPartner, currentScope, setCompanies);

    const { mutate: editPermissionSet } = useEditPermissionsSet(
        permissionsSet,
        requiredScope,
        navigate,
        permissionsSubmitData,
    );

    const { mutate: deletePermissionsSet } = useDeletePermissionsSet(
        permissionSetId,
        token,
        requiredScope,
        dispatch,
        navigate,
    );

    useEffect(() => {
        setPermissionsSet((prev) => ({
            ...prev,
            readOnly: permissionsSet?.readOnly,
            optOutOfNew: permissionsSet?.optOutOfNew,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest?.user]);

    useEffect(() => {
        if (!(isFetching || isRefetching)) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentCompanyData?.mfaEnforced) {
            setPermissionsSet((prev) => ({
                ...prev,
                mfaEnabled: true,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCompanyData?.mfaEnforced]);

    const handleFormSwitchChange = (e) => {
        const { name, checked } = e.target;
        setPermissionsSet((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const fetchingData =
        permissionsDataStatus === 'loading' ||
        isFetching ||
        isRefetching ||
        isFetchingCompanies ||
        isRefetchingCompanies;

    if (fetchingData) return <LoadingIndicator />;

    const breadCrumbTrail = [
        { label: 'Permissions Sets', path: ['/admins', { index: 2 }] },
        { label: 'Permissions Set Edit', path: '' },
    ];
    const header = 'Permissions Sets';

    const finalFormSubmit = (value) => {
        const finalSubmit = {
            ...value,
            companyID: permissionsSet?.companyID,
            companyName: permissionsSet?.companyName,
            readOnly: permissionsSet?.readOnly,
            optOutOfNew: permissionsSet?.optOutOfNew,
        };

        setPermissionsSet(finalSubmit);

        if (permissionSetId) {
            editPermissionSet();
            return;
        }
    };

    const formFields = (errors, touched) => {
        const fields = [
            <PermissionsCompany
                key="company"
                errors={errors}
                permissionsSet={permissionsSet}
                setPermissionsSet={setPermissionsSet}
                currentScope={currentScope}
                accountScope={requiredScope}
                touched={touched}
                darkMode={darkMode}
                companies={companies}
                disabled={permissionSetId}
            />,
            <PermissionsSetName
                key="name"
                errors={errors}
                touched={touched}
                darkMode={darkMode}
            />,
            <PermissionsManageAccess
                key="manageAccess"
                touched={touched}
                permissionSetId={permissionSetId}
                permissionsSet={permissionsSet}
                onOpen={onOpen}
                handleFormSwitchChange={handleFormSwitchChange}
                darkMode={darkMode}
            />,
        ];

        return fields;
    };

    return (
        <>
            <FormBase
                breadCrumbTrail={breadCrumbTrail}
                header={header}
                initialValues={oldPermissionsSet}
                finalFormSubmit={finalFormSubmit}
                backlink={['/admins', { state: { index: 2 } }]}
                extraCards={[
                    <PermissionsList
                        key="permissionsList"
                        data={permissionsSet}
                        disabled={
                            !canEditPermission(
                                permissionsSet,
                                requiredScope,
                                currentCompany,
                            )
                        }
                        submitData={permissionsSubmitData}
                        setSubmitData={setPermissionsSubmitData}
                    />,
                ]}
                ufBackBtn={ufBackBtn}
                ufSaveBtn={ufSaveBtn}>
                {({ errors, touched, values }) =>
                    formFields(errors, touched, values)
                }
            </FormBase>
            <DeletePermissionsSetModal
                isOpen={isOpen}
                onClose={onClose}
                finalRef={finalRef}
                deleteOnClick={deletePermissionsSet}
            />
        </>
    );
};

export default PermissionsSetsEdit;
