import { useState } from 'react';
import { FormControl, Stack, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import NameField from '@/components/forms/new/formikFormFields/NameField';
import DropDown from 'components/forms/new/formikFormFields/DropDown';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../walkthroughIds';

const PermissionsCompany = ({
    errors,
    touched,
    darkMode,
    currentScope,
    accountScope,
    permissionsSet,
    setPermissionsSet,
    companies,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleSelectChange = (item) => {
        setPermissionsSet((prev) => ({
            ...prev,
            companyID: item.id,
            companyName: item.name,
        }));
    };

    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            isInvalid={!!errors.firstName && touched.firstName}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack direction={'row'} spacing={stackSpacing} sx={stackStyling()}>
                <FormLabel sx={formLabelStyling(darkMode)}>Company</FormLabel>
                {currentScope === 20 ? (
                    <Field
                        component={NameField}
                        walkthroughid={walkthroughIds.permissions.ufCompany}
                        id="companyName"
                        name="companyName"
                        disabled
                    />
                ) : (
                    <Field
                        component={DropDown}
                        id="companyName"
                        name="companyName"
                        placeholder="Not Allocated"
                        searchable
                        currentId={permissionsSet?.companyID}
                        handleSelectChange={handleSelectChange}
                        accountScope={accountScope}
                        darkMode={darkMode}
                        data={companies}
                        walkthroughid={walkthroughIds.permissions.ufCompany}
                        validate={() => {
                            if (!permissionsSet?.companyID) {
                                return 'Please select a company';
                            }
                        }}
                    />
                )}
                {isHovered && (
                    <FieldToolTip
                        label="Current Company"
                        ariaLabel="company-tool-tip"
                        left="25px"
                        top="25px"
                    />
                )}
            </Stack>
        </FormControl>
    );
};

export default PermissionsCompany;
