import { useMutation } from '@tanstack/react-query';
import savePermissionsSet from '@/API/PermissionsSets/savePermissionsSet';
import copyPermissionsSet from '@/API/PermissionsSets/copyPermissionsSet';

const useCopyPermissionsSet = ({
    permissionsSet,
    currentCompany,
    navigate,
    copyName,
    accountScope,
}) => {
    const editCopiedPermissionsSetNavigate = (data) => {
        navigate(`permissions/${data.id}/edit`, { state: data });
    };

    return useMutation({
        mutationFn: async () => {
            try {
                const copyRes = await copyPermissionsSet({
                    id: permissionsSet.id,
                    currentCompany: currentCompany,
                });
                const copyPermissionsSetData = { ...copyRes, name: copyName };
                const res = await savePermissionsSet({
                    formData: copyPermissionsSetData,
                    scope: accountScope,
                });
                editCopiedPermissionsSetNavigate(res);
            } catch (error) {
                throw new Error(
                    error?.message || 'Failed to copy permissions set',
                );
            }
        },
        onError: (error) => {
            throw new Error(error?.message || 'Failed to copy permissions set');
        },
    });
};

export default useCopyPermissionsSet;
