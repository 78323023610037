import { useQuery } from '@tanstack/react-query';
import getPermissionsSetById from '@/API/PermissionsSets/getPermissionsSetById';

const useGetPermissionSetById = (
    permissionSetId,
    setPermissionsSet,
    setOldPermissionsSet,
) => {
    return useQuery({
        queryKey: ['permissionsSet', { permissionSetId }],
        queryFn: () => getPermissionsSetById({ id: permissionSetId }),
        enabled: Boolean(permissionSetId),
        onSuccess: (data) => {
            setOldPermissionsSet(data);
            setPermissionsSet(data);
        },
        onError: (error) => {
            throw new Error(error);
        },
        refetchOnWindowFocus: false,
    });
};

export default useGetPermissionSetById;
