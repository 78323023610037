import ProtectedNoMatch from 'components/v4/ProtectedNoMatch';
import { Numbers, Numbers as NumbersLayout } from 'pages/telephony';
import { TeamsPhoneMobileTrial } from 'pages/tpm';

import path from '@/routes/constants';

const NumbersRoutes = [
    {
        children: [
            {
                index: true,
                element: <Numbers />,
            },
            {
                path: 'tpm',
                Component: NumbersLayout,
                children: [
                    {
                        path: path.SERVICES_TPM_TRIAL,
                        element: <TeamsPhoneMobileTrial />,
                    },
                    {
                        path: '*',
                        element: <ProtectedNoMatch />,
                    },
                ],
            },
        ],
    },
];

export default NumbersRoutes;
