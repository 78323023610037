/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { onModalError } from '../../../../components/tables/TableAndModal';
import { LoadingFieldDropdown } from '../../../../components';
import config from '../../../../config.json';
import { axios } from '../../../../services/axios';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import walkthroughIds from '../walkthroughIds';
/*
 * This component returns when you edit a User from the 'User' Tab of the sidebar
 */
const General = () => {
    const dispatch = useDispatch();

    const [availableNumbers, setAvailableNumbers] = useState([]);
    const [thisNumber, setThisNumber] = useState(null);
    const [assignedTrunkType, setAssignedTrunkType] = useState(null);

    // DR
    const [isDrNumber, setIsDrNumber] = useState(false);
    const [drVoicePolicies, setDrVoicePolicies] = useState(null);
    const [drDialPlans, setDrDialPlans] = useState(null);
    const { requiredScope } = useSelector((state) => state.login);

    // path from state
    const { pathname } = useLocation();

    // current company from state
    const { currentCompany, currentCompanyData } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    //per user plan
    const [userPlanMode, setUserPlanMode] = useState('HIDDEN'); // 'HIDDEN' || 'READ_ONLY' || 'WRITABLE'
    const [userPlans, setUserPlans] = useState([]);

    // full set of modal data
    const { modalData, modalState, modalStateError } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
            modalLoadFields: state.modal.loadFields,
            modalShow: state.modal.show,
        };
    });

    const { general } = walkthroughIds;

    // change handlers for the form
    const managedChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                managedBy: v,
            },
        });
    };

    const numberChangeHandler = (v) => {
        if (!v) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    numberID: null,
                    planID: null,
                    voicePolicyID: null,
                    dialPlanID: null,
                },
            });
        }
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                numberID: v,
            },
        });
    };

    const planChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                planID: v,
            },
        });
    };

    const voiceChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                voicePolicyID: v,
            },
        });
    };

    const dialChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                dialPlanID: v,
            },
        });
    };

    /**
     * Get all available numbers belonging to a company.
     */
    useEffect(() => {
        if (currentCompany) {
            axios
                .get(`/company/${currentCompany}/numbers/available`)
                .then((response) => {
                    // Set numbers
                    const numbers = response.data.map((number) => ({
                        ...number,
                        telephoneNumber: `+${number.telephoneNumber} - ${number.trunkType}`,
                    }));
                    setAvailableNumbers(numbers);
                });
        }
    }, [currentCompany]);

    useEffect(() => {
        if (currentCompanyData) {
            const { companyBillingSettings } = currentCompanyData;
            const { perUserPlan } = companyBillingSettings;

            const newPlanMode =
                perUserPlan === 0
                    ? 'HIDDEN'
                    : perUserPlan === 1
                      ? 'WRITABLE'
                      : 'READ_ONLY';
            const fetchPlansList =
                perUserPlan === 1 || (perUserPlan === 2 && requiredScope > 20);
            setUserPlanMode(newPlanMode);
            if (fetchPlansList) {
                axios
                    .get(`/Company/${currentCompany}/Plans/Assigned`)
                    .then((res) => {
                        setUserPlans(res.data);
                    })
                    .catch((e) => {
                        console.error('error fetching plans list', { e });
                    });
            }
        }
    }, [currentCompanyData]);

    const checkIsDRNumber = () => {
        axios.get(`/Number/${modalState?.numberID}`).then((response) => {
            // DR number?
            const newNumberIsDR =
                response.data.trunkType === 'MS Direct Routing';

            setIsDrNumber(newNumberIsDR);
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    isDrNumber: newNumberIsDR,
                },
            });
            if (newNumberIsDR) {
                axios
                    .get(`/service/${response.data.serviceID}`)
                    .then((response) => {
                        setDrVoicePolicies(response.data.voicePolicies);
                        setDrDialPlans(response.data.dialPlans);
                    });
            }
            setThisNumber(response.data);
        });
    };

    useEffect(() => {
        if (thisNumber != null && assignedTrunkType == null) {
            setAssignedTrunkType(thisNumber.trunkType);
        }
    }, [thisNumber]);

    /**
     * If this user has a number set already, pull the details about it.
     * We also wanna flag any DR numbers so we can show the voice policies
     * and dial plans for that service.
     */
    useEffect(() => {
        if (modalState?.numberID) {
            checkIsDRNumber();
            return;
        }
        setIsDrNumber(false);
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                isDrNumber: false,
            },
        });
    }, [modalState?.numberID]);

    return (
        <Box
            sx={{
                py: '10px',
                px: 0,
                maxWidth: '42rem',
                minWidth: '36rem',
                // mt: '1rem',
                mx: 'auto',
            }}>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <LoadingFieldDropdown
                        fieldName="Managed By"
                        noEmptyOption
                        dropDownValue="value"
                        displayField="name"
                        onError={onModalError}
                        onChange={managedChangeHandler}
                        staticEnum={config.users.managedBy}
                        fieldValue={modalState?.managedBy}
                        dataWalkthroughid={general.ufManagedBy}
                    />
                </Col>
            </Row>
            {modalState?.managedBy === 0 && modalState?.isLicensed && (
                <>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                value={modalState?.usageLocation}
                                label="Usage Location"
                                fullWidth
                                disabled
                                data-walkthroughid={general.ufUsageLocation}
                            />
                        </Col>
                    </Row>
                    {(assignedTrunkType ||
                        (assignedTrunkType === null &&
                            thisNumber === null)) && (
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <LoadingFieldDropdown
                                    searchable
                                    staticEnum={availableNumbers}
                                    fieldName="Number"
                                    dropDownValue="id"
                                    displayField="telephoneNumber"
                                    additional={
                                        modalData?.telephoneNumber !==
                                            config.notAllocated.table && [
                                            {
                                                id: modalData?.numberID,
                                                telephoneNumber:
                                                    assignedTrunkType ===
                                                        null ||
                                                    assignedTrunkType === ''
                                                        ? modalData?.telephoneNumber
                                                        : `+${modalData?.telephoneNumber} - ${assignedTrunkType}`,
                                            },
                                        ]
                                    }
                                    onError={onModalError}
                                    onChange={numberChangeHandler}
                                    onDisplayDropdown={(v) => {
                                        return (
                                            (Number(v['telephoneNumber']) &&
                                                '+' + v['telephoneNumber']) ||
                                            v['telephoneNumber']
                                        );
                                    }}
                                    groupBy={({ telephoneNumber }) => {
                                        if (
                                            telephoneNumber.startsWith('611300')
                                        ) {
                                            return '611300';
                                        }
                                        if (
                                            telephoneNumber.startsWith('611800')
                                        ) {
                                            return '611800';
                                        }
                                        if (
                                            telephoneNumber.startsWith('6113')
                                        ) {
                                            return '6113';
                                        }
                                        return telephoneNumber.substring(0, 3);
                                    }}
                                    fieldValue={modalState?.numberID}
                                    dataWalkthroughid={
                                        general.ufTelephoneNumber
                                    }
                                />
                            </Col>
                        </Row>
                    )}

                    {userPlanMode !== 'HIDDEN' && modalState?.numberID && (
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                {(currentCompanyData?.companyBillingSettings
                                    ?.perUserPlan === 1 ||
                                    (currentCompanyData?.companyBillingSettings
                                        ?.perUserPlan === 2 &&
                                        requiredScope > 20)) && (
                                    <LoadingFieldDropdown
                                        staticEnum={userPlans}
                                        searchable
                                        fieldName="Plan"
                                        dropDownValue="id"
                                        displayField="name"
                                        onError={onModalError}
                                        errorText={modalStateError?.planID}
                                        onChange={planChangeHandler}
                                        fieldValue={modalState?.planID}
                                        noneFieldDisplay="Company Default Plan"
                                        data-walkthroughid={
                                            general.ufDefaultPlan
                                        }
                                    />
                                )}
                                {(currentCompanyData?.companyBillingSettings
                                    ?.perUserPlan === 0 ||
                                    (currentCompanyData?.companyBillingSettings
                                        ?.perUserPlan === 2 &&
                                        requiredScope === 20)) &&
                                    modalState && (
                                        <TextField
                                            fullWidth
                                            label={'Plan'}
                                            disabled
                                            type="text"
                                            value={
                                                isEmpty(modalState.plan)
                                                    ? 'Company Default Plan'
                                                    : modalState.plan
                                            }
                                            data-walkthroughid={general.ufPlan}
                                        />
                                    )}
                            </Col>
                        </Row>
                    )}

                    {modalState?.numberID && isDrNumber && (
                        <>
                            <Row style={{ minHeight: 60 }}>
                                <Col>
                                    <LoadingFieldDropdown
                                        staticEnum={drVoicePolicies}
                                        searchable
                                        fieldName="Voice Policy"
                                        dropDownValue="id"
                                        displayField="name"
                                        onError={onModalError}
                                        errorText={
                                            modalStateError?.voicePolicyID
                                        }
                                        onChange={voiceChangeHandler}
                                        fieldValue={modalState?.voicePolicyID}
                                        dataWalkthroughid={
                                            general.ufVoicePolicy
                                        }
                                    />
                                </Col>
                            </Row>
                            {pathname.includes('/users') && (
                                <Row style={{ minHeight: 60 }}>
                                    <Col>
                                        <LoadingFieldDropdown
                                            staticEnum={drDialPlans}
                                            searchable
                                            fieldName="Dial Plan"
                                            dropDownValue="id"
                                            displayField="name"
                                            errorText={
                                                modalStateError?.dialPlanID
                                            }
                                            onError={onModalError}
                                            onChange={dialChangeHandler}
                                            fieldValue={modalState?.dialPlanID}
                                            dataWalkthroughid={
                                                general.ufDialPlan
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default General;
