import appConfig from '../../config.json';

export const IGNORED_MESSAGE = 'Ignored, no changes detected.';
export const SUCCESS_MESSAGE = 'Success.';
export const CSV_TYPE = 'text/csv';
export const CSV_TYPE_ERROR = 'File must be .csv type';

export const trunkUsageDropdown = [
    { id: 'CallingUserAssignment', name: 'Calling User Assignment' },
    // {id: 'ConferenceAssignment', name: 'Conference Assignment'},
    { id: 'FirstPartyAppAssignment', name: 'First Party App Assignment' },
    // {id: 'ThirdPartyAppAssignment', name: 'Third Party App Assignment'},
];

export const ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const TRUNK_USAGE_VALUES = [
    'CallingUserAssignment',
    'FirstPartyAppAssignment',
];
export const TEMPLATE_FILENAME = 'NumbersUpdateTemplate.csv';

export const VERIFICATION_CHECKS = [
    {
        label: 'Received CSV',
        check: false,
        current: true,
        completionCounter: 0,
        total: 1,
    },
    {
        label: 'Fetching Company Data',
        check: false,
        current: false,
        completionCounter: 0,
        total: 4,
    },
    {
        label: 'Converting CSV',
        check: false,
        current: false,
        completionCounter: 0,
        total: 3,
    },
    {
        label: 'Verifying Numbers',
        check: false,
        current: false,
        completionCounter: 0,
        total: 2,
    },
    {
        label: 'Verifying Trunks',
        check: false,
        current: false,
        completionCounter: 0,
        total: 2,
    },
    {
        label: 'Verifying Addresses',
        check: false,
        current: false,
        completionCounter: 0,
        total: 1,
    },
    {
        label: 'Verifying Outbound Caller IDs',
        check: false,
        current: false,
        completionCounter: 0,
        total: 1,
    },
];

export const DEFAULT_VERIFICATION_RESULT = {
    passed: {
        columns: [],
        data: [],
    },
    failed: {
        columns: [],
        data: [],
    },
};

export const SHARED_FIELDS_TO_EXPORT = {
    id: 'Number ID',
    telephoneNumber: 'Telephone Number',
    cliPresentation: 'Outbound Caller ID',
    trunk: 'Trunk',
    operatorConnectCallingProfile: 'Operator Connect Calling Profile',
    operatorConnectUsageID: 'Operator Connect Telephone Number Usage',
    address: 'Address',
    overstampNumber: 'Overstamp Number',
    attribute1: 'Attribute 1',
    attribute2: 'Attribute 2',
    attribute3: 'Attribute 3',
};
export const FIELDS_TO_EXPORT = {
    ...SHARED_FIELDS_TO_EXPORT,
    errors: 'Errors',
};

export const INVALID_FILENAME = 'InvalidNumbers.csv';
export const FAILED_FILENAME = 'FailedNumbers.csv';

export const API_RESULT_FIELDS_TO_EXPORT = {
    ...SHARED_FIELDS_TO_EXPORT,
    apiErrors: 'Errors',
};

export const haveErrorsFilter = (v) => v.errors.length > 0;
export const isIgnoredFilter = (v) => v.ignored;

export const FIELDS_CHANGE_NEED_VALIDATION = [
    'trunk',
    'addressID',
    'cliPresentation',
    'operatorConnectCallingProfile',
    'operatorConnectUsageID',
];

export const FIELDS_CHANGE_NO_NEED_VALIDATION = [
    'overstampNumber',
    'attribute1',
    'attribute2',
    'attribute3',
];

export const additionalOutbound = [
    {
        telephoneNumber: 'Restricted',
    },
];

export const succeedAPIColumns = [
    {
        title: 'Telephone Number',
        field: 'telephoneNumber',
    },
];

export const failedAPIColumns = [
    {
        title: 'Telephone Number',
        field: 'telephoneNumber',
    },
    {
        title: 'Failure Reasons',
        field: 'apiErrors',
    },
];

export const NUMBERS_DNE_ERROR =
    'This number ID does not exist in your company.';
export const NUMBER_NOT_OK_ERROR = 'This number is not in editable status.';
export const NO_DATA_FOUND_ERROR = "We didn't detect any changes.";
export const CSV_LIMIT_REACHED_ERROR = `Upload file contains more than ${appConfig.csvLimit} records.`;
export const CSV_LIMIT_REACHED_DESCRIPTION_ERROR = `If you need to add more than ${appConfig.csvLimit} entries, create and upload additional data files.`;
export const INVALID_CP_ERROR = 'This number has invalid Calling Profile.';
export const INVALID_TNU_ERROR =
    'This number has invalid Telephone Number Usage.';
export const TRUNKS_DNE_ERROR = 'This trunk does not exist in your company.';
export const ADDRESS_DNE_ERROR = 'This address does not exist in your company.';
export const ADDRESS_WRONG_ERROR = 'Cannot identify this address description.';
export const ADDRESS_CANNOT_ALLOCATED =
    'This address cannot be allocated to this number.';
export const OUTBOUND_DNE_ERROR =
    'This Outbound Caller ID does not exist in your company.';

export const defaultColumns = [
    {
        title: 'Telephone Number',
        field: 'telephoneNumber',
        defaultSort: 'asc',
    },

    {
        title: 'Outbound Caller ID',
        field: 'cliPresentation',
    },

    {
        title: 'Trunk',
        field: 'trunk',
    },
    {
        title: 'Calling Profile',
        field: 'operatorConnectCallingProfile',
    },
    {
        title: 'Usage ID',
        field: 'operatorConnectUsageID',
    },
    {
        title: 'Address',
        field: 'address',
    },
    {
        title: 'Custom Attribute 1',
        field: 'attribute1',
    },
    {
        title: 'Custom Attribute 2',
        field: 'attribute2',
    },
    {
        title: 'Custom Attribute 3',
        field: 'attribute3',
    },
];

export const passedColumns = [
    {
        title: 'Telephone Number',
        field: 'telephoneNumber',
    },

    {
        title: 'Outbound Caller ID',
        field: 'cliPresentation',
    },

    {
        title: 'Trunk',
        field: 'trunk',
    },
    {
        title: 'Calling Profile',
        field: 'operatorConnectCallingProfile',
    },
    {
        title: 'Usage ID',
        field: 'operatorConnectUsageID',
    },
    {
        title: 'Address',
        field: 'address',
    },
    {
        title: 'Custom Attribute 1',
        field: 'attribute1',
    },
    {
        title: 'Custom Attribute 2',
        field: 'attribute2',
    },
    {
        title: 'Custom Attribute 3',
        field: 'attribute3',
    },
    {
        title: 'Verification Status',
        field: 'verificationStatus',
    },
];

export const failedColumns = [
    ...defaultColumns,
    {
        title: 'Failure reasons',
        field: 'errors',
        width: '40%',
        render: (rowData) => {
            if (Array.isArray(rowData.errors) && rowData.errors.length > 0) {
                const errorString = rowData.errors.join('\n');
                return (
                    <span style={{ whiteSpace: 'pre-line' }}>
                        {' '}
                        {errorString}{' '}
                    </span>
                );
            }
            return null;
        },
    },
];
