import { useQuery } from '@tanstack/react-query';
import getCompanies from '@/API/Company/getCompanies';

const useGetCompanyList = async (
    currentPartner,
    currentScope,
    setCompanies,
) => {
    return useQuery({
        queryKey: ['companies', { currentPartner, currentScope }],
        queryFn: () =>
            getCompanies(currentPartner, currentScope).then((res) => res.data),
        onSuccess: (data) => setCompanies(data),
        refetchOnWindowFocus: false,
    });
};
export default useGetCompanyList;
