import ScopeType from '@/utils/enums/ScopeType';

export const canEditPermission = (data, requiredScope, currentCompany) => {
    const canEditCondition = (scope) =>
        !(
            !data?.companyID ||
            data?.requiredScope > scope ||
            data?.companyID !== currentCompany
        );
    switch (requiredScope) {
        case ScopeType.COMPANY:
            return canEditCondition(ScopeType.COMPANY);
        case ScopeType.PARTNER:
            return canEditCondition(ScopeType.PARTNER);
        case ScopeType.WHOLESALER:
            return canEditCondition(ScopeType.COMPANY);
        default:
            return true;
    }
};
