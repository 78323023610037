import { axios } from '@/services/axios';

const deletePermissionsSet = async ({ id, scope, token }) => {
    const res = await axios.delete(`/permissionset/${id}`, {
        headers: {
            authorization: `Bearer ${token}`,
            'X-RequestScope': scope,
        },
    });
    return res.data;
};

export default deletePermissionsSet;
