import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import config from '@/config.json';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import walkthroughIds from '../walkthroughIds';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'pages/auth/new/sso/config/authConfig';

import {
    customTabStylesCombineTheme,
    selectedTabStylesCombineTheme,
} from '@/constants';
import SSO from './sso/SSO';
import PasswordPolicy from 'pages/settingsSidebar/security/passwordPolicy/PasswordPolicy';

const pca = new PublicClientApplication(msalConfig);

const Security = () => {
    const location = useLocation();
    const { state } = location;

    const [tabIndex, setTabIndex] = useState(
        state?.index != null ? state.index : 0,
    );

    const { darkMode } = useSelector((state) => state.settings);
    const title = 'Security';

    return (
        <>
            <h1 className="mb-2 text-[2.5rem] font-medium">{title}</h1>
            <Tabs
                index={tabIndex}
                isLazy
                onChange={(index) => {
                    setTabIndex(index);
                }}>
                <TabList borderBottom={'none'} mb={4}>
                    {config.settingsSidebar.security.tabsList.map(
                        (tab, index) => (
                            <Tab
                                data-walkthroughid={
                                    walkthroughIds.ufTabButton + tab
                                }
                                key={index}
                                onMouseDown={(e) =>
                                    e.button === 2 && e.preventDefault()
                                }
                                sx={{
                                    ...customTabStylesCombineTheme,
                                    color: darkMode
                                        ? 'rgba(255, 255, 255)'
                                        : 'inherit',
                                }}
                                _selected={{
                                    ...selectedTabStylesCombineTheme,
                                }}>
                                {tab}
                            </Tab>
                        ),
                    )}
                </TabList>
                <TabPanels>
                    <TabPanel padding={0}>
                        {tabIndex == 0 && <PasswordPolicy />}
                    </TabPanel>
                    <TabPanel padding={0}>
                        <MsalProvider instance={pca}>
                            <SSO />
                        </MsalProvider>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default Security;
