export const initialState = {
    firstName: '',
    lastName: '',
    isActive: true,
    email: '',
    permissionSetID: '',
    mfaEnabled: true,
    canOverrideOverStamping: false,
    apiDocReader: false,
};
export const tabList = ['General', 'Admin'];
export const mfaDropdown = [
    { label: 'Enabled', value: true },
    { label: 'Disabled', value: false },
];

export const errorMessage = {
    401: 'Unauthorized to change this user!',
    403: 'Forbidden to change this user!',
};

export const ACTIONS = {
    LOGOUT: 'LOGOUT',
    CLEAR_TOKEN: 'CLEAR_TOKEN',
};

export const initFormData = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    companyID: '',
    isActive: false,
    username: '',
    canOverrideOverStamping: false,
    permissionSetID: '',
    permissionSet: '',
    apiDocReader: false,
    lastLogin: '',
    mfatotpConfirmed: false,
    mfaEnabled: false,
};

export const ERROR_EMAIL = 'Invalid email address';
