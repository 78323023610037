import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    HStack,
    Icon,
    Stack,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLayoutContext } from 'context/LayoutContext';

import { AnimatedTextWord } from './AnimatedTextWord';
import { _hover, accordionItemStyle, colorMode } from './constants';
import { routes } from './Routes';

import { SidebarItemList } from './index';

import { whitelist as ALLOWED_ROUTES } from '@/lib/data-router/constants';

function SidebarNoRerender() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isMenuCollapse, touched: isCollapseBtnTouched } =
        useLayoutContext();
    const { requiredScope } = useSelector((state) => state.login);
    const allowMenuItems = routes.filter(
        (parentRoute) => requiredScope >= parentRoute.scope,
    );
    const defaultIndex = allowMenuItems.findIndex((parentRoute) =>
        parentRoute.children.find((item) => pathname.includes(item.path)),
    );

    const currentPaths = window.location.pathname.split('/');
    const currentSubPath = currentPaths.length >= 2 && currentPaths[2];

    const selectedItem =
        defaultIndex &&
        allowMenuItems[defaultIndex]?.children.find((item) =>
            pathname.includes(item.path),
        );

    const isAllowed = allowMenuItems.some(
        (item) =>
            item.children &&
            item.children.some(
                (child) =>
                    (pathname.includes(child.path) ||
                        ALLOWED_ROUTES.includes(
                            currentSubPath.toLowerCase(),
                        )) && // NOTE: 3.39 allow all csv pages for now
                    requiredScope >= child.scope,
            ),
    );
    // TODO: change to protected route if can
    useEffect(() => {
        if (!isAllowed) {
            toast.error(`You do not have access to ${pathname?.split('/')[1]}`);
            navigate('/dashboard-services');
        }
    }, [pathname, isAllowed, navigate]);

    // TODO: 3.39.0: run this redirect only on mount
    useEffect(() => {
        if (!selectedItem) return;
        dispatch({
            type: 'REDIRECT',
            payload: {
                pathname: `${selectedItem.path}/${selectedItem.permissionName}`,
                scope: selectedItem.scope ?? 20,
            },
        });
    }, [selectedItem, dispatch]);

    return (
        <Accordion allowMultiple defaultIndex={[defaultIndex, 0]}>
            {allowMenuItems.map((parentRoute) => (
                <AccordionItem
                    key={parentRoute.label}
                    border="none"
                    aria-label="parent-path"
                    sx={accordionItemStyle}
                    maxW={isMenuCollapse ? ' 64px' : null}>
                    {({ isExpanded }) => (
                        <>
                            <Tooltip
                                label={
                                    isMenuCollapse && !isExpanded
                                        ? parentRoute.label
                                        : null
                                }
                                hasArrow
                                placement="right-end">
                                <AccordionButton
                                    className={`${parentRoute.label}`}
                                    padding="4px"
                                    height="32px"
                                    minW={isMenuCollapse ? '50px' : 'full'}
                                    borderRadius="md"
                                    justifyContent="space-between"
                                    _focus={{ boxShadow: 'none' }}
                                    _hover={colorMode[_hover]}
                                    color="white">
                                    <HStack
                                        spacing="3"
                                        aria-label="icon and parent path label">
                                        <Icon
                                            fontSize="lg"
                                            mr={2}
                                            ml={1}
                                            as={parentRoute.Icon}
                                        />

                                        {isMenuCollapse ? null : isCollapseBtnTouched ? (
                                            <AnimatedTextWord
                                                text={parentRoute.label}
                                            />
                                        ) : (
                                            <Text fontSize="sm" as="span">
                                                {parentRoute.label}
                                            </Text>
                                        )}
                                    </HStack>
                                    <AccordionIcon
                                        className={
                                            isMenuCollapse ? '-ml-1' : 'ml-auto'
                                        }
                                    />
                                </AccordionButton>
                            </Tooltip>
                            <AccordionPanel p={0}>
                                <Stack
                                    spacing="1"
                                    alignItems="stretch"
                                    aria-label="sidebar-item-list">
                                    <SidebarItemList
                                        parentRoute={parentRoute}
                                        requiredScope={requiredScope}
                                        colorMode={colorMode}
                                    />
                                </Stack>
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            ))}
        </Accordion>
    );
}
RouterLink.displayName = 'RouterLink';
export default SidebarNoRerender;
