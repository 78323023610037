import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
} from '@mui/material';

import { Alert, AlertIcon } from '@/components/v4';

import walkthroughIds from '../walkthroughIds';

export default function DeleteConfirmation({ mode, show }) {
    const dispatch = useDispatch();
    const { deleteModal } = walkthroughIds;

    const handleCloseModal = () => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                showPrompt: undefined,
            },
        });
    };
    const handleDelete = () => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                isActive: false,
                showPrompt: undefined,
            },
        });
        dispatch({
            type: 'ON_MODAL_SUBMIT',
            payload: true,
        });
    };

    return (
        <Dialog open={show} onClose={handleCloseModal}>
            <DialogTitle>{`Delete ${mode}`}</DialogTitle>
            <DialogContent data-walkthroughid={deleteModal.ufDeleteModal}>
                <DialogContentText>
                    <Alert style={{ marginTop: 5 }} status="error">
                        <AlertIcon />
                        {`Would you like to delete this ${mode}?`}
                    </Alert>
                </DialogContentText>
            </DialogContent>
            <DialogActions
                style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="secondary"
                    onClick={handleCloseModal}
                    data-walkthroughid={deleteModal.ufCancelBtn}>
                    {'Cancel'}
                </Button>
                <Button
                    variant="danger"
                    onClick={handleDelete}
                    data-walkthroughid={deleteModal.ufDeleteBtn}>
                    {'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export const DeleteButton = () => {
    const dispatch = useDispatch();

    const handleShowPrompt = () => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                showPrompt: true,
            },
        });
    };

    return (
        <Button
            style={{ marginLeft: '10px', marginTop: '0px', fontWeight: '600' }}
            variant="danger"
            onClick={handleShowPrompt}>
            {'Delete'}
        </Button>
    );
};
