//Deals with log in functions and also current and stored scope and minimum scope
//current scope is the drop down up the top right
//move to menu that can only be glocal,

const initLoginState = {
    loading: false,
    reLogin: false,
    loggedIn: false,
    scope: 0,
    email: '',
    currentScope: 20,
    minimumScope: 20,
    storedScope: 20,
    isCurrentUserMfaEnabled: false,
    sbcDomain: '',
    showMFA: false,
    apiDocReader: false,
};

export const loginReducer = (state = initLoginState, action) => {
    switch (action.type) {
        case 'SET_SBC_DOMAIN':
            return {
                ...state,
                sbcDomain: action.payload,
            };
        case 'SET_SUPPORT_URL':
            return {
                ...state,
                extLinkSupport: action.payload,
            };
        case 'SET_IS_CURRENT_USER_MFA_ENABLED':
            return {
                ...state,
                isCurrentUserMfaEnabled: action.payload,
            };
        case 'CHANGE_IMAGE_PATH_LIGHT_MODE':
            return {
                ...state,
                imagePathLightMode: action.payload,
            };
        case 'CHANGE_IMAGE_PATH_DARK_MODE':
            return {
                ...state,
                imagePathDarkMode: action.payload,
            };
        case 'SET_IMAGE_FILE_TYPE':
            return {
                ...state,
                imageFileType: action.imageFileType,
            };
        case 'CHANGE_EMAIL':
            return {
                ...state,
                email: action.payload,
            };
        case 'RELOGIN':
            return {
                ...state,
                reLogin: true,
            };
        case 'RENEW':
            return state;
        case 'RELOGIN_SUCCESS':
            return {
                ...state,
                ...action.payload,
                reLogin: false,
                loading: false,
                loggedIn: true,
                showMFA: false,
            };
        case 'ERROR_401':
            return {
                ...state,
                error401: true,
            };
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                ...action.payload,
                reLogin: false,
                loading: false,
                loggedIn: true,
                showMFA: false,
            };
        case 'LOAD_ACCESS_LIST':
            return {
                ...state,
                loadAccessList: true,
            };
        case 'CHANGE_REQUIRED_SCOPE':
            return {
                ...state,
                requiredScope: action.payload,
            };
        case 'ACCESS_LIST_SUCCESS':
            return {
                ...state,
                loadAccessList: false,
            };
        case 'ACCESS_LIST_ERROR':
            return {
                ...state,
                loadAccessList: false,
                accessListError: action.payload,
            };
        case 'LOGOUT':
            return {
                ...initLoginState,
            };
        case 'CHANGE_CURRENT_SCOPE':
            return {
                ...state,
                currentScope: action.payload,
                storedScope: action.payload,
            };
        case 'CHANGE_MINIMUM_SCOPE':
            return {
                ...state,
                minimumScope: action.payload,
                currentScope: Math.max(action.payload, state.currentScope),
            };
        case 'LOGIN_MFA_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'CLEAR_TOKEN':
            return {
                loading: false,
                reLogin: false,
                loggedIn: false,
                scope: 0,
                email: '',
                currentScope: 20,
                minimumScope: 20,
                storedScope: 20,
                isCurrentUserMfaEnabled: false,
                sbcDomain: '',
                showMFA: false,
                apiDocReader: false,
            };
        case 'REDIRECT':
            if (!state.storedScope) {
                return {
                    ...state,
                    currentScope: Math.max(
                        state.currentScope,
                        action.payload.scope,
                    ),
                    minimumScope: action.payload.scope,
                    storedScope: state.currentScope,
                };
            }
            return {
                ...state,
                currentScope: Math.max(state.storedScope, action.payload.scope),
                minimumScope: action.payload.scope,
            };
        case 'INIT_DEFAULT_ENTITIES':
        case 'SAVE_USER_SETTINGS': {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
