/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import './style.css';
import config from '../../../config.json';
import useGetPermissions from './queries/useGetPermissions';

const PermissionsList = ({ disabled, data, submitData, setSubmitData }) => {
    const [expanded, setExpanded] = useState({});
    const [permissions, setPermissions] = useState();

    const handleOnChange = ({ e, permissionName, scopeNumber, mode }) => {
        var newData = { ...permissions };
        newData[permissionName][scopeNumber][mode] = e.target.checked;

        var newSubmitData = { ...submitData };
        if (!newSubmitData[newData[permissionName][scopeNumber].id]) {
            newSubmitData[newData[permissionName][scopeNumber].id] = {
                ...newData[permissionName][scopeNumber],
                [mode]: e.target.checked,
            };
        } else {
            newSubmitData[newData[permissionName][scopeNumber].id][mode] =
                e.target.checked;
        }
        setSubmitData(newSubmitData);
    };

    const handleExpand = (panel) => () => {
        setExpanded({ ...expanded, [panel]: !expanded[panel] });
    };

    const { isFetching, isRefetching } = useGetPermissions(
        data?.id,
        setPermissions,
        setSubmitData,
    );

    const fetchingData = isFetching || isRefetching || !permissions;

    if (fetchingData) {
        return (
            <CircularProgress
                style={{ marginTop: '2.5%', marginLeft: '50%' }}
            />
        );
    }

    return (
        <div id="permissions-table-component">
            <div className="table-content">
                {Object.entries(permissions)
                    .sort((left, right) => left[0].localeCompare(right[0]))
                    .map(([permissionName, value]) => (
                        <Accordion
                            key={`${permissionName}`}
                            expanded={expanded[permissionName]}
                            onChange={handleExpand(permissionName)}>
                            <AccordionSummary
                                key={`${permissionName}`}
                                expandIcon={<ExpandMoreIcon />}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {permissionName}
                                        </Typography>
                                    </Grid>
                                    {expanded[permissionName] &&
                                        [
                                            'Create',
                                            'Read',
                                            'Update',
                                            'Delete',
                                        ].map((mode) => (
                                            <Grid
                                                item
                                                xs={2}
                                                key={`${permissionName}-${mode}`}>
                                                <Typography>{mode}</Typography>
                                            </Grid>
                                        ))}
                                </Grid>
                            </AccordionSummary>
                            {[20, 40, 60, 80]
                                .filter((scopeNumber) => value[scopeNumber])
                                .map((scopeNumber) => (
                                    <AccordionDetails
                                        key={`${permissionName}-${scopeNumber}`}>
                                        <div className="table-data">
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {
                                                        config.scope.json[
                                                            scopeNumber
                                                        ]
                                                    }
                                                </Grid>
                                                {[
                                                    'create',
                                                    'read',
                                                    'update',
                                                    'delete',
                                                ].map((mode) => (
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        key={`${permissionName}-${scopeNumber}-${mode}`}>
                                                        <Checkbox
                                                            disabled={disabled}
                                                            checked={
                                                                value[
                                                                    scopeNumber
                                                                ][mode]
                                                            }
                                                            onChange={(e) => {
                                                                handleOnChange({
                                                                    e,
                                                                    mode,
                                                                    scopeNumber,
                                                                    permissionName,
                                                                });
                                                            }}
                                                            data-walkthroughid={`/permissions/permissionsTab/${permissionName}/${mode}`}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                ))}
                        </Accordion>
                    ))}
            </div>
        </div>
    );
};

export default PermissionsList;
