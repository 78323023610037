import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react';
import { FormLabel, Input } from 'components/v4';

import walkthroughIds from './walkthroughIds';

//Copy field for single textfield components when copying modals
const ModalCopy = ({ setModalState, modalState, modalStateError }) => {
    const handleModalCopy = (e) => {
        setModalState({ copyName: e.target.value });
    };
    return (
        <>
            <FormControl>
                <FormLabel>Copy as</FormLabel>
                <Input
                    value={modalState?.copyName}
                    onChange={handleModalCopy}
                    data-walkthroughid={walkthroughIds.modal.ufCopyInput}
                />
                {modalStateError?.copyName ? (
                    <FormErrorMessage>
                        {modalStateError?.copyName}
                    </FormErrorMessage>
                ) : (
                    <FormHelperText>{modalStateError?.copyName}</FormHelperText>
                )}
            </FormControl>
        </>
    );
};

export default ModalCopy;
