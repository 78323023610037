import { useMutation } from '@tanstack/react-query';
import savePermissionsSet from '@/API/PermissionsSets/savePermissionsSet';
import savePermission from '@/API/Permissions/savePermission';
import { toast } from 'react-toastify';

const useEditPermissionsSet = (
    permissionsSet,
    accountScope,
    navigate,
    submitData,
) => {
    return useMutation({
        mutationFn: async () => {
            try {
                savePermissionsSet({
                    formData: permissionsSet,
                    scope: accountScope,
                }).then(() => {
                    const permissionsSubmit = Object.values(submitData).map(
                        (data) => savePermission({ data: data }),
                    );

                    Promise.all(permissionsSubmit).then(() => {
                        toast.success('Permissions set updated successfully');
                        navigate('/admins', { state: { index: 2 } });
                    });
                });
            } catch (error) {
                toast.error(
                    error?.message || 'Failed to update permissions set',
                );
                throw new Error(
                    error?.message || 'Failed to update permissions set',
                );
            }
        },
        onError: (error) => {
            toast.error(error?.message || 'Failed to update permissions set');
            throw new Error(
                error?.message || 'Failed to update permissions set',
            );
        },
    });
};

export default useEditPermissionsSet;
