import { useState } from 'react';
import { FormControl, Stack, FormLabel, Box, Text } from '@chakra-ui/react';
import { Field } from 'formik';
import PermissionsSetDropDown from '@/components/forms/new/formikFormFields/PermissionSetDropDown';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
    descriptionStyling,
} from '@/components/forms/new/formStyles';
import walkthroughIds from '../../walkthroughIds';

const APIUserPermissionSetDropdown = ({
    permissionSetID,
    currentCompany,
    accountScope,
    setApiUser,
    errors,
    touched,
    darkMode,
    values,
}) => {
    const handleSelectChange = (item, name) => {
        setApiUser((prev) => ({
            ...prev,
            name: values.name,
            [name]: item.id,
        }));
    };

    const [isHovered, setIsHovered] = useState(false);

    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isInvalid={!!errors.permissionSetID && touched.permissionSetID}>
            <Stack
                direction={'row'}
                spacing={stackSpacing}
                sx={stackStyling(true)}>
                <Box>
                    <FormLabel sx={formLabelStyling(darkMode)}>
                        Permission Set{' '}
                        <span style={{ color: '#e53e3e' }}>*</span>
                    </FormLabel>
                    <Text sx={descriptionStyling} maxWidth={250}>
                        Apply a permission set to the API User to customise the
                        management experience.
                    </Text>
                </Box>
                <Field
                    component={PermissionsSetDropDown}
                    id="permissionSetID"
                    name="permissionSetID"
                    permissionSetID={permissionSetID}
                    handleSelectChange={handleSelectChange}
                    currentCompany={currentCompany}
                    accountScope={accountScope}
                    darkMode={darkMode}
                    walkthroughid={
                        walkthroughIds.adminApiUsersGeneral.ufPermission
                    }
                    validate={() => {
                        if (!permissionSetID) {
                            return 'Please select a Permission Set';
                        }
                    }}
                />
                {isHovered && (
                    <FieldToolTip
                        label="Permission sets control what an API User can and cannot do in TCAP"
                        ariaLabel="api-user-permissionSet-tip"
                        left="25px"
                        top="25px"
                    />
                )}
            </Stack>
        </FormControl>
    );
};

export default APIUserPermissionSetDropdown;
