import {
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    forwardRef,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { isEmpty } from 'lodash';

import { Input } from '@/components/v4';

import {
    NOT_ALLOCATED,
    PlanMode,
    managedByOptions,
} from '@/pages/accounts/resourceAccounts/constants';

const ChakraSelect = forwardRef((props, ref) => (
    <Select selectedOptionColor="brand" ref={ref} {...props} />
));
export default function General({
    state,
    validation,
    userPlans,
    numberList,
    ReAccData,
    handleManagedBy,
    handleChangeNumber,
    handleChangePlan,
    handleChangeVoicePolicy,
    chakraStyles,
    enabledPlanMode,
    partnerOnlyPlanMode,
    disabledPlanMode,
    isLoading,
    // assignedTrunkType,
}) {
    const plans = userPlans?.length && [
        ...userPlans,
        {
            id: null,
            name: 'Company Default Plan',
        },
    ];
    return (
        <Stack spacing="3">
            {/* Managed By */}
            <FormControl>
                <FormLabel>Managed By</FormLabel>
                <ChakraSelect
                    name="managedBy"
                    options={managedByOptions}
                    value={managedByOptions.find(
                        (item) => item.value === state.managedBy,
                    )}
                    onChange={(item) => handleManagedBy(item)}
                    chakraStyles={chakraStyles}
                    isLoading={isLoading}
                    placeholder="Select Managed By"
                />
            </FormControl>
            {state.managedBy === 0 && state.isLicensed && (
                <>
                    {/* Usage Location */}
                    <FormControl>
                        <FormLabel>Usage Location</FormLabel>
                        <Input
                            name="usageLocation"
                            value={state.usageLocation}
                            isDisabled
                        />
                    </FormControl>

                    {/* Number */}
                    <FormControl>
                        <FormLabel>Number</FormLabel>
                        <ChakraSelect
                            name={'number'}
                            options={numberList}
                            value={numberList?.find(
                                (num) => num.id === state.numberID,
                            )}
                            onChange={(v) => handleChangeNumber(v, numberList)}
                            getOptionLabel={(i) => i.telephoneNumber}
                            getOptionValue={(i) => i.id}
                            chakraStyles={chakraStyles}
                            formatOptionLabel={(option) =>
                                (Number(option['telephoneNumber']) &&
                                    `+ ${option['telephoneNumber']}`) ||
                                option['telephoneNumber']
                            }
                            placeholder={NOT_ALLOCATED}
                            // isLoading={state?.numberID && !assignedTrunkType}
                        />
                    </FormControl>

                    {/* Plan */}
                    {state.numberID &&
                        state.userPlanMode !== PlanMode.HIDDEN && (
                            <FormControl>
                                {(enabledPlanMode || partnerOnlyPlanMode) && (
                                    <>
                                        <FormLabel>Plan</FormLabel>
                                        <ChakraSelect
                                            options={plans}
                                            value={plans?.find(
                                                (item) =>
                                                    item.id === state.planID,
                                            )}
                                            onChange={handleChangePlan}
                                            placeholder="Company Default Plan"
                                            getOptionLabel={(i) => i.name}
                                            getOptionValue={(i) => i.id}
                                            chakraStyles={chakraStyles}
                                        />
                                    </>
                                )}
                                {disabledPlanMode && ReAccData && (
                                    <>
                                        <FormLabel>Plan</FormLabel>
                                        <Input
                                            isDisabled
                                            type="text"
                                            value={
                                                isEmpty(state?.plan)
                                                    ? 'Company Default Plan'
                                                    : state.plan
                                            }
                                        />
                                    </>
                                )}
                            </FormControl>
                        )}

                    {/* IsDrNumber check AND Voice Policies */}
                    {state.isDrNumber && (
                        <FormControl isInvalid={!state?.voicePolicyID}>
                            <FormLabel>Voice Policy</FormLabel>
                            <ChakraSelect
                                options={state?.voicePolicies}
                                value={state?.voicePolicies?.find(
                                    (item) => item.id === state.voicePolicyID,
                                )}
                                getOptionLabel={(i) => i.name}
                                getOptionValue={(i) => i.id}
                                onChange={handleChangeVoicePolicy}
                                placeholder={NOT_ALLOCATED}
                                chakraStyles={chakraStyles}
                                isLoading={!state?.voicePolicies}
                                // isDisabled={!state.voicePolicies}
                            />
                            <FormErrorMessage>
                                {!state?.voicePolicyID &&
                                    (validation?.voicePolicyError ||
                                        'Field is required')}
                            </FormErrorMessage>
                        </FormControl>
                    )}
                </>
            )}
        </Stack>
    );
}
