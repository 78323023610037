import { useState, useEffect, useRef, useMemo } from 'react';
import { MdFileCopy } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { loadingColumnsConstructor } from '@/components/tables/ReduxTable';
import { EditRounded } from '@material-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import MaterialTable, { RefreshIcon } from '@/components/v4/MaterialTable';
import useGetPermissionsSet from './queries/useGetPermissionsSet';
import useTableToolbar from 'hooks/useTableToolbar';
import CopyPermissionsSetModal from './CopyPermissionsSetModal';
import walkthroughIds from './walkthroughIds';
import { useNavigate } from 'react-router-dom';
import useCopyPermissionsSet from './queries/useCopyPermissionsSet';
import { canEditPermission } from './utils';

const Permissions = () => {
    const tableRef = useRef();
    const navigate = useNavigate();
    const [selectedPermissionsSet, setSelectedPermissionsSet] = useState(null);
    const [copyName, setCopyName] = useState(
        `Copy of ${selectedPermissionsSet?.name}`,
    );
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = useRef(null);

    const editAdminNavigate = (data) => {
        navigate(`permissions/${data.id}/edit`, { state: data });
    };

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { requiredScope: accountScope, currentScope } = useSelector(
        (state) => {
            return { ...state.login };
        },
    );

    const handleOpenCopyModal = (data) => {
        setSelectedPermissionsSet(data);
        if (data) {
            onOpen();
        }
    };

    const defaultColumns = [
        {
            title: 'Company',
            field: 'companyName',
        },
        {
            title: 'Permission Set',
            field: 'name',
        },
        {
            title: 'Required Scope',
            field: 'requiredScopeName',
        },
        {
            title: 'Read Only Permissions',
            field: 'readOnly',
            hiddenByColumnsButton: true,
            hidden: true,
            type: 'boolean',
        },
        {
            title: 'Opt Out of New Permissions',
            field: 'optOutOfNew',
            hiddenByColumnsButton: true,
            hidden: true,
            type: 'boolean',
        },
    ];

    const [tableData, setTableData] = useState({
        columns: defaultColumns,
        data: [],
    });

    const { ...ufPermissionPage } = walkthroughIds.permissions;

    const { isLoading, refetch, isRefetching, isFetching } =
        useGetPermissionsSet(currentScope, currentCompany, setTableData);

    const { refreshFn } = useTableToolbar({
        refetch,
    });

    const { mutate: copyPermissionsSet, isLoading: isCopyingPermissionsSet } =
        useCopyPermissionsSet({
            permissionsSet: selectedPermissionsSet,
            currentCompany: currentCompany,
            navigate: navigate,
            copyName: copyName,
            accountScope: accountScope,
        });

    const fetchingData = isFetching || isRefetching;

    useEffect(() => {
        if (!fetchingData) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(tableData.columns),
        [tableData.columns],
    );

    const dummyTable = [];
    const dummyTableLength = 12;
    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    const componentLoadingState = isLoading || isRefetching;

    const renderedColumns = componentLoadingState
        ? loadingColumns
        : defaultColumns;
    const renderedTableData = componentLoadingState
        ? dummyTable
        : tableData.data;

    const tableActions = [
        {
            icon: RefreshIcon,
            tooltip: 'Refresh Admin Users',
            isFreeAction: true,
            onClick: () => {
                tableRef.current &&
                    tableRef.current.dataManager.columns.forEach((item) => {
                        tableRef.current.onFilterChange(item.tableData.id, '');
                    });
                refreshFn();
            },
            hidden: !refreshFn,
        },
        (rowData) => ({
            icon: () => (
                <MdFileCopy data-walkthroughid={ufPermissionPage.ufCopyBtn} />
            ),
            tooltip: 'Copy Permissions Set',
            position: 'row',
            disabled: fetchingData,
            onClick: () => {
                handleOpenCopyModal(rowData);
            },
        }),
        (rowData) => ({
            icon: () => (
                <EditRounded
                    fontSize="small"
                    data-walkthroughid={`${walkthroughIds.ufTableEditBtn}/${rowData?.firstName}`}
                />
            ),

            hidden: !canEditPermission(rowData, accountScope, currentCompany),
            tooltip: 'Edit Permissions Set',
            position: 'row',
            disabled: fetchingData,
            onClick: () => {
                editAdminNavigate(rowData);
            },
        }),
    ];

    const header = 'Permissions Sets';

    return (
        <>
            <MaterialTable
                tableRef={tableRef}
                data={renderedTableData}
                columns={renderedColumns}
                actions={tableActions}
                titleName={header}
                title={''}
                options={{
                    search: true,
                    pageSize: 50,
                    pageSizeOptions: [50, 100, 250, 500],
                }}
                refreshTable={refreshFn}
            />
            <CopyPermissionsSetModal
                isOpen={isOpen}
                onClose={onClose}
                data={selectedPermissionsSet}
                finalRef={finalRef}
                copyOnClick={copyPermissionsSet}
                copyName={copyName}
                setCopyName={setCopyName}
                isCopyingPermissionsSet={isCopyingPermissionsSet}
            />
        </>
    );
};

export default Permissions;
