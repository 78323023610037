/* eslint-disable react-refresh/only-export-components */
import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../store/store';

import Table, { loadingColumnsConstructor } from './ReduxTable';
import { axios } from '../../services/axios';
import { Error, EditForm, Modal } from '..';

// A search function exported and used by only a few tables in the app
export const floatSearch = (v, rowData, field) => {
    return Math.abs(parseFloat(v) - rowData[field]) < Number.EPSILON;
};

// a custom function to create the title of the modal when clicking on a table entry
export const modalTitle = (data, attribute, section, mode) => {
    if (data !== null && data[attribute]) {
        return mode + section + ' : ' + data[attribute];
    }
    return mode + section;
};

// A function to dispatch the error in the modal to the state
export const onModalError = (e) => {
    store.dispatch({
        type: 'CHANGE_MODAL_BACKEND_ERROR',
        payload: e,
    });
};

/*
 * Table And Modal Function that takes in a series of props
 * This component is reponsible for fetching table data and displaying it as listed by the ReduxTable.jsx
 * This compoent is responsible for passing through data that is fetched to the modal so when a user clicks on a entry ->
 * The entry opens in a modal with all the required data that the entry has in the database
 * ReduxTable and ReduxModal are the two components that this uses to render those pages
 */
export default function TableAndModal(props) {
    const {
        // defaultColumns is used to set the columns for the table
        defaultColumns,
        // tableFetch is the url for the api to perform  a get request to
        tableFetch,
        // onTableFetch is triggered when the fetch is performed
        onTableFetch,
        // onModalSucceed is used to trigger somethin when the modal request(whatever it is) returns a 200 - ie redirect to edit after add is successfull
        onModalSucceed,
        // overrideModalAdd is used to override the method for the add request of the modal
        overrideModalAdd,
        // overrideModalUpdate is used to override the method for the edit reuqest of the modal
        overrideModalUpdate,
        // onModalCopy is something that triggers after the 'Copy' functionality returns successfully
        onModalCopy,
        // overrideTableLoading overrides the loading method for the table
        overrideTableLoading,
        // modalFetch is the URL for the get request when you click the edit button on any of the tables
        modalFetch,
        // I am not sure what this does but it seems to take a series of 3 different data sets
        modalUpload,
        // onModalUpdate is a function that is triggered when the modalUpdate is sucessful
        onModalUpdate,
        // overrideOnModalError is a way to override the default error handling process of this component
        onModalError: overrideOnModalError,
        // modalAdd is the URL for the add option of a modal
        modalAdd,
        // modalConvert is the URL for the convert option of a modal
        modalConvert,
        // modalDelete is the URL for the delete option for a modal
        modalDelete,
        // modalUpdate is the URL for the put request when updating any entity that you have opened in a modal
        modalUpdate,
        // modalApproval is the URL for any reuqests made to approve a company using a modal
        modalApproval,
        // modalCopy is the URL for any requests made to modals using the copy mode
        modalCopy,
        // onModalAdd is a function that happens after a post request is sucessful, like redirecting to the edit page once a trunk is created
        onModalAdd,
        // the validation logic for a modal, it is presented as a combined error state for whatever is listed. Easier to understand looking a trunks.jsx
        modalValidate,
        // determines if the edit icon is shown in the table, bool
        editable,
        // I do not know what this does and have never seen it
        onModalChangeLogo,
        // If you want to pass static data to the table instead of fetching data this prop is used for that
        data,
        // determines if the modal will be a full page or if it wil be a pop up -> true is full page
        newPage,
        // Determines whether there is a alphabet based filter field on the Table component
        alphabetFilterField,
        // a way to override table rows actions for the Table - If you would like to have anything specific that isnt a option below
        rowActions: tableRowActions,
        // onTableSucceed is a method that is called once the table has successfully loaded
        onTableSucceed,
        // Set to true if implementing custom actions column
        noActionsColumn,
    } = props;

    // tableloading and error state
    const { loading: tableLoading, error } = useSelector((state) => {
        return { ...state.table };
    });

    // State of the modal destructured into many variables
    // TODO: Figure out what modalFreshData is, most likely something to do with the state hashing to stop leaving a page with unsaved changes
    const {
        loading: modalLoading,
        state: modalState,
        mode: modalMode,
        uploading: modalUploading,
        freshData: modalFreshData,
        show: modalShow,
        data: modalData,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const dispatch = useDispatch();

    /*
     * Initial Table loading and fetching logic
     * if data and overrideTableLoading are not specificied AND tableLoading ->
     * Either make a fetch request of onTableFetch or axios.get with the tableFetch url passed in from props
     */
    useEffect(() => {
        if (!(data || overrideTableLoading) && tableLoading) {
            const fetchRequest = onTableFetch
                ? onTableFetch()
                : axios.get(tableFetch);
            fetchRequest
                .then((res) => {
                    dispatch({
                        type: 'CHANGE_TABLE_STATE',
                        payload: {
                            //transform data when data is fetched using default columns and onTableSuceed prop if its passsed in
                            columns: defaultColumns,
                            data: onTableSucceed
                                ? onTableSucceed(res.data)
                                : res.data,
                        },
                    });
                    //custom sort by alphabet functionality only used once
                    if (alphabetFilterField) {
                        dispatch({ type: 'CHANGE_TABLE_INDEXING' });
                    }
                    dispatch({ type: 'CHANGE_TABLE_LOADING' });
                })
                .catch((e) => {
                    if (e.response?.status !== 401) {
                        dispatch({
                            type: 'CHANGE_TABLE_ERROR',
                            payload: e,
                        });
                    } else {
                        dispatch({ type: 'CHANGE_TABLE_LOADING' });
                    }
                });
        }
    }, [tableLoading]);

    /*
     * Loading of modal data if modalShow, modalLoading and modalFetch exist
     * modalFetch is used for axios request, this result is then dispatched to the modal State
     * loading and hash are then dispatched, else no modal is loaded
     */
    useEffect(() => {
        if (modalShow && modalLoading && modalFetch) {
            axios
                .get(modalFetch)
                .then((res) => {
                    dispatch({
                        type: 'CHANGE_MODAL_STATE',
                        payload: {
                            ...res.data,
                        },
                    });
                    dispatch({ type: 'CHANGE_MODAL_LOADING', payload: false });
                    dispatch({
                        type: 'CHANGE_MODAL_HASH',
                    });
                })
                .catch((e) => {
                    onModalError(e);
                });
        } else if (modalShow && modalLoading && !modalFetch) {
            dispatch({ type: 'CHANGE_MODAL_LOADING', payload: false });
            dispatch({
                type: 'CHANGE_MODAL_HASH',
            });
        }
    }, [modalLoading, modalShow]);

    /*
     * A function to determine what tableRow actions to show based on either its a Add or Edit modal
     * tableRowActions are determined and based on some factors I don't understand a breadcrumb view is added
     * Breadcrumb view shows where the modal came from and some information around it in the title
     */
    const onSucceed = (res) => {
        if (modalMode === 'Add') {
            if (tableRowActions?.multiChildTable) {
                dispatch({
                    type: 'OPEN_CHILD_TABLE',
                    payload: { ...res.data, append: true },
                });
                dispatch({
                    type: 'CHANGE_BREADCRUMB_VIEW',
                    payload: tableRowActions?.multiChildTable?.to ?? 0,
                });
                dispatch({
                    type: 'RESET_MODAL',
                });
                return;
            }
            if (
                tableRowActions?.childTable &&
                !tableRowActions?.childTable?.doNotOverrideOnModalSucceed
            ) {
                dispatch({
                    type: 'OPEN_CHILD_TABLE',
                    payload: { ...res.data, append: true },
                });
                dispatch({
                    type: 'RESET_MODAL',
                });
                return;
            }
        }
        //this is how the table is loaded by default
        dispatch({
            type: 'ON_MODAL_SUBMIT_SUCCEED',
        });
        // change the table loading state
        dispatch({
            type: 'CHANGE_TABLE_LOADING',
        });
        // load the colums and data into the table state
        // dispatch({
        //     type: 'CHANGE_TABLE_STATE',
        //     payload: {columns: loadingColumns, data: loadingData},
        // });
    };

    /*
     * This fucntion determines what data you want to submit
     * And it also determins how you want to submit it
     */
    useEffect(() => {
        // whenever the modal is uploading, this effect checks what type the mode is and acts accordingly
        if (modalUploading) {
            var axiosResponse = null;
            switch (modalMode) {
                // change logo case: if validation happens and its false, return a error.
                // Otherwise use the provided axios endpoint
                case 'Change Logo':
                    if (
                        modalValidate && // need to do validation
                        !modalValidate() //validation return false
                    ) {
                        onModalError({
                            name: 'Field Error',
                            message: 'There are some field errors.',
                        });
                        return;
                    }
                    axiosResponse = onModalChangeLogo();
                    break;
                // delete case: return a delte response with the modalDelete url
                // not sure what the modalFreshData stuff means.
                case 'Delete':
                    axiosResponse = axios.delete(
                        modalDelete,
                        modalUpload(modalFreshData || modalData, modalState),
                        modalUpload(modalFreshData || modalData, modalState)
                            .config,
                    );

                    break;
                // add case: if validation happens and it false, dispatch error.
                // else return either modalAdd url, overrideModalAdd or onModalAdd
                case 'Add':
                    if (
                        modalValidate && // need to do validation
                        !modalValidate() //validation return false
                    ) {
                        onModalError({
                            name: 'Field Error',
                            message: 'There are some field errors.',
                        });
                        return;
                    }

                    if (modalAdd) {
                        axiosResponse = axios.post(
                            modalAdd,
                            modalUpload(
                                modalFreshData || modalData,
                                modalState,
                            ),
                        );
                    } else if (onModalAdd) {
                        axiosResponse = onModalAdd();
                    } else {
                        overrideModalAdd();
                        return;
                    }
                    break;
                // approval case: return the modalApproval URl
                case 'Approval':
                    axiosResponse = axios.put(
                        modalApproval,
                        modalUpload(modalFreshData || modalData, modalState),
                    );
                    break;
                // convert case: return the modalConvert URL
                case 'Convert':
                    axiosResponse = axios.post(
                        modalConvert,
                        modalUpload(modalFreshData || modalData),
                    );
                    break;
                // edit case: if validation is requred and is false, dispatch a error
                // else  return modalUpdate, onModalUpdate or overrideModalUpdate based on props
                case 'Edit':
                    if (
                        modalValidate && // need to do validation
                        !modalValidate() //validation return false
                    ) {
                        onModalError({
                            name: 'Field Error',
                            message: 'There are some field errors.',
                        });
                        return;
                    }
                    if (modalUpdate) {
                        axiosResponse = axios.put(
                            modalUpdate,
                            modalUpload(
                                modalFreshData || modalData,
                                modalState,
                            ),
                            modalUpload(modalFreshData || modalData, modalState)
                                ?.config,
                        );
                    } else if (onModalUpdate) {
                        axiosResponse = onModalUpdate();
                    } else {
                        overrideModalUpdate();
                        return;
                    }
                    break;
                // copy case: if validation is required and its false then dispatch a error
                // else if return onModalCopy, else post with modalCopy
                case 'Copy':
                    if (
                        modalValidate && // need to do validation
                        !modalValidate() //validation return false
                    ) {
                        onModalError({
                            name: 'Field Error',
                            message: 'There are some field errors.',
                        });
                        return;
                    }
                    if (onModalCopy) {
                        onModalCopy();
                        return;
                    }

                    // modal Copy Logic
                    // not really sure what this is doing or why its neccessary for the app as redux actions are confusing
                    axios
                        .post(modalCopy)
                        .then((copyResult) => {
                            axios
                                .put(modalUpdate, {
                                    ...copyResult.data,
                                    name: modalState?.copyName,
                                })
                                .then((renameResult) => {
                                    dispatch({
                                        type: 'OPEN_CHILD_TABLE',
                                        payload: {
                                            ...(renameResult?.data ?? {}),
                                            append: true,
                                        },
                                    });
                                    dispatch({
                                        type: 'CHANGE_BREADCRUMB_VIEW',
                                        payload:
                                            tableRowActions?.multiChildTable
                                                ?.to ?? 0,
                                    });
                                    return;
                                })
                                .catch((e2) => {
                                    dispatch({
                                        type: 'OPEN_CHILD_TABLE',
                                        payload: {
                                            ...(copyResult?.data ?? {}),
                                            append: true,
                                        },
                                    });
                                    dispatch({
                                        type: 'CHANGE_BREADCRUMB_VIEW',
                                        payload:
                                            tableRowActions?.multiChildTable
                                                ?.to ?? 0,
                                    });
                                    onModalError(e2);
                                });
                        })
                        .catch((e) => {
                            onModalError(e);
                        });
                    return;
                default:
                    break;
            }

            /*
             * if there is a axios respoonse after all the above conditions, this executes it
             * the hash is changed using a dispatch
             * if onModalSuceed is  inpt then it is called, else onSucceed is called
             * This block also has error handling
             */
            if (axiosResponse) {
                axiosResponse
                    .then((res) => {
                        dispatch({
                            type: 'CHANGE_MODAL_HASH',
                        });
                        if (onModalSucceed) {
                            onModalSucceed(res);
                            return;
                        }
                        onSucceed(res);
                    })
                    .catch((e) => {
                        if (overrideOnModalError) {
                            overrideOnModalError(e);
                            return;
                        }
                        onModalError(e);
                    });
            }
        }
    }, [modalUploading]);

    //Memo that changes the columns the app renders when default columns changes
    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(defaultColumns),
        [defaultColumns],
    );

    /*
     * The way the component renders all the data
     * If there is a error then the error compoent is returned
     * If the mode is either edit or add then the EditForm componet is rendered
     * else the Table and Modal is rendered
     */
    if (error) {
        return (
            <>
                <Error error={error} />
            </>
        );
    }

    if (modalShow && newPage && (modalMode === 'Edit' || modalMode === 'Add')) {
        return <EditForm {...props} />;
    }

    return (
        <>
            <Table
                {...props}
                loadingColumns={loadingColumns}
                remoteData={data}
                editable={editable}
                noActionsColumn={noActionsColumn}
            />
            <Modal {...props} />
        </>
    );
}
