import { axios } from '@/services/axios';
import ScopeType from 'utils/enums/ScopeType';

const getPermissionsSet = async ({ currentScope, currentCompany }) => {
    const res =
        currentScope == ScopeType.GLOBAL
            ? await axios.get(`/permissionsets/${currentScope}`)
            : await axios.get(
                  `/permissionsets/${currentScope}/${currentCompany}`,
              );
    return res.data;
};

export default getPermissionsSet;
