import { Box, Stack, StackDivider } from '@chakra-ui/react';

import Input from '../../../components/v4/Input';
import Text from '../../../components/v4/Text';
import FormLabel from '../../../components/v4/FormLabel';

import walkthroughIds from '../walkthroughIds';
import { Select } from 'chakra-react-select';
import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { useSelector } from 'react-redux';

function AddressField({
    placeholder,
    fieldName,
    fieldValue,
    fieldHandler,
    fieldStyles = {},
    ...props
}) {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <Box sx={{ margin: '0 0 25px' }}>
            <FormLabel sx={{ margin: '0 0 3px', fontSize: 'sm' }}>
                {placeholder}
            </FormLabel>
            <Input
                {...props}
                sx={{
                    color: darkMode ? 'white' : '#000000de',
                    borderColor: darkMode
                        ? 'dark.tcap-borderGray'
                        : '#0000003b',
                    // borderRadius: '4px',
                    maxWidth: 210,
                    width: '100%',
                    ...fieldStyles,
                }}
                value={fieldValue}
                onChange={(e) => fieldHandler(fieldName, e.target.value)}
                data-walkthroughid={props.dataWalkthroughId}
            />
        </Box>
    );
}

export default function AddressFields({ fields, fieldHandler, countries }) {
    const { addresses } = walkthroughIds;
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
      const companyName = useSelector(
            (state) => state.navigationLists.currentCompanyData?.name,
        );
    const chakraStyles = {
        outlineColor: 'gray.300',
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
        ...{
            control: (provided, { selectProps }) => ({
                ...provided,
                borderColor: darkMode ? 'dark.tcap-borderGray' : '#0000003b',
            }),
        },
    };

    console.log('chakra select dark: ', chakraSelectDark);

    return (
        <Stack divider={<StackDivider />}>
            {/* Description */}
            <Box>
                <Text
                    fontSize="lg"
                    sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    Company Name
                </Text>
                <AddressField
                        //placeholder="Building Type"
                        fieldName="companyName"
                        fieldValue={fields.companyName || companyName }
                        fieldHandler={fieldHandler}
                        fieldStyles={{
                            width: '350px',
                            maxWidth: '350px',
                        }}
                        dataWalkthroughId={addresses.companyName}
                    />
                <Text
                    fontSize="lg"
                    sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    Description
                </Text>
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        //placeholder="Building Type"
                        fieldName="description"
                        fieldValue={fields.description}
                        fieldHandler={fieldHandler}
                        fieldStyles={{
                            width: '350px',
                            maxWidth: '350px',
                        }}
                        dataWalkthroughId={addresses.ufDescriptionInput}
                    />
                </Box>
            </Box>
            {/* Building: Row 1 */}
            <Box as="section">
                <Text
                    fontSize="lg"
                    sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    Building
                </Text>
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="Building Type"
                        fieldName="buildingType"
                        fieldValue={fields.buildingType}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.ufBuildingTypeInput}
                    />
                    <AddressField
                        placeholder="Building 1st Number"
                        fieldName="building1stNumber"
                        fieldValue={fields.building1stNumber}
                        fieldHandler={fieldHandler}
                        type="number"
                        dataWalkthroughId={`${addresses.ufBuildingNumberInput}/1`}
                    />
                    <AddressField
                        placeholder="Building 1st Suffix"
                        fieldName="building1stSuffix"
                        fieldValue={fields.building1stSuffix}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufBuildingSuffixInput}/1`}
                    />
                    <AddressField
                        placeholder="Building 2nd Number"
                        fieldName="building2ndNumber"
                        fieldValue={fields.building2ndNumber}
                        fieldHandler={fieldHandler}
                        type="number"
                        dataWalkthroughId={`${addresses.ufBuildingNumberInput}/2`}
                    />
                    <AddressField
                        placeholder="Building 2nd Suffix"
                        fieldName="building2ndSuffix"
                        fieldValue={fields.building2ndSuffix}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufBuildingSuffixInput}/2`}
                    />
                </Box>
                {/* Building: Row 2 */}
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="Building Floor Type"
                        fieldName="buildingFloorType"
                        fieldValue={fields.buildingFloorType}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.ufBuildingFloorTypeInput}
                    />
                    <AddressField
                        placeholder="Building Floor Number"
                        fieldName="buildingFloorNumber"
                        fieldValue={fields.buildingFloorNumber}
                        fieldHandler={fieldHandler}
                        type="number"
                        dataWalkthroughId={addresses.ufBuildingFloorNumInput}
                    />
                    <AddressField
                        placeholder="Building Floor Number Suffix"
                        fieldName="buildingFloorNumberSuffix"
                        fieldValue={fields.buildingFloorNumberSuffix}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={
                            addresses.ufBuildingFloorNumSuffixInput
                        }
                    />
                </Box>

                {/* Building: Row 3 */}
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="Building Property"
                        fieldName="buildingProperty"
                        fieldValue={fields.buildingProperty}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.ufBuildingPropertyInput}
                    />
                    <AddressField
                        placeholder="Building Location"
                        fieldName="buildingLocation"
                        fieldValue={fields.buildingLocation}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.ufBuildingLocationInput}
                    />
                </Box>
            </Box>

            {/* Street: Row 1 */}
            <Box as="section">
                <Text fontSize="lg" sx={{ fontWeight: 'bold', margin: 0 }}>
                    Street
                </Text>
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="Street House Number 1"
                        fieldName="streetHouseNumber1"
                        fieldValue={fields.streetHouseNumber1}
                        fieldHandler={fieldHandler}
                        type="number"
                        dataWalkthroughId={`${addresses.ufStreetHouseNumInput}/1`}
                    />
                    <AddressField
                        placeholder="Street House Number 1 Suffix"
                        fieldName="streetHouseNumber1Suffix"
                        fieldValue={fields.streetHouseNumber1Suffix}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufStreetHouseNumSuffixInput}/1`}
                    />
                    <AddressField
                        placeholder="Street House Number 2"
                        fieldName="streetHouseNumber2"
                        fieldValue={fields.streetHouseNumber2}
                        fieldHandler={fieldHandler}
                        type="number"
                        dataWalkthroughId={`${addresses.ufStreetHouseNumInput}/2`}
                    />
                    <AddressField
                        placeholder="Street House Number 2 Suffix"
                        fieldName="streetHouseNumber2Suffix"
                        fieldValue={fields.streetHouseNumber2Suffix}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufStreetHouseNumSuffixInput}/2`}
                    />
                </Box>

                {/* Street: Row 2 */}
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="Street Name 1"
                        fieldName="streetName1"
                        fieldValue={fields.streetName1}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufStreetNameInput}/1`}
                    />
                    <AddressField
                        placeholder="Street Type 1"
                        fieldName="streetType1"
                        fieldValue={fields.streetType1}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufStreetTypeInput}/1`}
                    />
                    <AddressField
                        placeholder="Street Suffix 1"
                        fieldName="streetSuffix1"
                        fieldValue={fields.streetSuffix1}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufStreetSuffixInput}/1`}
                    />
                </Box>

                {/* Street: Row 3 */}
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="Street Name 2"
                        fieldName="streetName2"
                        fieldValue={fields.streetName2}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufStreetNameInput}/2`}
                    />
                    <AddressField
                        placeholder="Street Type 2"
                        fieldName="streetType2"
                        fieldValue={fields.streetType2}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufStreetTypeInput}/2`}
                    />
                    <AddressField
                        placeholder="Street Suffix 2"
                        fieldName="streetSuffix2"
                        fieldValue={fields.streetSuffix2}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={`${addresses.ufStreetSuffixInput}/2`}
                    />
                </Box>

                {/* Street: Row 4 */}
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="City / Locality"
                        fieldName="locality"
                        fieldValue={fields.locality}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.ufCityLocalityInput}
                    />
                    <AddressField
                        placeholder="District"
                        fieldName="district"
                        fieldValue={fields.district}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.ufDistrictInput}
                    />
                </Box>

                {/* Street: Row 5 */}
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="State"
                        fieldName="state"
                        fieldValue={fields.state}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.ufStateInput}
                    />
                    <AddressField
                        placeholder="ZIP / Post Code"
                        fieldName="postcode"
                        fieldValue={fields.postcode}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.ufZipCodeInput}
                    />
                </Box>
            </Box>
            <Box as="section">
                <Text
                    fontSize="lg"
                    sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    MICROSOFT LOCATIONS
                </Text>
                <Box sx={{ display: 'flex' }} className="gap-6">
                    <AddressField
                        placeholder="Location ID"
                        fieldName="locationID"
                        fieldValue={fields.locationID}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.locationID}
                    />
                    <AddressField
                        placeholder="Civic address ID"
                        fieldName="civicAddressID"
                        fieldValue={fields.civicAddressID}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.civicAddressID}
                    />
                    <AddressField
                        placeholder="Latitude"
                        fieldName="latitude"
                        fieldValue={fields.latitude}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.latitude}
                    />
                    <AddressField
                        placeholder="Longitude"
                        fieldName="longitude"
                        fieldValue={fields.longitude}
                        fieldHandler={fieldHandler}
                        dataWalkthroughId={addresses.longitude}
                    />
                </Box>
            </Box>
            <Box as="section">
                {/* Country */}
                <Text
                    fontSize="lg"
                    sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    Country
                </Text>
                <Box maxW={300} mb={25}>
                    <Select
                        placeholder="Select Country"
                        tagVariant="solid"
                        onChange={(selectOpt) => {
                            fieldHandler('countryID', selectOpt.id);
                        }}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                        options={countries.sort((a, b) =>
                            a.name.localeCompare(b.name),
                        )}
                        isLoading={countries.length == 0}
                        value={countries.find((a) => a.id == fields.countryID)}
                        chakraStyles={chakraStyles}
                        dataWalkthroughId={addresses.ufCountryInput}
                        selectedOptionColor="brand"
                    />
                </Box>

                {/* Google Places */}
                <Text
                    fontSize="lg"
                    sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    Google Place ID
                </Text>

                <Box sx={{ margin: '0 0 25px' }}>
                    <Input
                        sx={{
                            color: darkMode ? 'white' : '#000000de',
                            borderColor: darkMode
                                ? 'dark.tcap-borderGray'
                                : '#0000003b',
                            borderRadius: '4px',
                            maxWidth: 210,
                            width: '100%',
                            textOverflow: 'ellipsis',
                        }}
                        disabled
                        value={fields.googlePlaceID}
                    />
                </Box>
            </Box>
        </Stack>
    );
}
