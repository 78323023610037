import { useRef } from 'react';
import { toast } from 'react-toastify';

export function useSingleToast() {
    const toastId = useRef(null);

    const successToast = (message, options = {}) => {
        // Dismiss the previous toast before showing a new one
        if (toastId.current) {
            toast.dismiss(toastId.current);
        }

        toastId.current = toast.success(message, {
            autoClose: 5000,
            ...options,
        });
    };

    const errorToast = (message, options = {}) => {
        // Dismiss the previous toast before showing a new one
        if (toastId.current) {
            toast.dismiss(toastId.current);
        }

        toastId.current = toast.error(message, {
            autoClose: 5000,
            ...options,
        });
    };

    return { successToast, errorToast };
}
