import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { forwardRef, Tooltip } from '@chakra-ui/react';
import { classNames as cx } from 'utils/classNames';
import { useSelector } from 'react-redux';

/**
 * @typedef {Object} TooltipOptions
 * @property {boolean} show (required) show tooltip icon when hovering to the parent element
 * @property {string} [tooltipClass] class name for tooltip
 * @property {string} [iconClass] class name for icon
 */

/**
 * @typedef {import('@chakra-ui/react').TooltipProps & TooltipOptions} CustomTooltipProps
 * @type React.ForwardRefRenderFunction<HTMLDivElement, CustomTooltipProps>
 * @description Custom Tooltip component that shows when hover to the parent element
 * @description Parent component requires to have onMouseEnter and onMouseLeave (or pointer if disabled) events
 * @returns {JSX.Element}
 */
export const AltTooltip = forwardRef((props, ref) => {
    const { darkMode } = useSelector((state) => state.settings);
    const { label, show, tooltipClass, iconClass } = props;
    return (
        <Tooltip
            ref={ref}
            hasArrow
            color={darkMode ? '#848484' : null}
            className={cx(darkMode ? 'text-white' : null, tooltipClass)}
            label={label}
            {...props}>
            <QuestionOutlineIcon
                display={show ? 'block' : 'none'}
                color={darkMode ? 'white' : 'black'}
                size="small"
                className={cx('cursor-help', iconClass)}
            />
        </Tooltip>
    );
});
