import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';
import Grid from 'components/v4/Grid';
import Alert from 'components/v4/Alert';
import AlertIcon from 'components/v4/AlertIcon';

import SkeletonPlaceholder from 'components/v4/Cards/ServiceProfileCardPlaceholder';

import SbcService from './SbcService';
import OcService from './OcService';
import DrService from './DrService';
import TpmService from './TpmService';

import OccService from './OccService';

import path from '@/routes/constants';
import { useNavigateLocation } from 'hooks/useNavigateLocation/useNavigateLocation';

const walkthroughIdPrefix = '/dashboard-services/new-service';

import AcsService from './AcsService';
import VendorDefinedService from './VendorDefinedService';
import ZoomService from './ZoomService';

export default function AvailableServiceProfiles({
    serviceProfiles,
    serviceWizard,
}) {
    const { handleNavigateLocation } = useNavigateLocation();
    const { requiredScope } = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const { colorScheme } = useSelector((state) => state.settings);

    /**
     * Loading available service profiles.
     */
    if (serviceProfiles === null || serviceProfiles === undefined) {
        return (
            <SkeletonPlaceholder
                style={{ maxWidth: '1064px' }}
                skeletons={13}
                rows={1}
                columns={4}
                gap={4}
                items={4}
            />
        );
    }

    /**
     * Loading services.
     */
    if (!serviceProfiles?.length) {
        return (
            <Alert
                status="info"
                sx={{ marginBottom: '30px', whiteSpace: 'pre-line' }}>
                <AlertIcon />
                {
                    'Your partner does not have any additional services which can be added.'
                }{' '}
                &nbsp;
                {requiredScope > 40 && (
                    <Button
                        size="xs"
                        colorScheme="blue"
                        onClick={() => {
                            handleNavigateLocation(`/${path.SERVICE_PROFILES}`);
                            dispatch({
                                type: 'CHANGE_MINIMUM_SCOPE',
                                payload: 40,
                            });
                        }}>
                        Go to service profiles
                    </Button>
                )}
            </Alert>
        );
    }

    /**
     * Available service profiles.
     */
    return (
        <>
            {serviceWizard.hasCreateServiceError &&
                serviceWizard.isCreatingService && (
                    <Alert
                        status="error"
                        sx={{ marginBottom: '30px', whiteSpace: 'pre-line' }}>
                        <AlertIcon />
                        {serviceWizard.hasCreateServiceError} &nbsp; &nbsp;
                        <Button
                            size="xs"
                            variant="outline"
                            colorScheme={colorScheme}
                            className={'secondary bg-white hover:opacity-70'}
                            onClick={() => {
                                serviceWizard.setIsCreatingService(false);
                                dispatch({
                                    type: 'CHANGE_MODAL_BACKEND_ERROR',
                                    payload: null,
                                });
                            }}
                            data-walkthroughid={`${walkthroughIdPrefix}/button/cancel`}>
                            Cancel
                        </Button>
                    </Alert>
                )}
            <Grid columns={4} gap={8} style={{ maxWidth: '1064px' }}>
                {serviceProfiles.map((serviceProfile) => {
                    switch (serviceProfile.serviceType) {
                        case 'ACSDirectRouting':
                            return (
                                <AcsService
                                    serviceWizard={serviceWizard}
                                    id="ACS Direct Routing"
                                />
                            );
                        case 'SBCaaS':
                            return (
                                <SbcService
                                    serviceProfile={serviceProfile}
                                    serviceWizard={serviceWizard}
                                    id="SBCaaS"
                                />
                            );
                        case 'OperatorConnect':
                            return (
                                <OcService
                                    serviceProfile={serviceProfile}
                                    serviceWizard={serviceWizard}
                                    id="Operator Connect"
                                />
                            );
                        case 'DirectRouting':
                            return (
                                <DrService
                                    serviceProfile={serviceProfile}
                                    serviceWizard={serviceWizard}
                                    id="Direct Routing"
                                />
                            );
                        case 'TeamsPhoneMobile':
                            return (
                                <TpmService
                                    serviceWizard={serviceWizard}
                                    serviceProfile={serviceProfile}
                                    id="Teams Phone Mobile"
                                />
                            );
                        case 'OperatorConnectConferencing':
                            return (
                                <OccService
                                    serviceProfile={serviceProfile}
                                    serviceWizard={serviceWizard}
                                    id="Operator Connect Conferencing"
                                />
                            );
                        case 'VendorDefined':
                            return (
                                <VendorDefinedService
                                    serviceProfile={serviceProfile}
                                    serviceWizard={serviceWizard}
                                    id="Vendor Defined"
                                />
                            );
                        case 'ZoomPEX':
                            return (
                                <ZoomService
                                    serviceProfile={serviceProfile}
                                    serviceWizard={serviceWizard}
                                    id="Zoom PEX"
                                />
                            );
                    }
                })}
            </Grid>
        </>
    );
}
