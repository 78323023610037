export const _focus = '_focus';
export const _active = '_active';
export const _hover = '_hover';

export const colorMode = {
    [_focus]: {
        bg: 'whiteAlpha.300',
    },
    [_active]: {
        bg: 'whiteAlpha.300',
    },
    [_hover]: {
        bg: 'whiteAlpha.100',
    },
};
export const accordionItemStyle = {
    '.chakra-stack .chakra-text': {
        marginInlineStart: 0,
    },

    '&': {
        marginBottom: 1,
    },
    '& .chakra-stack :where(.chakra-button)': {
        margin: 0,
    },
};
