import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

function BreadCrumbHeader() {
    return (
        <Breadcrumb
            separator="/"
            fontWeight="medium"
            fontSize="sm"
            className="hover:no-underline">
            <BreadcrumbItem>
                <BreadcrumbLink
                    as={Link}
                    to="/visibility-groups"
                    _hover={{
                        textDecoration: 'none',
                    }}>
                    Visibility Groups
                </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink
                    as={Link}
                    _hover={{
                        textDecoration: 'none',
                    }}>
                    Manage Group
                </BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>
    );
}
export { BreadCrumbHeader };
