import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { axios } from '@/services/axios';
import { InfoIcon } from '@chakra-ui/icons';
import {
    Box,
    FormLabel,
    HStack,
    Skeleton,
    SkeletonCircle,
    Stack,
    Tooltip,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Select } from 'chakra-react-select';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Heading,
    Input,
    LoadingIndicator,
} from 'components/v4';
import FieldValidation from 'components/v4/FieldValidation';
import useFieldValidation from 'hooks/useFieldValidation';
import { isEmpty } from 'lodash';
import walkthroughIds from 'pages/services/walkthroughIds';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import FooterContainer from '../edit/FooterContainer';
import { validationSchemaVD } from '../edit/schema';
import style from '../edit/styles';
import { useDefinedAttributes } from './DefinedAttributesContext';
import DefinedAttributes from './attributes/definedAttributes';
import useDefinedAttributeValidation from './attributes/useDefinedAttributeValidation';
import config from '@/config.json';

export default function AddVendorDefined({
    data,
    handleBackButton,
    level,
    visibleScopeOptions,
}) {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const { darkMode } = useSelector((state) => state.settings);
    const { requiredScope } = useSelector((state) => state.login);

    const {
        currentWholesaler: wholesalerID,
        currentPartner: partnerID,
        wholesalers,
        companiesAndPartners,
    } = useSelector((state) => state.navigationLists);

    const { dispatch, attributesList, attributesErrors } =
        useDefinedAttributes();

    const DEFAULT_VENDOR_DEFINED_PAYLOAD = {
        name:
            level === 'partner'
                ? 'Vendor Defined Partner Profile'
                : 'Vendor Defined Wholesaler Profile',
        serviceType: 'VendorDefined',
        serviceDefinitionID: '',
        partnerID: level === 'partner' ? partnerID : undefined,
        wholesalerID: level === 'partner' ? undefined : wholesalerID,
        visibleScope: level === 'partner' ? 20 : 60,
    };

    const { ...ufElements } = walkthroughIds.serviceProfiles;
    const chakraSelectDarkStyle = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDarkStyle,
    };

    const [modalState, setModalState] = useState(
        DEFAULT_VENDOR_DEFINED_PAYLOAD,
    );

    const { validationErrors, validatefn } = useFieldValidation(
        modalState,
        validationSchemaVD,
    );

    const { handleAttributeError, removeAttributeError } =
        useDefinedAttributeValidation();

    // get the pre existing data from the backend to display the dynamic attributes
    const { isFetching: vendorDefinedLoading } = useQuery({
        // eg. VendorDefined | the service definition id
        queryKey: ['vendorDefined', data?.serviceType?.split('|')[1]],
        queryFn: async () => {
            const serviceDefinitionID = data?.serviceType?.split('|')[1];
            const res = await axios.get(
                `serviceprofiles/vendordefined/${serviceDefinitionID}/${scope()}`,
                {
                    headers: {
                        'X-RequestScope': requiredScope,
                    },
                },
            );
            return res.data;
        },
        enabled: Boolean(data?.serviceType?.split('|')[1]),
        onSuccess: (data) => {
            // data for the service type and attributes list
            setModalState((prevState) => ({
                ...prevState,
                data,
            }));
            if (data.attributeList?.length > 0) {
                for (let att of data.attributeList) {
                    // attribute value is empty
                    if (isEmpty(att.value)) {
                        // default value is empty, set value to be empty string
                        att.value = isEmpty(att.defaultValue)
                            ? null
                            : att.defaultValue;
                    }
                }
            }
            dispatch({
                type: 'UPDATE_ATT_LIST',
                payload: data.attributeList,
            });
        },
        onError: (err) => {
            toast.error(err?.response?.data?.message);
        },
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (level !== 'partner' && data && wholesalers?.length > 0) {
            const wsName = wholesalers.find(
                ({ id }) => id === wholesalerID,
            )?.name;
            handleChangeState({
                name: `${wsName} - ${data?.displayName} Wholesaler Profile`,
            });
        } else if (level === 'partner' && data && wholesalers?.length > 0) {
            const partnerName = companiesAndPartners.find(
                ({ id }) => id === partnerID,
            )?.name;
            handleChangeState({
                name: `${partnerName} - ${data?.displayName} Partner Profile`,
            });
        }
    }, [data, companiesAndPartners, wholesalers, level]);

    /*
     * A function to get the current scope and append it for API calls
     */
    const scope = () => {
        if (level === 'partner') {
            return `Partner/${partnerID}`;
        } else {
            return `Wholesaler/${wholesalerID}`;
        }
    };

    // function to set modal state with a given payload
    const handleChangeState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    const handleSubmit = async () => {
        setError(null);
        let hasError = false;

        if (!validatefn()) {
            setError({
                response: {
                    data: {
                        message:
                            'There are some validation errors in the form. Please check and try again.',
                    },
                },
            });
            return;
        }

        // check for any attributes errors
        if (attributesErrors.length > 0) {
            hasError = true;
        }

        // check for required attributes
        if (attributesList?.length > 0) {
            attributesList.forEach((att, index) => {
                const validation = JSON.parse(
                    att.definedAttributeValidationType,
                );

                // if required, input cannot be empty
                if (validation.Required && isEmpty(att.value)) {
                    handleAttributeError(index, 'Field is required.');
                    hasError = true;
                } else {
                    removeAttributeError(index);
                }
            });
        }
        if (hasError) return;

        setIsLoading(true);
        try {
            const serviceDefinitionID = data?.serviceType?.split('|')[1];
            // wholesaler creates a service profile for partner
            if (level === 'partner') {
                console.log('this is partner add vendor');
                await axios.post(
                    `serviceprofiles/vendordefined/${serviceDefinitionID}/partner`,
                    {
                        name: modalState.name,
                        partnerID: modalState.partnerID,
                        serviceDefinitionID: serviceDefinitionID,
                        visibleScope: modalState.visibleScope,
                        attributeList: attributesList,
                    },
                    {
                        headers: {
                            'X-RequestScope': requiredScope,
                        },
                    },
                );
            }
            // GA creates for wholesaler
            else {
                console.log('this is wholesaler add vendor');
                await axios.post(
                    `serviceprofiles/vendordefined/${serviceDefinitionID}/wholesaler`,
                    {
                        name: modalState.name,
                        wholesalerID: modalState.wholesalerID,
                        serviceDefinitionID: serviceDefinitionID,
                        visibleScope: modalState.visibleScope,
                        attributeList: attributesList,
                    },
                    {
                        headers: {
                            'X-RequestScope': requiredScope,
                        },
                    },
                );
            }
            toast.success(
                `Successfully created a ${
                    level === 'partner' ? 'Partner' : 'Wholesaler'
                } Vendor Defined Service Profile!`,
            );
            handleBackButton();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box sx={{ maxWidth: '2xl', minWidth: 'xl', margin: 'auto' }}>
            <Heading fontSize="3xl" as="h2" sx={{ marginBottom: '10px' }}>
                {`Add ${modalState.name}`}
            </Heading>

            {error && (
                <Alert status="error" sx={{ marginTop: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>Service name</FormLabel>
                <Input
                    value={modalState.name}
                    onChange={(e) =>
                        handleChangeState({ name: e.target.value })
                    }
                    sx={style.inputField}
                    data-walkthroughid={ufElements.ufServiceNameInput}
                />
                <FieldValidation errors={validationErrors?.name} />
            </Box>

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>
                    Visible Scope &nbsp;
                    <Tooltip
                        label="Determine visibility and service creation level"
                        hasArrow
                        placement="top-end">
                        <InfoIcon />
                    </Tooltip>
                </FormLabel>
                {modalState.visibleScope <= requiredScope ? (
                    <Select
                        defaultValue={visibleScopeOptions.find(
                            (option) =>
                                option.value === modalState.visibleScope,
                        )}
                        options={visibleScopeOptions}
                        chakraStyles={chakraStyles}
                        onChange={(selectedOption) => {
                            handleChangeState({
                                visibleScope: selectedOption.value,
                            });
                        }}
                        selectedOptionColor="brand"
                    />
                ) : (
                    <Input
                        isDisabled
                        value={config.scope.json[modalState.visibleScope]}
                    />
                )}
            </Box>

            {vendorDefinedLoading ? (
                <Box>
                    {[...Array(3)].map((_, index) => (
                        <Stack key={index} mb={3}>
                            <HStack>
                                <Skeleton h={5} w={'25%'} />
                                <SkeletonCircle h={5} w={5} />
                            </HStack>

                            <Skeleton h={10} borderRadius={4} />
                            <Skeleton h={5} w={'15%'} />
                        </Stack>
                    ))}
                </Box>
            ) : (
                <DefinedAttributes requiredScope={requiredScope} />
            )}

            <FooterContainer>
                <Button
                    variant="outline"
                    sx={{ background: 'white' }}
                    onClick={handleBackButton}
                    data-walkthroughid={ufElements.ufCloseButton}>
                    Close
                </Button>
                <Button
                    data-walkthroughid={ufElements.ufUpdateButton}
                    onClick={() => handleSubmit()}>
                    Add
                </Button>
            </FooterContainer>
        </Box>
    );
}
