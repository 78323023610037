import { Box } from '@chakra-ui/react';
import { Route, Routes, useLocation } from 'react-router-dom';

import {
    AssociatedAccounts,
    MfaTeamsPhoneMobileWizard,
    TpmContent,
    TpmTrialProcess,
    TpmWrapper,
} from './';

import { classNames } from 'utils/classNames';
import { path } from './constants';

function TeamsPhoneMobileTrial() {
    const { pathname } = useLocation();
    const { SMS_AUTHORIZATION, ASSOCIATED_ACCOUNTS } = path;
    const isAssociatedPage = pathname.includes(path.ASSOCIATED_ACCOUNTS);

    return (
        <TpmWrapper className="mt-[10px] flex flex-col">
            <TpmContent
                className={classNames(
                    'items-center justify-center',
                    isAssociatedPage ? 'h-full' : 'h-tpm',
                )}>
                <Box className="mx-auto my-0">
                    <Box
                        className={classNames(
                            'mx-auto my-0',
                            isAssociatedPage ? 'w-[896px]' : '',
                        )}>
                        <Routes>
                            <Route index element={<TpmTrialProcess />} />
                            <Route
                                path={SMS_AUTHORIZATION}
                                element={<MfaTeamsPhoneMobileWizard />}
                            />
                            <Route
                                path={ASSOCIATED_ACCOUNTS}
                                element={<AssociatedAccounts />}
                            />
                        </Routes>
                    </Box>
                </Box>
            </TpmContent>
        </TpmWrapper>
    );
}

export default TeamsPhoneMobileTrial;
