import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Spinner, Box } from '@chakra-ui/react';
import { ChevronDownIcon, QuestionOutlineIcon } from '@chakra-ui/icons';

import config from '../../config.json';
import ErrorBoundary from '../maintenance/ErrorBoundary';
import { customAutoCompleteStyle } from 'pages/navbar/constant';
import { InputAdornment, Tooltip } from '@material-ui/core';

export const findCurrentValue = ({
    options,
    displayField,
    dropDownValue,
    currentValue,
    noneFieldDisplay,
    onDisplayDropdown,
}) => {
    if (!options || !displayField || !dropDownValue) {
        return null;
    }
    var res = options?.find((v) => {
        return v[dropDownValue] === currentValue;
    });

    if (!res) {
        return noneFieldDisplay || config.notAllocated.dropdown;
    }
    return (
        (onDisplayDropdown && onDisplayDropdown(res)) ||
        res[displayField] ||
        res ||
        'ERROR:Not Found!!!!'
    );
};

// Specific for 'Company Managing' field on drop down
// Labels company and partner for group by function of autocomplete
export const relabel = (companies) => {
    var res = [];
    let parentName = '';
    let parentID = '';
    for (const company of companies) {
        if (company.parent == null || company.parent.length === 0) {
            parentName = company.name;
            parentID = company.id;
            res.push({
                id: company.id,
                name: company.name,
                parentID: '',
                parentName: '',
            });
        } else {
            res.push({
                id: company.id,
                name: company.name,
                parentID,
                parentName,
            });
        }
    }
    return res;
};

export const groupBy = (option) => {
    if (option.parentName.length === 0) {
        return option.name;
    }
    return option.parentName;
};

const getOptionSelectedID = (option, v) => {
    return option.id === v.id;
};

export const getOptionSelected = (option, value) => {
    if (typeof value === 'string') {
        return value === option.id;
    }
    return option.name === value.name;
};

export const getOptionLabel = (option) => option.name || '';

/*
 * SearchBox component used by multi selects, auto completes and drop downs that require search functionality
 */
export default function Asynchronous(props) {
    const { colorScheme } = useSelector((state) => state.settings);
    const [open, setOpen] = useState(false);
    const {
        placeholder,
        defaultValue,
        loading,
        onChange,
        message,
        onFocus,
        label,
        displayField,
        dropDownValue,
        onDisplayDropdown,
        options,
        noneFieldDisplay,
        helperText,
        errorText,
        groupByProps,
        endAdornment,
        renderOption,
        getOptionSelected: getOptionSelectedProps,
        getOptionLabel: getOptionLabelProps,
        dataWalkthroughid,
        isRequired = false,
        hasTooltip = false,
        tooltipLabel = 'Field is required.',
    } = props;

    const findCurrentName = () => {
        var res = options.find((v) => {
            return v.id === defaultValue;
        });
        var name = res?.name;
        if (res?.parentName) {
            name = res?.name + ' Under: ' + res?.parentName;
        }
        return name;
    };

    const [isHovered, setIsHovered] = useState(false);

    // For each option you pass in, it must not be null, hence the error boundary
    return (
        <ErrorBoundary>
            <Box sx={customAutoCompleteStyle(colorScheme)}>
                <Autocomplete
                    data-walkthroughid={dataWalkthroughid}
                    {...props}
                    open={open}
                    clearOnBlur={false}
                    defaultValue={defaultValue}
                    onFocus={onFocus}
                    value={defaultValue}
                    renderOption={renderOption}
                    placeholder={placeholder}
                    onChange={(e, value) => {
                        //ASSUMPTION :ONLY USED WHEN THERE'S GROUP BY?
                        if (value != null) {
                            //CHANGE!
                            const foundItem = options.filter(
                                (company) =>
                                    company?.[dropDownValue ?? 'id'] ===
                                    value?.[dropDownValue ?? 'id'],
                            );
                            onChange(foundItem[0]);
                        }
                    }}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    groupBy={groupByProps}
                    getOptionSelected={
                        getOptionSelectedProps || getOptionSelectedID
                    }
                    getOptionLabel={getOptionLabelProps || getOptionLabel}
                    options={options}
                    loading={loading}
                    disableClearable
                    renderInput={(params) => {
                        return (
                            <TextField
                                label={label}
                                {...params}
                                required={isRequired}
                                helperText={errorText || helperText}
                                error={Boolean(errorText)}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                placeholder={
                                    placeholder ||
                                    findCurrentValue({
                                        options,
                                        displayField,
                                        dropDownValue,
                                        currentValue: defaultValue,
                                        message,
                                        onDisplayDropdown,
                                        noneFieldDisplay,
                                    }) ||
                                    findCurrentName()
                                }
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <Spinner boxSize={4} />
                                            ) : null}
                                            {endAdornment}
                                            {params.InputProps.endAdornment}
                                            {isHovered && hasTooltip && (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={tooltipLabel}
                                                        placement="bottom"
                                                        arrow>
                                                        <QuestionOutlineIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </InputAdornment>
                                            )}
                                        </React.Fragment>
                                    ),
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        );
                    }}
                    popupIcon={<ChevronDownIcon boxSize={5} mt="2px" />}
                />
            </Box>
        </ErrorBoundary>
    );
}
