import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { axios } from '@/services/axios';
import { Box, Heading } from '@chakra-ui/react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const tabList = ['General'];

const EditZoomTrunk = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [tabView, setTabView] = useState(0);

    const [title, setTitle] = useState('');

    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { currentCompany } = useSelector((state) => ({
        ...state.navigationLists,
    }));

    const handleBackbutton = () => {
        navigate('/trunks');
        dispatch({
            type: 'RESET_MODAL',
        });
    };

    const handleTabChange = (e, value) => {
        setTabView(value);
    };

    const statusToString = (status) => {
        switch (status) {
            case 0:
                return 'OK';
            case 1:
                return 'Adding';
            case 3:
                return 'Pending Approval';
            case 4:
                return 'Updating';
            case 8:
                return 'Degraded';
            case 9:
                return 'Deleting';
            default:
                return 'Error';
        }
    };

    // loading trunk api call
    useEffect(() => {
        if (id) {
            axios
                .get(`/Trunk/${id}`)
                .then((res) => {
                    dispatch({
                        type: 'CHANGE_MODAL_STATE',
                        payload: {
                            ...res.data,
                        },
                    });
                    setTitle(
                        'Edit Trunk -> ' +
                            res.data.sipHeader +
                            ' -> Status: ' +
                            statusToString(res.data.status),
                    );
                    setIsLoading(false);
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                    setIsLoading(false);
                });
        }
    }, [id]);

    // editing a trunk api call
    const handleEditTrunk = () => {
        setIsLoading(true);
        axios
            .post(
                '/trunk/name',
                {
                    trunkId: state.id,
                    trunkName: state.name,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then(() => {
                navigate('/trunks');
                dispatch({
                    type: 'RESET_TABLE',
                });
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setIsLoading(false);
            });
    };

    // reset navigation on wholesaler/company change
    if (state?.companyID !== currentCompany) {
        navigate('/trunks');
    }

    return isLoading ? (
        <CircularProgress style={{ marginTop: '2.5%', marginLeft: '50%' }} />
    ) : (
        <>
            <Heading fontSize="3xl" as="h2" mb={'1%'}>
                <span className="text-2xl font-semibold">{title}</span>
            </Heading>
            <TabContext value={tabView}>
                <Box
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'brand.500',
                        },
                    }}>
                    <Tabs
                        value={tabView}
                        centered
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}>
                        <Tab label="General" key="General" />
                    </Tabs>
                    <TabPanel className="MuiTypography-body1" value={tabView}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}>
                            <Grid
                                container
                                xs={12}
                                style={{ maxWidth: '896px' }}
                                className="mx-auto flex justify-center">
                                {/* Trunk Name */}
                                <Grid item xs={6}>
                                    <TextField
                                        sx={{ paddingTop: 0.6 }}
                                        fullWidth
                                        id="TrunkName"
                                        InputLabelProps={{ shrink: true }}
                                        label="Trunk Name"
                                        size="small"
                                        value={state.name}
                                        onChange={(e) => {
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    name: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </TabPanel>
                </Box>
            </TabContext>

            {/* Back/Save Changes Button */}
            <div
                style={{
                    border: 'none',
                    textAlign: 'center',
                    justifyContent: 'center',
                    marginBottom: 10,
                }}>
                <Button
                    className="min-w-[5rem]"
                    variant="secondary"
                    onClick={handleBackbutton}>
                    Back
                </Button>
                <div style={{ marginLeft: 10, display: 'inline' }}>
                    <Button
                        className="min-w-[5rem]"
                        variant="primary"
                        type="submit"
                        onClick={handleEditTrunk}>
                        Update
                    </Button>
                </div>
            </div>
        </>
    );
};

export default EditZoomTrunk;
