import { createContext, useReducer } from 'react';

import { reducerFn, initState } from '.';

export const VisibilityGroupsContext = createContext(null);

export const VisibilityGroupsProvider = (props) => {
    const [state, dispatch] = useReducer(reducerFn, initState);

    return (
        <VisibilityGroupsContext.Provider value={[state, dispatch]}>
            {props.children}
        </VisibilityGroupsContext.Provider>
    );
};
