import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { connect } from 'react-redux';

const SUPPORT_EMAIL = ['support@pingco.cloud'];
//copied from react documentation
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null, email: props.email };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div
                    style={{
                        backgroundColor: '#000',
                        height: '100vh',
                        padding: 100,
                    }}>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        Something went wrong. Please try again later. If the
                        problem persists please contact your administrator.
                        {SUPPORT_EMAIL.includes(this.state.email) && (
                            <>
                                {this.state.error &&
                                    JSON.stringify(this.state.error)}
                                {this.state.errorInfo && (
                                    <p style={{ whiteSpace: 'pre-line' }}>
                                        {this.state.errorInfo.componentStack}
                                    </p>
                                )}
                            </>
                        )}
                    </Alert>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
    };
};
export default connect(mapStateToProps)(ErrorBoundary);
