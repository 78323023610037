import React, { useState } from 'react';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Select,
  Button,
  Flex,
  Text,
  useToast,
  Progress,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import { AttachmentIcon, CheckCircleIcon, SpinnerIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

function PBXImporter() {
  const navigate = useNavigate();
  const [sourcePlatform, setSourcePlatform] = useState('AI Auto Detect');
  const [targetPlatform, setTargetPlatform] = useState('Microsoft Teams');
  const [file, setFile] = useState(null);
  const [isConverting, setIsConverting] = useState(false);
  const [conversionStatus, setConversionStatus] = useState('');
  const [progressValue, setProgressValue] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const toast = useToast();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleConvert = () => {
    if (!file) {
      toast({
        title: 'Please upload a file.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsConverting(true);
    let statusIndex = 0;
    const statuses = [
      'Importing config file',
      'Validating numbers',
      'Mapping config to Platform User identity',
      'Creating import data format',
      'Importing numbers',
    ];

    const interval = setInterval(() => {
      if (statusIndex < statuses.length) {
        setConversionStatus(statuses[statusIndex]);
        setProgressValue((statusIndex / statuses.length) * 100);
        statusIndex++;
      } else {
        clearInterval(interval);
        setIsConverting(false);
        setConversionStatus('Import completed successfully!');
        setProgressValue(100);
        setShowSuccess(true);
        toast({
          title: 'Import completed successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    }, 5000);
  };

  const handleClose = () => {
    setIsConverting(false);
    setConversionStatus('');
    setProgressValue(0);
    setShowSuccess(false);
    navigate('/users')
  };

  return (
    <Box p={8} maxWidth="500px" mx="auto">
      <Heading mb={6}>PBX to Platform Importer</Heading>

      {!isConverting && !showSuccess && (
        <>
          <FormControl mb={4}>
            <FormLabel>Step 1 - Select Source Platform</FormLabel>
            <Select
              value={sourcePlatform}
              onChange={(e) => setSourcePlatform(e.target.value)}
            >
              <option>AI Auto Detect</option>
              <option>Avaya</option>
              <option>Mitel</option>
              <option>3CX</option>
              <option>TCAP Teams</option>
              <option>TCAP Zoom</option>
              <option>TCAP Webex</option>
              
            </Select>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Step 2 - Import PBX backup file</FormLabel>
            <input type="file" onChange={handleFileChange} id="file-upload" style={{ display: 'none' }} />
            <Button as="label" htmlFor="file-upload" leftIcon={<AttachmentIcon ml={1} />} colorScheme="blue">
              Upload
            </Button>
            {file && <Text mt={2}>Selected file: {file.name}</Text>}
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Step 3 - Select Target Platform</FormLabel>
            <Select
              value={targetPlatform}
              onChange={(e) => setTargetPlatform(e.target.value)}
            >
              <option>Microsoft Teams</option>
              <option>Zoom</option>
              <option>WebEx</option>
            </Select>
          </FormControl>

          <Button
            colorScheme="blue"
            onClick={handleConvert}
            isDisabled={isConverting || !file}
          >
            Convert
          </Button>
        </>
      )}

      {isConverting && !showSuccess && (
        <Box mt={4} textAlign="center">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
          <Box
            bg="blue.100"
            p={3}
            borderRadius="md"
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="center" // Center horizontally
          >
            <SpinnerIcon mr={2} />
            <Text>{conversionStatus}</Text>
          </Box>
          <Progress value={progressValue} mb={4} />
          <Button onClick={handleClose}>Close</Button>
        </Box>
      )}

      {showSuccess && (
        <Box mt={4} textAlign="center">
          <Box
            bg="green.100"
            p={4}
            borderRadius="md"
            mb={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              bg="green.100"
              p={4}
              borderRadius="md"
              mb={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Icon as={CheckCircleIcon} color="green.500" size="4em" mb={2} /> {/* Use Icon with size prop */}
              <Text>On-Prem PBX provisioned successfully!</Text>
            </Box>
          </Box>
          <Button onClick={handleClose}>Close</Button>
        </Box>
      )}
    </Box>
  );
}

export default PBXImporter;