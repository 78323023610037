import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    ListItem,
    UnorderedList,
    Text,
    AlertTitle,
} from '@chakra-ui/react';
import { DownloadIcon, AttachmentIcon } from '@chakra-ui/icons';

import { Alert, AlertIcon, Button, FormLabel, Heading } from '@/components/v4';
import walkthroughIds from '@/pages/accounts/users/walkthroughIds';
import { CSVPrompt } from '@/pages/accounts/users/csv/CSVTemplateView';
import {
    CSV_LIMIT_REACHED_DESCRIPTION_ERROR,
    CSV_LIMIT_REACHED_ERROR,
} from 'hooks/useCSVNumbersUpdate/constants';

/** Resource Account Template */
export default function CSVTemplateView({
    handleUploadUsersData,
    downloadCSVText,
    header,
    step1Text,
    step2Text,
    instructionsHeader,
    instructionsBody,
    handleDownloadTemplate,
    loading,
    csvLimit,
}) {
    const navigate = useNavigate();
    const { darkMode } = useSelector((state) => state.settings);

    // file upload
    const inputRef = useRef(null);

    const handleBackButton = () => {
        navigate(-1);
    };

    return (
        <Box display={'flex'} justifyContent="center">
            <Box
                width={400}
                display={'flex'}
                flexDirection={'column'}
                gap={'1px'}
                fontFamily={'Helvetica'}
                justifyContent={'center'}
                boxShadow="md"
                p="6"
                bg={darkMode ? 'dark.tcap' : 'white'}
                rounded="md">
                <Heading size="xl">{header}</Heading>
                <Box mt={'10px'}>
                    <Text size="md"> {step1Text} </Text>
                    <Button
                        isLoading={loading}
                        rightIcon={<DownloadIcon />}
                        sx={{ marginTop: 2, width: '100%' }}
                        size="sm"
                        onClick={handleDownloadTemplate}
                        data-walkthroughid={walkthroughIds.csv.ufDownloadBtn}>
                        {downloadCSVText}
                    </Button>
                </Box>

                <Box mt={'25px'}>
                    <Text size="md"> {step2Text} </Text>
                    <Button
                        size="sm"
                        as={FormLabel}
                        sx={{ marginTop: 2, width: '100%' }}
                        className="hover:cursor-pointer"
                        data-walkthroughid={walkthroughIds.csv.ufUploadBtn}
                        onClick={() => {
                            if (inputRef.current.value)
                                inputRef.current.value = '';
                        }}>
                        Choose File <AttachmentIcon ml={1} />
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            id="image-input"
                            accept=".csv"
                            ref={inputRef}
                            onChange={handleUploadUsersData}
                        />
                    </Button>
                </Box>

                {csvLimit && (
                    <Alert status="error">
                        <AlertIcon />
                        <Box flexDirection="column">
                            <AlertTitle>{CSV_LIMIT_REACHED_ERROR}</AlertTitle>
                            {CSV_LIMIT_REACHED_DESCRIPTION_ERROR}
                        </Box>
                    </Alert>
                )}

                <Box mt={'10px'} mb={'10px'}>
                    <Text size="lg" fontWeight="bold">
                        {' '}
                        {instructionsHeader}{' '}
                    </Text>
                    <UnorderedList>
                        {instructionsBody.map((v, i) => (
                            <ListItem key={i}>
                                <CSVPrompt sentence={v} />
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>
                <Button
                    variant="outline"
                    sx={{ marginTop: 2, width: '100%', bgColor: 'white' }}
                    size="sm"
                    _hover={{ opacity: 0.8 }}
                    onClick={handleBackButton}
                    data-walkthroughid={walkthroughIds.csv.ufCloseBtn}>
                    Close
                </Button>
            </Box>
        </Box>
    );
}
