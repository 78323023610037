import { useQuery } from '@tanstack/react-query';
import getPermissions from '@/API/Permissions/getPermissionsList';
import { addSpacing } from '@/utils/utils';

const useGetPermissions = async (id, setPermissions) => {
    return useQuery({
        queryKey: ['permissions', { id }],
        queryFn: async () => {
            try {
                const res = await getPermissions(id);
                if (!res) {
                    throw new Error('getPermissions returned undefined');
                }

                const newData = res.reduce((result, currentValue) => {
                    const currentValueName = addSpacing(currentValue.name);
                    if (!result[currentValueName]) {
                        result[currentValueName] = {
                            [currentValue.scope]: currentValue,
                        };
                        return result;
                    }
                    result[currentValueName][currentValue.scope] = currentValue;
                    return result;
                }, {});
                return newData;
            } catch (error) {
                throw new Error(error?.message || 'Failed to get permissions');
            }
        },
        onSuccess: (data) => setPermissions(data),
        onError: (error) => {
            console.error('Error getting permissions:', error);
            throw new Error(error?.message || 'Failed to get permissions');
        },
        refetchOnWindowFocus: false,
    });
};

export default useGetPermissions;
