/**
 * entry point page for /csvusers
 * note: validation and verification both used interchangeably here
 */
import React from 'react';
import useCSVUsers from './useCSVUsers.js';
import CSVTemplateView from './CSVTemplateView.jsx';
import { ChakraProvider, Box } from '@chakra-ui/react';
import chakratheme from '../../../../chakratheme';
import VerifyCSVView from './VerifyCSVView.jsx';
import VerifyCSVTableView from './VerifyCSVTableView.jsx';
import useBulkUpdate from './useBulkUpdate.js';
import APILoadingView from './APILoadingView.jsx';
import APIResultsView from './APIResultsView.jsx';

import useCSVTemplate from './useCSVTemplate.js';
import {
    passedColumns,
    failedColumns,
    succeedAPIColumns,
    failedAPIColumns,
} from '../constants';
import './reset.css';
import { nullError } from 'components/error.js';
import { useSelector } from 'react-redux';
import { Error } from 'components/index.js';
import appConfig from '../../../../config.json';

const DOWNLOAD_CSV_TEMPLATE = 'DOWNLOAD_CSV_TEMPLATE';
const LOADING_CSV_VALIDATION = 'LOADING_CSV_VALIDATION';
const CSV_VALIDATION_RESULTS = 'CSV_VALIDATION_RESULTS';
const API_CALLS_PROGRESS = 'API_CALLS_PROGRESS';
const API_CALLS_RESULTS = 'API_CALLS_RESULTS';
const CSV_DATA_NOT_FOUND = 'CSV_DATA_NOT_FOUND';
const CSV_LIMIT_REACHED = 'CSV_LIMIT_REACHED';

const useCSVDisplayControl = () => {
    //UI button states
    const [viewVerificationResults, setViewVerificationResults] =
        React.useState(false);
    const [viewAPIResults, setViewAPIResults] = React.useState(false);
    //processing csv hook
    const {
        handleUploadUsersData,
        uploadData,
        verificationChecks,
        loading: verificationLoading,
        error: verificationErrors,
        payloads,
        verificationResult,
        handleReset: handleResetCSV,
        handleExportInvalidData,
        csvLimit, // boolean check for csv with more than appConfig.csvLimit records
    } = useCSVUsers();
    //api calls hook
    const {
        handlePayloadChange,
        loading: apiLoading,
        counter: completionCounter,
        errorCounter,
        error: apiErrors,
        succeed: apiSucceed,
        handleExportFailedData,
        handleReset: handleResetBulkUpdate,
        total,
    } = useBulkUpdate();
    //button handler to view CSV_VALIDATION_RESULTS
    const handleViewVerificationResults = () => {
        setViewVerificationResults(true);
    };
    //button handler to view API_CALLS_RESULTS
    const handleViewAPIResults = () => {
        setViewAPIResults(true);
    };
    //button handler to reset everything
    const handleStartOver = () => {
        setViewVerificationResults(false);
        setViewAPIResults(false);
        handleResetCSV();
        handleResetBulkUpdate();
        window.location.reload();
    };
    //handles what need to be currently shown
    const handleCurrentDisplay = () => {
        if (verificationErrors) {
            return CSV_DATA_NOT_FOUND;
        }
        if (csvLimit) {
            return CSV_LIMIT_REACHED;
        }
        if (!uploadData) {
            return DOWNLOAD_CSV_TEMPLATE;
        }

        if (viewAPIResults) {
            return API_CALLS_RESULTS;
        }

        if (apiLoading) {
            return API_CALLS_PROGRESS;
        }

        if (viewVerificationResults) {
            return CSV_VALIDATION_RESULTS;
        }

        if (verificationLoading) {
            return LOADING_CSV_VALIDATION;
        }
    };
    //handle cancel button on CSV_VALIDATION_RESULTS
    const handleCancelValidationTable = () => {
        handleResetCSV();
        setViewVerificationResults(false);
        // window.location.reload();
    };
    //handle transition to start API_CALLS_PROGRESS
    const handleStartAPICall = () => {
        handlePayloadChange(payloads);
        setViewVerificationResults(false);
    };

    return {
        handleUploadUsersData,
        handleCurrentDisplay,
        verificationChecks,
        verificationErrors,
        handleViewVerificationResults,
        verificationResult,
        handleCancel: handleCancelValidationTable,
        handleImport: handleStartAPICall,
        handleExportInvalidData,
        completionCounter,
        payloads,
        errorCounter,
        handleViewAPIResults,
        apiErrors,
        apiSucceed,
        handleExportFailedData,
        handleStartOver,
        total,
        csvLimit,
    };
};

export default function CSVDisplay() {
    const {
        handleUploadUsersData,
        handleCurrentDisplay,
        verificationChecks,
        handleViewVerificationResults,
        verificationResult,
        handleCancel,
        verificationErrors,
        handleImport,
        handleExportInvalidData,
        completionCounter,
        payloads,
        errorCounter,
        handleViewAPIResults,
        apiErrors,
        apiSucceed,
        handleExportFailedData,
        handleStartOver,
        total,
        csvLimit,
    } = useCSVDisplayControl();

    const currentDisplay = handleCurrentDisplay();

    const { handleDownloadTemplate, loading: templateLoading } =
        useCSVTemplate();

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    if (!currentCompany) {
        return <Error error={nullError} />;
    }

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <Box id="csv-upload">
                {(currentDisplay === DOWNLOAD_CSV_TEMPLATE ||
                    currentDisplay === CSV_LIMIT_REACHED) && (
                    <CSVTemplateView
                        handleUploadUsersData={handleUploadUsersData}
                        downloadCSVText="Download"
                        header="CSV Upload - Users"
                        step1Text="Step 1 - Download the CSV template"
                        step2Text="Step 2 - Upload CSV to be imported"
                        instructionsHeader="Instructions"
                        handleDownloadTemplate={handleDownloadTemplate}
                        loading={templateLoading}
                        bgColor="white"
                        instructionsBody={[
                            `CSV upload is limited to a maximum of ${appConfig.csvLimit} items per upload.`,
                            'Swapping numbers requires you to un-assign and then re-assign.',
                            'Please ensure you keep the original CSV file, in case of data corruption.',
                            'You are responsible for the changes you are about to make through this data import.',
                        ]}
                        csvLimit={csvLimit}
                    />
                )}
                {currentDisplay === LOADING_CSV_VALIDATION && (
                    <VerifyCSVView
                        handleViewVerificationResults={
                            handleViewVerificationResults
                        }
                        verificationChecks={verificationChecks}
                    />
                )}
                {currentDisplay === CSV_VALIDATION_RESULTS && (
                    <VerifyCSVTableView
                        verificationResult={verificationResult}
                        handleCancel={handleCancel}
                        handleImport={handleImport}
                        handleExportInvalidData={handleExportInvalidData}
                        handleStartOver={handleStartOver}
                        passedColumns={passedColumns}
                        failedColumns={failedColumns}
                    />
                )}
                {currentDisplay === API_CALLS_PROGRESS && (
                    <APILoadingView
                        completionCounter={completionCounter}
                        payloads={payloads}
                        verificationResult={verificationResult}
                        errorCounter={errorCounter}
                        handleViewAPIResults={handleViewAPIResults}
                        total={total}
                    />
                )}
                {currentDisplay === API_CALLS_RESULTS && (
                    <APIResultsView
                        error={apiErrors}
                        succeed={apiSucceed}
                        succeedAPIColumns={succeedAPIColumns}
                        failedAPIColumns={failedAPIColumns}
                        handleExportFailedData={handleExportFailedData}
                        handleDone={handleStartOver}
                    />
                )}
                {currentDisplay === CSV_DATA_NOT_FOUND && (
                    <VerifyCSVView
                        handleStartOver={handleStartOver}
                        errors={verificationErrors}
                    />
                )}
            </Box>
        </ChakraProvider>
    );
}
