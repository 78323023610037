import { useQuery } from '@tanstack/react-query';
import getPermissionsSet from '@/API/PermissionsSets/getPermissionSets';

const useGetPermissionsSet = (currentScope, currentCompany, setTableData) => {
    return useQuery({
        queryKey: ['permissionsSet', { currentScope, currentCompany }],
        queryFn: () => getPermissionsSet({ currentScope, currentCompany }),
        refetchOnWindowFocus: false,
        onError: (error) => {
            throw new Error(error);
        },
        onSuccess: (data) => {
            setTableData((prev) => ({
                ...prev,
                data: data,
            }));
        },
    });
};

export default useGetPermissionsSet;
