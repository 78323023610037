import { useState } from 'react';
import { useSelector } from 'react-redux';
import { axios } from 'services/axios';
import {
    CSV_TYPE,
    CSV_TYPE_ERROR,
    DEFAULT_VERIFICATION_RESULT,
    VERIFICATION_CHECKS,
    INVALID_FILENAME,
    buildColumns,
} from './constants';
import Papa from 'papaparse';
import delay from 'lodash/delay';

import { downloadCSV } from 'utils/utils';
import { CSV_TYPE_FX } from 'pages/accounts/users/csv/useCSVUsers';
import appConfig from '../../config.json';

export default function useCSVAddressUpdate() {
    const { currentCompany: companyID } = useSelector(
        (state) => state.navigationLists,
    );
    const companyName = useSelector(
        (state) => state.navigationLists.currentCompanyData?.name
    );
    const [uploadData, setUploadData] = useState(null);
    const [error, setError] = useState(null);
    const [csvLimit, setCSVLimit] = useState(false);
    const [templateLoading, setTemplateLoading] = useState(false);

    const [payloads, setPayloads] = useState(null);
    const [customFailedColumns, setCustomFailedColumns] = useState()
    const [customPassedColumns, setCustomPassedColumns] = useState()

    const [verificationChecks, setVerificationChecks] =
        useState(VERIFICATION_CHECKS);
    const [verificationResult, setVerificationResult] = useState(
        DEFAULT_VERIFICATION_RESULT,
    );
    /**
     * for UI Validation progress checks, sets the next index to be in progress.
     * @param {*} i index in VERIFICATION_CHECKS that needs to be updated.
     */
    console.log(companyName)
    const changeVerificationChecks = (i) => {
        delay(
            (index) => {
                setVerificationChecks((prev) => {
                    const newVerificationChecks = [...prev];
                    newVerificationChecks[index].check = true;
                    if (index < newVerificationChecks.length - 1) {
                        newVerificationChecks[index + 1].current = true;
                    }
                    return newVerificationChecks;
                });
            },
            750,
            i,
        );
    };


    /**
     * for UI Validation progress checks, increments current index's completionCounter.
     * @param {*} i index in VERIFICATION_CHECKS that needs to be updated.
     */
    const changeVerificationProgresses = (i) => {
        delay(
            (index) => {
                setVerificationChecks((prev) => {
                    const newVerificationChecks = [...prev];
                    newVerificationChecks[index].completionCounter += 1;
                    return newVerificationChecks;
                });
            },
            750,
            i,
        );
    };





    /**
     * use papaparse to convert CSV -> JSON (with webworker).
     * @param {*} data CSV data to be converted to JSON.
     * @returns JSON data
     */
    const convertCSVDataToJSON = async (data) => {
        return new Promise((resolve) => {
            const papaparseConfig = {
                dynamicTyping: true, //for numbers type
                header: true, //CSV has headers
                worker: true, //use worker thread
                complete: function (results) {
                    return resolve(results);
                },
            };
            Papa.parse(data, papaparseConfig);
        });
    };

    const validateInteger = (value, fieldName, errors) => {
        if (value !== null && value !== undefined) {
            const numberString = String(value).trim();
            const integerRegex = /^-?\d+$/;
            if (integerRegex.test(numberString)) {
                const parsedNumber = parseInt(numberString, 10);
                if (!Number.isInteger(parsedNumber)) {
                    errors.push(`${fieldName} is not an integer`);
                }
            } else {
                errors.push(`${fieldName} is not an integer`);
            }
        }
    };
     const validateString = (value, fieldName, errors) => {
        if (value !== null && value !== undefined && typeof value !== 'string') {
            errors.push(`${fieldName} is not a string`);
        }
    };
    function compareArrays(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        return arr1.every((value, index) => value === arr2[index]);
    }
    async function
        processData(dataFromCSV) {
        try {
            const countryCodesResponse = await axios.get('/Countries');
            // Replace with your API endpoint
            const countryCodes = countryCodesResponse.data // Extract alpha2 codes

            const { passed, failed } = await validateAndSeparateData(dataFromCSV, countryCodes);
            console.log(passed);
            console.log(failed);
            const tableResults = {
                passed: {
                    data: passed // Correct: Assign the passed array
                },
                failed: {
                    data: failed // Correct: Assign the failed array
                }
            };
            console.log(tableResults.passed.data)
            const passedColumns = buildColumns(tableResults.passed.data, false);
            console.log(passedColumns)
            const failedColumns = buildColumns(tableResults.failed.data, true);
            setCustomFailedColumns(failedColumns)
            setCustomPassedColumns(passedColumns)
            setVerificationResult(tableResults)
            setPayloads(passed)
            // ... continue processing the passed and failed arrays
        } catch (error) {
            console.error('Error processing data:', error);
            // Handle the error (e.g., display an error message)
        }
    }
    async function validateAndSeparateData(data, countryCodes) {
        const passed = [];
        const failed = [];
        data.pop();
        for (const item of data) {
            const errors = [];
            validateString(item.description, 'description', errors);
            validateInteger(item.streetHouseNumber1, 'streetHouseNumber1', errors);
            validateInteger(item.building1stNumber, 'building1stNumber', errors);
            validateInteger(item.building2ndNumber, 'building2ndNumber', errors);
            validateInteger(item.buildingFloorNumber, 'buildingFloorNumber', errors);
            validateInteger(item.streetHouseNumber2, 'streetHouseNumber2', errors);
            // if (typeof item.description !== 'string' && item.description !== null) {
            //     errors.push('description is not a string');
            // }

            // if ((!Number.isInteger(item.streetHouseNumber1) && item.streetHouseNumber1!==null)) {
            //     errors.push('streetHouse is not an integer');
            // }

            // if (item.streetHouseNumber1 !== null) {
            //     const numberString = item.streetHouseNumber1 // Trim whitespace

            //     const integerRegex = /^-?\d+$/; // Matches optional negative sign followed by digits

            //     if (integerRegex.test(numberString)) {
            //         const parsedNumber = parseInt(numberString, 10);

            //         if (!Number.isInteger(parsedNumber)) {
            //             errors.push('streetHouseNumber1 is not an integer');
            //         }
            //     } else {
            //         errors.push('streetHouseNumber1 is not an integer'); // If regex fails, it's not an integer
            //     }
            // }
            const isValidCountryCode = countryCodes.find(c => c.alpha2 === item.country);
            if(item.streetHouseNumber1===null || item.streetHouseNumber1===''){
                errors.push('streetHouseNumber1 is a mandatory field');
            }
            if (!isValidCountryCode) { // Check for undefined and null
                errors.push('country is not a valid country code');
            }
            if (errors.length === 0) {

                if (item.companyName === '' || item.companyName === null) {
                    passed.push({ ...item, countryId: isValidCountryCode.id, companyName: companyName , companyID: companyID });
                } else {
                    passed.push({ ...item, countryId: isValidCountryCode.id, companyID: companyID });
                } 
            } else {
                failed.push({ ...item, failureReasons: errors }); 
            }
        }

        return { passed, failed };
    }



    const handleUploadData = async (e) => {
        if (
            e.target.files[0].type === CSV_TYPE ||
            e.target.files[0].type === CSV_TYPE_FX
        ) {
            const data = e.target.files[0];
            console.log(data)
            const { data: newDataJSON } = await convertCSVDataToJSON(data);
            console.log(newDataJSON)
            // // check for csv limits
            if (newDataJSON.length > appConfig.csvLimit) {
                setCSVLimit(true);
                setVerificationChecks((prev) => {
                    const newVerificationChecks = [...prev];
                    newVerificationChecks.forEach((v, index) => {
                        v.check = false;
                        v.current = index === 0; // NOTE: restart at Received CSV check
                        v.completionCounter = 0;
                    });
                    return newVerificationChecks;
                });
                return;
            }

            if (csvLimit) setCSVLimit(false);

            //save data
            //setLoading(true);
            setUploadData(newDataJSON);
            changeVerificationProgresses(0);
            changeVerificationChecks(0);
            console.log(Object.keys(newDataJSON))
            const result = compareArrays(Object.keys(newDataJSON[0]), ['companyName','description', 'buildingType', 'building1stSuffix', 'building1stNumber', 'building2ndSuffix', 'building2ndNumber', 'buildingFloorType', 'buildingFloorNumber', 'buildingFloorNumberSuffix', 'buildingProperty', 'buildingLocation', 'streetHouseNumber1', 'streetHouseNumber1Suffix', 'streetHouseNumber2', 'streetHouseNumber2Suffix', 'streetName1', 'streetType1', 'streetSuffix1', 'streetName2', 'streetType2', 'streetSuffix2', 'locality', 'district', 'state', 'postcode', 'country', 'googlePlaceID'])
            if (result) {
                changeVerificationProgresses(1);
                changeVerificationChecks(1);
            }
            else {
                // setError(['Invalid CSV headers in file'])
                changeVerificationProgresses(1)
                changeVerificationChecks(1);
            }



            // Example usage (assuming you have the country codes from the API):


            changeVerificationProgresses(2);
            changeVerificationChecks(2);
            // convertCSVData(newDataJSON, setError); 
            processData(newDataJSON)

        } else {
            window.alert(CSV_TYPE_ERROR);
        }
    };

    // const handleUploadData = async (e) => {
    //     if (e.target.files[0].type === CSV_TYPE || e.target.files[0].type === CSV_TYPE_FX) {
    //         const file = e.target.files[0];
    //         const reader = new FileReader();

    //         reader.onload = async (event) => {
    //             const csvText = event.target.result; // Read file content as text

    //             try {
    //                 const { data: newDataJSON } = await convertCSVDataToJSON(csvText);
    //                 console.log(newDataJSON);

    //                 // Check for CSV limits
    //                 if (newDataJSON.length > appConfig.csvLimit) {
    //                     setCSVLimit(true);
    //                     setVerificationChecks((prev) => {
    //                         const newVerificationChecks = [...prev];
    //                         newVerificationChecks.forEach((v, index) => {
    //                             v.check = false;
    //                             v.current = index === 0;
    //                             v.completionCounter = 0;
    //                         });
    //                         return newVerificationChecks;
    //                     });
    //                     return;
    //                 }

    //                 if (csvLimit) setCSVLimit(false);

    //                 // Save data
    //                 setUploadData(newDataJSON);
    //                 changeVerificationProgresses(0);
    //                 changeVerificationChecks(0);
    //                 console.log(Object.keys(newDataJSON));

    //                 const result = compareArrays(Object.keys(newDataJSON[0]), [
    //                     'description',
    //                     'buildingType',
    //                     'building1stSuffix',
    //                     'building1stNumber',
    //                     'building2ndSuffix',
    //                     'building2ndNumber',
    //                     'buildingFloorType',
    //                     'buildingFloorNumber',
    //                     'buildingFloorNumberSuffix',
    //                     'buildingProperty',
    //                     'buildingLocation',
    //                     'streetHouseNumber1',
    //                     'streetHouseNumber1Suffix',
    //                     'streetHouseNumber2',
    //                     'streetHouseNumber2Suffix',
    //                     'streetName1',
    //                     'streetType1',
    //                     'streetSuffix1',
    //                     'streetName2',
    //                     'streetType2',
    //                     'streetSuffix2',
    //                     'locality',
    //                     'district',
    //                     'state',
    //                     'postcode',
    //                     'country',
    //                     'googlePlaceID',
    //                 ]);

    //                 if (result) {
    //                     changeVerificationProgresses(1);
    //                     changeVerificationChecks(1);
    //                 } else {
    //                     changeVerificationProgresses(1);
    //                     changeVerificationChecks(1);
    //                 }

    //                 changeVerificationProgresses(2);
    //                 changeVerificationChecks(2);
    //                 processData(newDataJSON);
    //             } catch (error) {
    //                 console.error("Error processing CSV:", error);
    //                 // Handle parsing errors
    //             }
    //         };

    //         reader.onerror = (event) => {
    //             console.error("Error reading file:", event.target.error);
    //             // Handle file reading errors
    //         };

    //         reader.readAsText(file); // Read the file as text
    //     } else {
    //         window.alert(CSV_TYPE_ERROR);
    //     }
    // };





    const handleDownloadCSVTemplate = () => {
        const jsonData = [{
            'companyName': '',
            'description': '',
            'buildingType': '',
            'building1stNumber': '',
            'building1stSuffix': '',
            'building2ndNumber': '',
            'building2ndSuffix': '',
            'buildingFloorType': '',
            'buildingFloorNumber': '',
            'buildingFloorNumberSuffix': '',
            'buildingProperty': '',
            'buildingLocation': '',
            'streetHouseNumber1': '',
            'streetHouseNumber1Suffix': '',
            'streetHouseNumber2': '',
            'streetHouseNumber2Suffix': '',
            'streetName1': '',
            'streetType1': '',
            'streetSuffix1': '',
            'streetName2': '',
            'streetType2': '',
            'streetSuffix2': '',
            'locality': '',
            'district': '',
            'state': '',
            'postcode': '',
            'locationId': '',
            'civicAddressID': '',
            'latitude': '',
            'longitude': '',
            'country': '',
            'googlePlaceID': '',
        }]
        const res = Papa.unparse(jsonData);
        downloadCSV(res, 'addresses_template.csv');
        setTemplateLoading(false);
    };


    return {
        handleDownloadCSVTemplate,
        handleUploadData,
        templateLoading,
        verificationChecks,
        uploadData,
        verificationResult,
        payloads,
        error,
        csvLimit,
        customFailedColumns,
        customPassedColumns
    };
}
