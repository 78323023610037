import { useState, useRef } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    MenuItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    Tooltip,
    useBreakpointValue,
    useClipboard,
    useDisclosure,
} from '@chakra-ui/react';
import { CopyIcon, LinkIcon } from '@chakra-ui/icons';
import { axios } from '@/services/axios';
import { useSelector } from 'react-redux';
export const GeneratePermission = ({ serviceObj }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [baseUrl, setBaseUrl] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [error, setError] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false); // State for error display
    const { darkMode } = useSelector((state) => state.settings);
    const initialRef = useRef();
    const inputRef = useRef(null);
    const { hasCopied, onCopy } = useClipboard(redirectUrl.data);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const truncatedValue =
        redirectUrl?.data?.length > 55
            ? redirectUrl?.data?.substring(0, 55) + '.....'
            : redirectUrl?.data?.length;
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setShowError(false);
    };
    const handleCopy = (event) => {
        setError('');
        setBaseUrl(event.target.value);
    };
    const isValidURL = (string) => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
    };
    const generateCopy = () => {
        let errorMessage = '';
        if (baseUrl === '') {
            setBaseUrl(window.location.origin);
            // setError(`please enter a url example ${window.location.origin}`);
            // return;
        }
        if (baseUrl?.length > 100) {
            errorMessage = `URL must be less than 60 characters example ${window.location.origin}`;
            setError(errorMessage);
            return;
        } else if (baseUrl && !isValidURL(baseUrl)) {
            errorMessage = `Invalid input. Please provide as URL, example ${window.location.origin}`;
            setError(errorMessage);
            return;
        }
        if (!isChecked) {
            setShowError(true);
            return;
        }
        const urlOrigin = new URL(baseUrl);
        axios
            .get(
                `/Company/${serviceObj?.companyID}/MSLoginLink?domain=${urlOrigin}`,
                {
                    headers: {
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache',
                        Expires: '0',
                    },
                },
            )
            .then((res) => setRedirectUrl(res))
            .catch((e) => e);
        setBaseUrl('');
    };
    const closeAction = () => {
        if (redirectUrl !== '') {
            setRedirectUrl('');
            setIsChecked(false);
        }
        onClose;
    };
    const closeDone = () => {
        setRedirectUrl('');
        onClose();
        setIsChecked(false);
    };

    return (
        <>
            <MenuItem
                id="request-access"
                icon={<LinkIcon color={darkMode ? 'white' : 'black'} />}
                onClick={() => {
                    onOpen();
                }}
                data-walkthroughid="resource-id">
                <Text marginBottom="0">Request Access</Text>
            </MenuItem>
            <Modal
                blockScrollOnMount={false}
                initialFocusRef={initialRef}
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <span style={{ color: 'blue' }}>
                            {' '}
                            {serviceObj?.company?.name}
                        </span>
                        -Generate Permission URL
                    </ModalHeader>
                    <ModalCloseButton onClick={closeAction} />
                    {redirectUrl === '' && (
                        <>
                            <ModalBody pb={6}>
                                <Text mb="1rem">
                                    Please enter a url to redirect the customer
                                    after permissions have been granted. Leave
                                    empty to use {window.location.origin}.
                                </Text>
                                <FormControl>
                                    <Stack spacing={4}>
                                        <Flex
                                            align="center"
                                            justifyContent="center">
                                            <FormLabel
                                                sx={
                                                    error
                                                        ? {
                                                              paddingBottom:
                                                                  '17px',
                                                          }
                                                        : {}
                                                }>
                                                Redirect URL
                                            </FormLabel>
                                            <Flex
                                                direction="column"
                                                alignItems="center">
                                                {' '}
                                                {/* Inner Flex for input and error (column) */}
                                                <FormControl
                                                    isInvalid={!!error}>
                                                    {' '}
                                                    {/* FormControl for styling */}
                                                    <Input
                                                        w={!isMobile && 'sm'}
                                                        ref={initialRef}
                                                        placeholder="Redirect URL"
                                                        value={baseUrl}
                                                        onChange={handleCopy}
                                                        data-walkthroughid="resource-url-input"
                                                    />
                                                </FormControl>
                                                {error && (
                                                    <FormHelperText
                                                        data-walkthroughid="resource-url-input-errortext"
                                                        color="red.500">
                                                        {error}
                                                    </FormHelperText>
                                                )}
                                            </Flex>
                                        </Flex>

                                        <Flex align="flex-start" spacing={2}>
                                            {' '}
                                            {/* Align items to the start (left) */}
                                            <Checkbox
                                                id="terms"
                                                data-walkthroughid="resource-url-checkbox"
                                                size="lg"
                                                isChecked={isChecked}
                                                onChange={handleCheckboxChange}
                                                borderColor={
                                                    showError
                                                        ? 'red.500'
                                                        : undefined
                                                }>
                                                {' '}
                                            </Checkbox>
                                            <Box>
                                                <Text fontSize="sm" as="span">
                                                    {' '}
                                                    {/* ml adds left margin */}I
                                                    acknowledge the link
                                                    generated by this process
                                                    should only be sent to an
                                                    Administrator at{' '}
                                                    <span
                                                        style={{
                                                            color: 'blue',
                                                        }}>
                                                        {' '}
                                                        {
                                                            serviceObj?.company
                                                                ?.name
                                                        }
                                                    </span>
                                                    . Sending the wrong url to
                                                    the wrong company may result
                                                    in incorrectly synced data
                                                    requiring the customer to be
                                                    recreated.
                                                </Text>
                                            </Box>
                                        </Flex>
                                        <Flex
                                            align="flex-start"
                                            justifyContent="space-between">
                                            <Button
                                                colorScheme="gray"
                                                data-walkthroughid="resource-url-cancel"
                                                width="50%"
                                                onClick={onClose}
                                                mr={3}>
                                                Cancel
                                            </Button>
                                            <Button
                                                width="50%"
                                                data-walkthroughid="resource-url-submit"
                                                onClick={generateCopy}
                                                variantColor="blue">
                                                Submit
                                            </Button>
                                        </Flex>
                                    </Stack>
                                </FormControl>
                            </ModalBody>
                        </>
                    )}
                    {redirectUrl !== '' && (
                        <>
                            <ModalBody pb={6}>
                                <>
                                    <Text fontSize="sm">
                                        {' '}
                                        {/* ml adds left margin */}
                                        Please copy the link below and send to
                                        an {serviceObj?.company?.name}{' '}
                                        adminstrator
                                    </Text>
                                </>
                                <Stack spacing={4}>
                                    <InputGroup>
                                        <Tooltip
                                            label={redirectUrl.data}
                                            placement="top">
                                            <Input
                                                ref={inputRef}
                                                value={truncatedValue}
                                                isReadOnly
                                            />
                                        </Tooltip>
                                        <InputRightElement width="4.5rem">
                                            <Tooltip
                                                label={
                                                    hasCopied
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                                placement="top">
                                                <Button
                                                    h="1.75rem"
                                                    size="sm"
                                                    onClick={() => {
                                                        onCopy();
                                                        // if (inputRef.current) {
                                                        //     inputRef.current.select();
                                                        // }
                                                    }}>
                                                    <CopyIcon />
                                                </Button>
                                            </Tooltip>
                                        </InputRightElement>
                                    </InputGroup>
                                    <ModalFooter>
                                        <Button
                                            data-walkthroughid="resource-url-done"
                                            onClick={closeDone}>
                                            Done
                                        </Button>
                                    </ModalFooter>
                                </Stack>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};
