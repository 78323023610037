import { useState } from 'react';
import { FormControl, Stack, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import NameField from '@/components/forms/new/formikFormFields/NameField';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../walkthroughIds';

const PermissionsSetName = ({ errors, touched, darkMode, disabled }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            isInvalid={!!errors.name && touched.name}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack direction={'row'} spacing={stackSpacing} sx={stackStyling()}>
                <FormLabel sx={formLabelStyling(darkMode)}>
                    Name <span style={{ color: '#e53e3e' }}>*</span>
                </FormLabel>
                <Field
                    component={NameField}
                    walkthroughid={walkthroughIds.permissions.ufPermissionSet}
                    id="name"
                    name="name"
                    disabled={disabled}
                    validate={(value) => {
                        if (!value || value === '') {
                            return 'Name is required';
                        }
                    }}
                />
                {isHovered && (
                    <FieldToolTip
                        label="Name of Permissions Set"
                        ariaLabel="permissions-name-tool-tip"
                        left="25px"
                        top="25px"
                    />
                )}
            </Stack>
        </FormControl>
    );
};

export default PermissionsSetName;
