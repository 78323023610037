import CSVTemplateView from 'pages/accounts/users/csv/CSVTemplateView.jsx';
import VerifyCSVView from 'pages/accounts/users/csv/VerifyCSVView';
import VerifyCSVTableView from 'pages/accounts/users/csv/VerifyCSVTableView';
import { ChakraProvider } from '@chakra-ui/react';
import chakratheme from 'src/chakratheme';
import { useState } from 'react';
import LoadingIndicator from 'components/v4/LoadingIndicator';
import APILoadingView from 'pages/accounts/users/csv/APILoadingView';
import APIResultsView from 'pages/accounts/users/csv/APIResultsView';
import useCSVAddressUpdate from 'hooks/useCSVAddressupdate';
import { failedAPIColumns, failedColumns, passedColumns, succeedAPIColumns } from 'hooks/useCSVAddressUpdate/constants';
import  useCSVAddressBulkUpdate from 'hooks/useCSVAddressupdate/useCSVAddressBulkUpdate';
const useCSVAddressUpdateUIControl = () => {
    const [viewVerificationResults, setViewVerificationResults] =
        useState(false);
    const [viewAPIResults, setViewAPIResults] = useState(false);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const handleViewVerificationResults = () => {
        setViewVerificationResults(true);
    };
    const handleCancel = () => {
        setLoadingIndicator(true);
        window.location.reload();
    };
    const handleViewAPIResults = () => {
        setViewAPIResults(true);
        setViewVerificationResults(false);
    };
    return {
        viewVerificationResults,
        handleViewVerificationResults,
        handleCancel,
        loadingIndicator,
        handleViewAPIResults, 
        viewAPIResults,
    };
};
export default function CSVAddressUpdate() {
    const {
        handleDownloadCSVTemplate,
        handleUploadData,
        templateLoading,
        verificationChecks,
        uploadData,
        handleExportInvalidData,
        verificationResult,
        payloads,
        error: verificationError,
        csvLimit,
        customFailedColumns,
        customPassedColumns
    } = useCSVAddressUpdate();
    const {
        viewVerificationResults,
        handleViewVerificationResults,
        handleCancel,
        handleViewAPIResults,
        loadingIndicator,
        viewAPIResults,
    } = useCSVAddressUpdateUIControl();
    const {
        handlePayloadChange,
        loading: apiLoading,
        counter,
        errorCounter,
        error,
        succeed,
        handleExportFailedData,
        total,
        customSucessColumns,
    } = useCSVAddressBulkUpdate();

    if (loadingIndicator) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <LoadingIndicator />
            </ChakraProvider>
        );
    }
    if (verificationError) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <VerifyCSVView
                    handleStartOver={handleCancel}
                    errors={verificationError}
                />
            </ChakraProvider>
        );
    }
    if (viewAPIResults) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <APIResultsView
                    error={error}
                    succeed={succeed}
                    handleExportFailedData={handleExportFailedData}
                    handleDone={handleCancel}
                    succeedAPIColumns={customSucessColumns}
                    failedAPIColumns={failedAPIColumns}
                />
            </ChakraProvider>
        );
    }
    if (apiLoading) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <APILoadingView
                    completionCounter={counter}
                    payloads={payloads}
                    verificationResult={verificationResult}
                    errorCounter={errorCounter}
                    handleViewAPIResults={handleViewAPIResults}
                    total={total}
                />
            </ChakraProvider>
        );
    }

    if (viewVerificationResults) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <VerifyCSVTableView
                    verificationResult={verificationResult}
                    handleCancel={handleCancel}
                    handleImport={() => {
                        handlePayloadChange(payloads);
                    }}
                    passedColumns={customPassedColumns}
                    failedColumns={customFailedColumns}
                    handleExportInvalidData={handleExportInvalidData}
                    handleStartOver={handleCancel}
                />
            </ChakraProvider>
        );
    }
    if (uploadData) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <VerifyCSVView
                    handleViewVerificationResults={
                        handleViewVerificationResults
                    }
                    verificationChecks={verificationChecks}
                />
            </ChakraProvider>
        );
    }

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <CSVTemplateView
                handleUploadUsersData={handleUploadData}
                downloadCSVText="Download"
                header="CSV Upload"
                step1Text="Step 1 - Download the CSV template"
                step2Text="Step 2 - Upload CSV to be imported"
                instructionsHeader="Instructions"
                handleDownloadTemplate={handleDownloadCSVTemplate}
                loading={templateLoading}
                bgColor="white"
                instructionsBody={[
                    'This feature is used to create new addresses only.',
                    'streetHouseNumber1 and country are mandatory requirements to create an address and also when you uploading file ensure type is utf-8 to support arbitrary languages.',
                    'Once an address is created, it cannot be deleted from TCAP.',
                    'The company default address is defined under Company settings.',
                    'You are responsible for the changes you are about to make through this data import.',
                ]}
                csvLimit={csvLimit}
            />
        </ChakraProvider>
    );
}
