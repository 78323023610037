export const validationSchemaDR = {
    name: [{ type: 'isEmpty', message: 'Service Name cannot be left empty.' }],
    voicePolicies: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one voice policy.',
        },
    ],
    dialPlans: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one dial plan.',
        },
    ],
    regions: [
        { type: 'isArrayEmpty', message: 'Please select at least one region.' },
    ],
};

export const validationSchemaOC = {
    name: [{ type: 'isEmpty', message: 'Service Name cannot be left empty.' }],
    voicePolicies: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one voice policy.',
        },
    ],
    callingProfiles: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one calling profile.',
        },
    ],
    operatorProfiles: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one operator profile.',
        },
    ],
    dialPlans: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one dial plan.',
        },
    ],
    regions: [
        { type: 'isArrayEmpty', message: 'Please select at least one region.' },
    ],
};

export const validationSchemaSBCAAS = {
    name: [{ type: 'isEmpty', message: 'Service Name cannot be left empty.' }],
    maxChannel: [
        {
            type: 'isBetween',
            conditions: [1, 100],
            message: 'Max Channel should be between 1 and 100.',
        },
    ],
    regions: [
        { type: 'isArrayEmpty', message: 'Please select at least one region.' },
    ],
    // trunkTypes: [
    //     { type: 'isArrayEmpty', message: 'Please select at least one trunk type.' },
    // ],
};

export const validationSchemaACS = {
    name: [{ type: 'isEmpty', message: 'Service Name cannot be left empty.' }],
    regions: [
        { type: 'isArrayEmpty', message: 'Please select at least one region.' },
    ],
    maxChannel: [
        { type: 'isEmpty', message: 'Max Channel Count cannot be left empty.' },
    ],
};

export const validationSchemaTPM = {
    name: [{ type: 'isEmpty', message: 'Service Name cannot be left empty.' }],
    callingProfiles: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one calling profile.',
        },
    ],
    operatorProfiles: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one operator profile.',
        },
    ],
};

export const validationSchemaOCC = {
    name: [{ type: 'isEmpty', message: 'Service Name cannot be left empty.' }],
    callingProfiles: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one calling profile.',
        },
    ],
    operatorProfiles: [
        {
            type: 'isArrayEmpty',
            message: 'Please select at least one operator profile.',
        },
    ],
};

export const validationSchemaVD = {
    name: [{ type: 'isEmpty', message: 'Service Name cannot be left empty.' }],
};

export const validationSchemaZoom = {
    name: [{ type: 'isEmpty', message: 'Service Name cannot be left empty.' }],
};
