export const INVALID_USERNAME_PASSWORD =
    'Login unsuccessful due to invalid username or password';
export const EXPIRED_PASSWORD = 'Login unsuccessful due to expired password';
export const DISABLED_DELETE_USER =
    'Login unsuccessful due to disabled or deleted user';
export const CONDITIONAL_ACCESS_REQUIREMENT =
    'Login unsuccessful due to conditional access requirement';
export const MFA_REQUIREMENT = 'Login unsuccessful due to MFA requirement';
export const ACCESS_MICROSOFT_FAILED =
    'Access via Microsoft Graph failed (no roles returned)';

export const NO_VOICE_POLICIES =
    'Provisioning DNS (Incomplete: Missing Voice Policies)';
export const NO_DIAL_PLANS =
    'Provisioning DNS (Incomplete: Missing Dial Plans)';
export const NO_REGIONS = 'Provisioning DNS (Incomplete: Missing Regions)';
export const APP_AUTH_ACCESS_REQUIRED =
    'Graph API access requested. No credentials to sync with';
export const CALLING_DISABLED =
    'Calling Disabled due to expired Graph API failures. Please Grant Access to restore';

const ERROR_TOOLTIP = 'Please contact support for assistance.';

export const statusBaseConfig = {
    menu: {
        EditOnly: {
            showEdit: true,
            grantAccess: false,
            showTenantId: false,
        },
        EditAndAccess: {
            showEdit: true,
            grantAccess: true,
            showTenantId: false,
        },
    },
    actions: {
        GrantAccess: {
            actionIcon: 'Settings',
            actionIconTooltip: 'Grant Access',
        },
        ResetServiceAdmin: {
            actionIcon: 'Settings',
            actionIconTooltip: 'Reset Service Admin',
        },
        SyncSetupGuide: {
            actionIcon: 'ExternalLink',
            actionIconTooltip: 'Sync Setup Guide',
        },
        EditService: {
            actionIcon: 'Edit',
            actionIconTooltip: 'Edit Service',
        },
        None: {
            actionIcon: null,
            actionIconTooltip: null,
        },
    },
};

export const APP_AUTH = 'AppAuth';
export const OPERATOR_CONNECT = 'OperatorConnect';
export const DIRECT_ROUTING = 'DirectRouting';
export const ZOOM = 'ZoomPEX';
export const statusDisplayExtendMapping = {
    [ACCESS_MICROSOFT_FAILED]: {
        ...statusBaseConfig.actions.GrantAccess,
        ...statusBaseConfig.menu.EditAndAccess,
    },
    [DISABLED_DELETE_USER]: {
        ...statusBaseConfig.actions.ResetServiceAdmin,
        ...statusBaseConfig.menu.EditOnly,
    },
    [INVALID_USERNAME_PASSWORD]: {
        ...statusBaseConfig.actions.ResetServiceAdmin,
        ...statusBaseConfig.menu.EditOnly,
    },
    [MFA_REQUIREMENT]: {
        ...statusBaseConfig.actions.SyncSetupGuide,
        ...statusBaseConfig.menu.EditOnly,
        link: '/Guides/MFA_for_TCAP.pdf',
    },
    [CONDITIONAL_ACCESS_REQUIREMENT]: {
        ...statusBaseConfig.actions.SyncSetupGuide,
        ...statusBaseConfig.menu.EditOnly,
        link: '/Guides/MFA_for_TCAP.pdf',
    },
    [EXPIRED_PASSWORD]: {
        ...statusBaseConfig.actions.ResetServiceAdmin,
        ...statusBaseConfig.menu.EditOnly,
    },
};

export const appAuthData = {
    statusId: {
        0: {
            Healthy: {
                color: 'green',
                animation: false,
                tooltip: null,
                tooltipIcon: null,
                ...statusBaseConfig.actions.None,
                ...statusBaseConfig.menu.EditOnly,
            },
            statusLabels: ['Healthy'],
        },
        2: {
            'Access Required': {
                color: 'yellow',
                animation: false,
                tooltip: 'Please Grant Access to the service',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            statusLabels: ['Access Required'],
        },
        4: {
            Initializing: {
                color: 'green',
                animation: true,
                tooltip: 'Confirming Access. Please wait',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                ...statusBaseConfig.menu.EditOnly,
            },
            statusLabels: ['Initializing'],
        },
        5: {
            Reinitializing: {
                color: 'green',
                animation: true,
                tooltip:
                    'Service is currently re-establishing access. Please wait',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                ...statusBaseConfig.menu.EditOnly,
            },
            statusLabels: ['Reinitializing'],
        },
        20: {
            Degraded: {
                color: 'yellow',
                animation: false,
                tooltip: null, // statusDisplayExtended gonna display tooltip
                tooltipIcon: 'Info',
                //statusDisplayExtendMapping -- special case
            },
            Error: {
                color: 'red',
                animation: false,
                tooltip: ERROR_TOOLTIP,
                tooltipIcon: 'Warning',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            statusLabels: ['Degraded', 'Error'],
        },
        21: {
            Degraded: {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Service cannot connect. Please Grant Access. Calling may be disabled if this issue is not resolved',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            statusLabels: ['Degraded'],
        },
        '-1': {
            'Service Account Error': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Service Account issue detected. This may be impacting some service features. Please refer to the Sync Setup guide to restore access',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.SyncSetupGuide,
                ...statusBaseConfig.menu.EditAndAccess,
                link: '/Guides/MFA_for_TCAP.pdf',
            },
            'Calling Disabled': {
                color: 'yellow',
                animation: false,
                tooltip: CALLING_DISABLED,
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            // when app auth status is 5 to superseed calling disabled
            Reinitializing: {
                color: 'green',
                animation: true,
                tooltip:
                    'Service is currently re-establishing access. Please wait',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                ...statusBaseConfig.menu.EditOnly,
            },
            statusLabels: [
                'Service Account Error',
                'Calling Disabled',
                'Reinitializing',
            ],
        },
    },
    statusIds: [0, 2, 4, 5, 20, 21],
};

export const menuAppAuthMapping = {
    NoAppAuth: {
        ...statusBaseConfig.menu.EditAndAccess,
    },
    HasAppAuth: {
        ...statusBaseConfig.menu.EditOnly,
    },
};

export const operatorConnectData = {
    statusId: {
        0: {
            Healthy: {
                color: 'green',
                animation: false,
                tooltip: null,
                tooltipIcon: null,
                ...statusBaseConfig.actions.None,
                // menuAppAuthMapping -- special case
            },
            statusLabels: ['Healthy'],
        },
        2: {
            'Access Required': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Operator Connect is not configured as access has not been provided. Please Grant Access or provide a Tenant ID',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.GrantAccess,
                showEdit: true,
                grantAccess: true,
                showTenantId: true,
            },
            statusLabels: ['Access Required'],
        },
        20: {
            Error: {
                color: 'red',
                animation: false,
                tooltip: ERROR_TOOLTIP,
                tooltipIcon: 'Warning',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            statusLabels: ['Error'],
        },
        '-1': {
            'Calling Disabled': {
                color: 'red',
                animation: false,
                tooltip: CALLING_DISABLED,
                tooltipIcon: 'Warning',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            statusLabels: ['Calling Disabled'],
        },
    },
    statusIds: [0, 2, 20],
};

export const directRoutingData = {
    statusId: {
        0: {
            Healthy: {
                color: 'green',
                animation: false,
                tooltip: null,
                tooltipIcon: null,
                ...statusBaseConfig.actions.None,
                ...statusBaseConfig.menu.EditOnly,
            },
            statusLabels: ['Healthy'],
        },
        2: {
            'Confirming Access': {
                color: 'green',
                animation: true,
                tooltip: 'Checking initial Graph API access',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                ...statusBaseConfig.menu.EditOnly,
            },
            'Access Required': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Direct Routing cannot be configured as Sync permissions have not been provided. Please Activate Sync',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            'Approval Required': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Services will not provision until your company has been approved. Please contact your partner for more information',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.None,
                ...statusBaseConfig.menu.EditOnly,
            },
            statusLabels: [
                'Confirming Access',
                'Access Required',
                'Approval Required',
            ],
        },
        4: {
            Initializing: {
                color: 'green',
                animation: true,
                tooltip: 'Pairing with Teams. Please wait',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                ...statusBaseConfig.menu.EditOnly,
            },
            statusLabels: ['Initializing'],
        },
        10: {
            'Provisioning DNS': {
                color: 'green',
                animation: true,
                tooltip:
                    'Direct Routing domains are being created and validated. Please wait',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                showEdit: false,
                grantAccess: false,
                showTenantId: false,
            },
            'Region not set': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Direct Routing requires at least one Region to be assigned to the service.  Please edit service to continue',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.EditService,
                ...statusBaseConfig.menu.EditOnly,
            },
            statusLabels: ['Provisioning DNS', 'Region not set'],
        },
        11: {
            'No Voice Policies': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Direct Routing requires at least one Voice Policy to be assigned to the service.  Please edit service to continue',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.EditService,
                ...statusBaseConfig.menu.EditOnly,
            },
            'No Dial Plans': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Direct Routing requires at least one Dial Plan to be assigned to the service.  Please edit service to continue',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.EditService,
                ...statusBaseConfig.menu.EditOnly,
            },
            'Provisioning SBC': {
                color: 'green',
                animation: true,
                tooltip:
                    'SBC is being provisioned for Direct Routing.  Please wait',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                showEdit: false,
                grantAccess: false,
                showTenantId: false,
            },
            statusLabels: [
                'No Voice Policies',
                'No Dial Plans',
                'Provisioning SBC',
            ],
        },
        12: {
            'No Voice Policies': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Direct Routing requires at least one Voice Policy to be assigned to the service.  Please edit service to continue',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.EditService,
                ...statusBaseConfig.menu.EditOnly,
            },
            'No Dial Plans': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Direct Routing requires at least one Dial Plan to be assigned to the service.  Please edit service to continue',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.EditService,
                ...statusBaseConfig.menu.EditOnly,
            },
            'Activating Teams Phone': {
                color: 'green',
                animation: true,
                tooltip:
                    'Enabling Enterprise Voice on new domains. Please wait',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                showEdit: true,
                grantAccess: false,
                showTenantId: false,
            },
            statusLabels: [
                'No Voice Policies',
                'No Dial Plans',
                'Activating Teams Phone',
            ],
        },
        13: {
            'No Voice Policies': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Direct Routing requires at least one Voice Routing Policy to be assigned to the service.  Please edit service to continue',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.EditService,
                ...statusBaseConfig.menu.EditOnly,
            },
            'No Dial Plans': {
                color: 'yellow',
                animation: false,
                tooltip:
                    'Direct Routing requires at least one Dial Plan to be assigned to the service.  Please edit service to continue',
                tooltipIcon: 'Info',
                ...statusBaseConfig.actions.EditService,
                ...statusBaseConfig.menu.EditOnly,
            },
            'Provisioning Teams Phone': {
                color: 'green',
                animation: true,
                tooltip: 'Creating Voice Routing Policies and Dial Plans',
                tooltipIcon: 'Spinner',
                ...statusBaseConfig.actions.None,
                showEdit: true,
                grantAccess: false,
                showTenantId: false,
            },
            statusLabels: [
                'No Voice Policies',
                'No Dial Plans',
                'Provisioning Teams Phone',
            ],
        },
        20: {
            Error: {
                color: 'red',
                animation: false,
                tooltip: ERROR_TOOLTIP,
                tooltipIcon: 'Warning',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            statusLabels: ['Error'],
        },
        '-1': {
            'Calling Disabled': {
                color: 'red',
                animation: false,
                tooltip: CALLING_DISABLED,
                tooltipIcon: 'Warning',
                ...statusBaseConfig.actions.GrantAccess,
                ...statusBaseConfig.menu.EditAndAccess,
            },
            statusLabels: ['Calling Disabled'],
        },
    },
    statusIds: [0, 2, 3, 4, 10, 11, 12, 13, 20],
};
export const initServiceData = {
    services: {
        [APP_AUTH]: appAuthData,
        [OPERATOR_CONNECT]: operatorConnectData,
        [DIRECT_ROUTING]: directRoutingData,
    },
    serviceTypes: [APP_AUTH, OPERATOR_CONNECT, DIRECT_ROUTING],
};

export const STATUS_CHANGE = 'STATUS_CHANGE';
export const changed_status = 'changed_status';
export const loaded_status = 'loaded_status';
