export default {
    permissions: {
        ufCompany: '/permissions/input/companyInput',
        ufReadOnly: '/permissions/switch/readOnly',
        ufOptOut: '/permissions/checkbox/optOut',
        ufPermissionSet: '/permissions/input/permissionSet',
        ufDeleteBtn: '/permissions/button/deletePermission',
        ufSaveBtn: '/permissions/permissionsTab/button/saveBtn',
        ufIsActive: '/permissions/switch/isActive',
        ufBackBtn: '/permissions/button/backBtn',
        ufCopyInput: '/permissions/input/copyInput',
    },
};
