import { getVgs, getVg } from '@/API/VisibilityGroups/';

const getVisibilityGroups = async () => {
    try {
        const res = await getVgs();
        if (res.status !== 200) {
            throw new Error(
                res.message || 'This feature is not enabled for your profile.',
            );
        }
        return res.data;
    } catch (error) {
        console.error('Error fetching visibility groups: ', error);
        throw new Error(error?.message);
    }
};

export const visibilityGroupsQuery = () => ({
    queryKey: ['visibilityGroups'],
    queryFn: async () => getVisibilityGroups(),
});

export const loader = (queryClient) => async () => {
    const query = visibilityGroupsQuery();

    return (
        queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query))
    );
};

const getVisibilityGroup = async (vgId) => {
    try {
        const res = await getVg(vgId);
        if (res.status !== 200) {
            throw new Error(
                res.message || 'This feature is not enabled for your profile.',
            );
        }
        return res.data;
    } catch (error) {
        console.error('Error fetching visibility group: ', error);
        throw new Error(error?.message);
    }
};

export const vgMembersQuery = (vgId) => ({
    queryKey: ['visibilityGroup', vgId],
    queryFn: async () => getVisibilityGroup(vgId),
});

export const vgMembersLoader =
    (queryClient) =>
    async ({ params }) => {
        const query = vgMembersQuery(params.vgId);

        return (
            queryClient.getQueryData(query.queryKey) ??
            (await queryClient.fetchQuery(query))
        );
    };
