import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { Box } from '@chakra-ui/react';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useBoolean } from '@chakra-ui/react';

import TableAndModal, { floatSearch } from 'components/tables/TableAndModal';
import NumberFormatCustom, {
    NonNegativeInteger,
} from 'components/maintenance/NumberFormatCustom';
import config from '@/config.json';
import DeleteConfirmation, { DeleteButton } from './DeleteConfirmation';
import { AltTooltip } from 'components/v4/Tooltip/AltTooltip';

import walkthroughIds from '../walkthroughIds';
import path from '@/routes/constants';

/**
 * Component to render the Calling Tab of the Plans component
 */
const Calling = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [hover, setHover] = useBoolean();

    // modal data from state
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // table data from state
    const { child } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    const { callingRates } = walkthroughIds;

    // default columns from state
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
        },
        {
            title: 'Priority',
            field: 'priority',
            doNotOverrideSort: true,

            defaultSort: 'asc',
        },

        {
            title: 'Prefix',
            field: 'prefix',
        },
        {
            title: 'Flag Fall In Rate',
            field: 'flagFallInRate',
            doNotOverrideSort: true,
            render: (rowData) => {
                return (
                    <NumberFormatCustom
                        displayType="text"
                        value={rowData['flagFallInRate']}
                    />
                );
            },
            customFilterAndSearch: (v, rowData) =>
                floatSearch(v, rowData, 'flagFallInRate'),
        },
        {
            title: 'Flag Fall Out Rate',
            field: 'flagFallOutRate',
            doNotOverrideSort: true,
            render: (rowData) => {
                return (
                    <NumberFormatCustom
                        displayType="text"
                        value={rowData['flagFallOutRate']}
                    />
                );
            },
            customFilterAndSearch: (v, rowData) =>
                floatSearch(v, rowData, 'flagFallOutRate'),
        },
        {
            title: 'Inbound Rate',
            field: 'inboundRate',
            doNotOverrideSort: true,
            render: (rowData) => {
                return (
                    <NumberFormatCustom
                        displayType="text"
                        value={rowData['inboundRate']}
                    />
                );
            },
            customFilterAndSearch: (v, rowData) =>
                floatSearch(v, rowData, 'inboundRate'),
        },
        {
            title: 'Outbound Rate',
            field: 'outboundRate',
            doNotOverrideSort: true,
            render: (rowData) => {
                return (
                    <NumberFormatCustom
                        displayType="text"
                        value={rowData['outboundRate']}
                    />
                );
            },
            customFilterAndSearch: (v, rowData) =>
                floatSearch(v, rowData, 'outboundRate'),
        },
    ];

    // render table and modal with the modal content
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/rates/' + child?.id}
            modalFetch={'/rate/' + modalData?.id}
            modalAdd="/rate"
            modalUpdate="/rate/"
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                    planID: child?.id,
                };
            }}
            // title="Calling Rates"
            titleProps={
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    className="gap-4">
                    {/* TODO: Q4 2024 release feature, remove hidden class when it's ready */}
                    <Button
                        className="btn btn-primary btn-sm md:min-w-[110px]"
                        onClick={() => {
                            const urlPathname = `/plans/${path.CSVCALLINGRATES}?plan=${encodeURIComponent(
                                child?.name?.replace(/ /g, '_'),
                            )}&planID=${encodeURIComponent(child?.id)}`;
                            dispatch({
                                type: 'UPDATE_HISTORY',
                                payload: {
                                    pathname: urlPathname,
                                },
                            });
                            navigate(urlPathname?.split('&')[0]);
                        }}>
                        CSV Update
                    </Button>
                    <Button
                        className="btn btn-primary btn-sm md:min-w-[110px]"
                        onClick={() => {
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    state: {
                                        isActive: true,
                                        planID: child?.id,
                                    },
                                    loading: false,
                                    mode: 'Add',
                                    hash: '',
                                },
                            });
                            dispatch({
                                type: 'CHANGE_MODAL_HASH',
                            });
                        }}>
                        {' '}
                        <AddRoundedIcon
                            fontSize="small"
                            style={{ marginBottom: '2%' }}
                        />{' '}
                        Calling Rates{' '}
                    </Button>
                </Box>
            }
            defaultColumns={defaultColumns}
            // add
            noTitle
            title="Calling Rates"
            groupable
            filterable
            rowActions={{
                editable: true,
            }}
            modalInitState={{
                isActive: true,
            }}
            maxBodyHeight={
                window.innerHeight * config.breadcrumbView.tableHeight
            }
            newPage
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                    prefix:
                        (!modalState?.prefix ||
                            modalState?.prefix?.length < 1) &&
                        'Field is required.',
                    priority:
                        (!modalState?.priority ||
                            modalState?.priority?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });

                return !(
                    newErrorState.name ||
                    newErrorState.prefix ||
                    newErrorState.priority
                );
            }}
            modalButtons={
                modalMode === 'Edit' && (
                    <>
                        <Button
                            variant="primary"
                            onClick={() => {
                                dispatch({
                                    type: 'ON_MODAL_SUBMIT',
                                    payload: true,
                                });
                            }}
                            className="mr-[14px]">
                            Save
                        </Button>
                        <DeleteButton />
                    </>
                )
            }
            modalContent={
                <>
                    <DeleteConfirmation
                        show={modalState?.showPrompt}
                        mode="Calling Rate"
                    />
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                fullWidth
                                id="Name"
                                label="Name"
                                type="text"
                                value={modalState?.name}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            name: e.target.value,
                                        },
                                    });
                                }}
                                error={Boolean(modalStateError?.name)}
                                helperText={modalStateError?.name}
                                data-walkthroughid={callingRates.ufNameInput}
                            />
                        </Col>
                        <Col>
                            <TextField
                                label="Priority"
                                value={modalState?.priority}
                                //  type="number"
                                InputProps={{
                                    inputComponent: NonNegativeInteger,
                                }}
                                error={Boolean(modalStateError?.priority)}
                                helperText={modalStateError?.priority}
                                fullWidth
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            priority: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    callingRates.ufPriorityInput
                                }
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Prefix"
                                label="Prefix"
                                onMouseEnter={setHover.on}
                                onMouseLeave={setHover.off}
                                error={Boolean(modalStateError?.prefix)}
                                helperText={modalStateError?.prefix}
                                value={modalState?.prefix}
                                InputProps={{
                                    endAdornment: (
                                        <AltTooltip
                                            label="Prefix Example: 61 to match numbers starting in 61, 61[23] matches numbers starting in 612 or 613"
                                            show={hover}
                                        />
                                    ),
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            prefix: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={callingRates.ufPrefixInput}
                            />
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                label="Flag Fall In Rate"
                                //  //type="number"
                                value={modalState?.flagFallInRate}
                                error={Boolean(modalStateError?.flagFallInRate)}
                                helperText={modalStateError?.flagFallInRate}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                fullWidth
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            flagFallInRate: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    callingRates.ufFlagFallInRateInput
                                }
                            />
                        </Col>
                        <Col>
                            <TextField
                                label="Flag Fall Out Rate"
                                value={modalState?.flagFallOutRate}
                                error={Boolean(
                                    modalStateError?.flagFallOutRate,
                                )}
                                helperText={modalStateError?.flagFallOutRate}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                fullWidth
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            flagFallOutRate: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    callingRates.ufFlagFallOutRateInput
                                }
                            />
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                label="Inbound Rate"
                                value={modalState?.inboundRate}
                                error={Boolean(modalStateError?.inboundRate)}
                                helperText={modalStateError?.inboundRate}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                fullWidth
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            inboundRate: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    callingRates.ufInboundRateInput
                                }
                            />
                        </Col>
                        <Col>
                            <TextField
                                label="Outbound Rate"
                                value={modalState?.outboundRate}
                                error={Boolean(modalStateError?.outboundRate)}
                                helperText={modalStateError?.outboundRate}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                fullWidth
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            outboundRate: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    callingRates.ufOutboundRateInput
                                }
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default Calling;
