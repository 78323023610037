import { useState } from 'react';
import { FormControl, Stack, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import NameField from '@/components/forms/new/formikFormFields/NameField';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../../../walkthroughIds';

const AdminUsersFirstName = ({ errors, touched, darkMode }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            isInvalid={!!errors.firstName && touched.firstName}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack direction={'row'} spacing={stackSpacing} sx={stackStyling()}>
                <FormLabel sx={formLabelStyling(darkMode)}>
                    First Name <span style={{ color: '#e53e3e' }}>*</span>
                </FormLabel>
                <Field
                    component={NameField}
                    walkthroughid={walkthroughIds.adminsGeneral.ufFirstName}
                    id="firstName"
                    name="firstName"
                    validate={(value) => {
                        if (!value || value === '') {
                            return 'First name is required';
                        }
                    }}
                />
                {isHovered && (
                    <FieldToolTip
                        label="Enter Admin User's first name"
                        ariaLabel="admin-user-first-name-tip"
                        left="25px"
                        top="25px"
                    />
                )}
            </Stack>
        </FormControl>
    );
};

export default AdminUsersFirstName;
