import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import useDetectFileType from 'hooks/useDetectFileType';

/*
 * Header component to get favicon and company name from state
 */
const Header = () => {
    // const dispatch = useDispatch();

    const { partnerName, favIconPath } = useSelector((state) => {
        return { ...state.header };
    });

    const imgFileType = useDetectFileType(favIconPath);

    // useEffect(() => {
    //     if (!partnerName || !favIconPath) {
    //         axios.get(`/theme/${window.location.host}`).then((res) => {
    //             const { data } = res;
    //             dispatch({
    //                 type: 'SET_SBC_DOMAIN',
    //                 payload: data.sbcDomain,
    //             });
    //             dispatch({
    //                 type: 'CHANGE_IMAGE_PATH_LIGHT_MODE',
    //                 payload: data.lightLogo,
    //             });
    //             dispatch({
    //                 type: 'CHANGE_IMAGE_PATH_DARK_MODE',
    //                 payload: data.darkLogo,
    //             });

    //             dispatch({
    //                 type: 'HEADER_FAVICON_SUCCESS',
    //                 payload: data.favicon,
    //             });
    //             dispatch({
    //                 type: 'HEADER_TITLE_SUCCESS',
    //                 payload: data.titleName,
    //             });
    //             dispatch({
    //                 type: 'SET_SUPPORT_URL',
    //                 payload: data.extLinkSupport,
    //             });
    //         });
    //     }
    // }, [partnerName, favIconPath]);

    //returns helmet with image and partner name
    return (
        <Helmet>
            {/**
             * @note CSP frame-ancestors
             * @see https://content-security-policy.com/frame-ancestors/
             */}
            <meta
                httpEquiv="Content-Security-Policy"
                content={`
                    frame-src 'self' ${
                        import.meta.env.VITE_APP_IMAGE_BASE
                    } https://www.google.com/recaptcha/ youtube.com https://ytimg.com https://www.youtube.com;
                  `}
            />
            <link
                rel="icon"
                href={import.meta.env.VITE_APP_IMAGE_BASE + favIconPath}
                type={`image/${imgFileType}`}
                className="favicon"
            />
            <title>{partnerName}</title>
        </Helmet>
    );
};

export default Header;
