/*eslint-disable no-unused-vars */
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Info from '@mui/icons-material/Info';
import { Icon } from '@chakra-ui/react';
import { Box, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { axios } from '@/services/axios';
import { TableAndModal, TabPanel } from 'components';
import { CustomFieldsForm } from 'pages/organisations';
import { renderTelephoneNumber } from 'utils/utils';
import GeneralForm from './NumbersEdit';
import config from '@/config.json';
import { Box as ChakraBox, ChakraProvider } from '@chakra-ui/react';
import useAddress from 'hooks/useAddress';
import { useDeepMerge } from 'hooks/useDeepMerge';
import Services from 'pages/accounts/users/services/Services';
import { ServicesProvider } from 'pages/accounts/users/services/ServicesContext';
import walkthroughIds from '../walkthroughIds';
import path from '@/routes/constants';
import { IoMdInformationCircleOutline } from 'react-icons/io';
// shows a 'Information' Icon that shows the title/message when you hover over it
export const Message = (props) => {
    return (
        <Tooltip arrow title={props.title} aria-label="statusTooltip">
            <Icon
                width={30}
                height={30}
                top={1}
                left={1}
                color={props.darkMode ? 'white' : undefined}
                position="relative">
                <IoMdInformationCircleOutline />
            </Icon>
        </Tooltip>
    );
};

/*
 * Numbers Tab of the Numbers component from the sidebar
 * Handles displaying and editing the number
 */
const NumberTable = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const buttonCtrRef = useRef(0); // min 0
    const { theme } = useDeepMerge();
    const { pathname } = useLocation();
    const { CSVNUMBERS } = path;

    const { darkMode } = useSelector((state) => state.settings);
    // state access
    const { modalData, modalState } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
            modalLoadFields: state.modal.loadFields,
            modalShow: state.modal.show,
        };
    });

    const { state: tableState } = useSelector((state) => {
        return { ...state.table };
    });
    const { currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    const {
        currentCompany,
        currentWholesaler,
        currentPartner,
        currentCompanyData,
    } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { modalTab } = useSelector((state) => {
        return { ...state.view };
    });

    const [, setShowLoading] = useState(false);
    const [, setActionedErrors] = useState(false);
    const [addresses] = useState([
        {
            id: null,
            description: 'Company Default',
        },
    ]);
    const [lookupAddress, setLookupAddress] = useState(addresses[0]);
    const { concatAddressFields } = useAddress();

    // default columns for table and modal
    const defaultColumns = [
        {
            title: 'Telephone Number',
            field: 'telephoneNumber',
            render: (rowData) =>
                renderTelephoneNumber(rowData, 'telephoneNumber'),
        },

        {
            title: 'Outbound Caller ID',
            field: 'cliPresentation',
            render: (rowData) =>
                renderTelephoneNumber(rowData, 'cliPresentation'),
        },
        {
            title: 'Overstamp Number',
            field: 'overstampNumber',
            render: (rowData) =>
                renderTelephoneNumber(rowData, 'overstampNumber'),
            hiddenByColumnsButton: true,
            hidden: true,
        },
        {
            title: 'Last Updated',
            field: 'lastUpdateTS',
            type: 'datetime',
            width: '10%',
            maxWidth: '60%',
        },
        {
            title: 'Wholesaler',
            field: 'wholesalerName',
            hidden: currentScope < 80,
        },
        {
            title: 'Partner',
            field: 'partnerName',
            hidden: currentScope < 60,
        },
        {
            title: 'Company',
            field: 'companyName',
            hidden: currentScope < 40,
        },

        {
            title: 'User',
            field: 'userName',
            width: '15%',
        },
        {
            title: 'Trunk',
            field: 'trunkName',
        },
        // NOTE: for v3.38.2 when number table is enhanced
        // {
        //     title: 'Address',
        //     field: 'addressID',
        //     hiddenByColumnsButton: true,
        //     hidden: true,
        //     render: (rowData) => {
        //         if (rowData?.addressID) {
        //             return <span>{lookupAddress.get(rowData.addressID)}</span>;
        //         }
        //         return console.log("Table doesn't have addressID");
        //     },
        // },
        {
            title: 'Status',
            field: 'status',
            render: (rowData) => {
                return (
                    <Box display="flex" zIndex={1} position="relative">
                        <span>
                            {rowData?.operatorMessage ? (
                                <>
                                    {
                                        config.numbers.operatorStatusJSON[
                                            rowData.operatorStatus
                                        ]
                                    }
                                    <Message
                                        title={`Operator Message: ${rowData?.operatorMessage}`}
                                        darkMode={darkMode}
                                    />
                                </>
                            ) : (
                                <>
                                    {config.numbers.statusJSON[rowData.status]}
                                    &nbsp;
                                    {rowData?.engineMessage &&
                                        rowData?.engineMessage?.length > 0 && (
                                            <Message
                                                title={rowData.engineMessage}
                                                darkMode={darkMode}
                                            />
                                        )}
                                </>
                            )}
                        </span>
                    </Box>
                );
            },
            lookup: config.numbers.statusJSON,
        },
        {
            title: 'Calling Enabled',
            field: 'isEnabled',
            hiddenByColumnsButton: true,
            hidden: true,
            type: 'boolean',
        },
        ...config.table.custom,
    ];

    // NOTE: for v3.38.2 when number table is enhanced
    // useEffect(() => {
    //     axios.get(`/addresses/${currentCompany}`).then((response) => {
    //         const addressesConcated = response.data.map((address) => {
    //             const addressConcated = concatAddressFields(address);
    //             if (address?.description.length) {
    //                 return {
    //                     ...address,
    //                     description: `${address.description} - ${addressConcated}`,
    //                 };
    //             }
    //             return {
    //                 ...address,
    //                 description: addressConcated,
    //             };
    //         });
    //         const addressMap = new Map(
    //             addressesConcated.map((address) => [
    //                 address.id,
    //                 address.description,
    //             ]),
    //         );
    //         setLookupAddress(addressMap);
    //         setAddresses([...addresses, ...addressesConcated]);
    //     });
    // }, []);

    // function for fetching data for the table and modal
    const tableFetch = () => {
        switch (currentScope) {
            case 80:
                return '/numbers/list';
            case 60:
                return `/numbers/list?WholesalerID=${currentWholesaler}`;
            case 40:
                return `/partner/${currentPartner}/numbers/list`;
            default:
                return `/company/${currentCompany}/numbers/list`;
        }
    };

    // return table and modal with the tabs required for numbers - general and custom fields form
    return (
        <TableAndModal
            tableFetch={tableFetch()}
            modalFetch={'/number/' + modalData?.id}
            size={'md'}
            modalUpdate={'/number/'}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title={config.numbers.tableTitle}
            titleProps={
                currentScope === 20 && (
                    <Button
                        data-walkthroughid={walkthroughIds.ufCsvUpdateBtn}
                        className="btn  btn-sm"
                        variant="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            navigate(`${pathname}/${CSVNUMBERS}`);
                        }}>
                        {'CSV Update'}
                    </Button>
                )
            }
            defaultColumns={defaultColumns}
            groupable
            filterable
            rowActions={{
                conditionalEditable: {
                    disabled: (rowData) => !rowData?.canEdit,
                },
            }}
            maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
            modalTitle={`Edit Number : +${modalData?.telephoneNumber}`}
            newPage
            overrideOnRowSelected={(e, path, dataClicked) => {
                const isAdding = e.target.checked;
                const newVal = isAdding
                    ? buttonCtrRef.current + 1
                    : buttonCtrRef.current - 1;
                const newPos = newVal > 0 ? 'left' : 'right';
                buttonCtrRef.current = newVal;
            }}
            onTableSucceed={(data) => {
                setShowLoading(false);
                setActionedErrors(false);
                buttonCtrRef.current = 0;
                return data.map(({ status, isEnabled, ...rest }) => ({
                    status:
                        // 'Disabled' custom status and added to the status config
                        !isEnabled && status === 0 ? 'Disabled' : status,
                    isEnabled,
                    ...rest,
                }));
            }}
            dynamicScope
            additionalActions={[
                {
                    icon: ({ disabled }) => {
                        return (
                            <Button
                                className="btn  btn-sm"
                                style={{ marginBottom: '4px' }}
                                variant="primary">
                                Actioned {`(${buttonCtrRef.current})`}
                            </Button>
                        );
                    },
                    tooltip: 'Done',
                    position: 'toolbarOnSelect',
                    onClick: (event, data) => {
                        setShowLoading(true);
                        setActionedErrors(false);
                        const actionedResponses = data.map((v) =>
                            axios.post(`/Number/${v.id}/Actioned`),
                        );
                        Promise.all(actionedResponses)
                            .then((res) => {
                                const allData = res.map((v) => ({
                                    ...v.data,
                                    status:
                                        !v.data.isEnabled && v.data.status === 0
                                            ? 'Disabled'
                                            : v.data.status,
                                }));
                                setTimeout(() => {
                                    setShowLoading(false);
                                });
                                var newTableData = [...tableState.data].map(
                                    (old) => {
                                        const newData = allData.find(
                                            (v) => v.id === old.id,
                                        );
                                        return newData || old;
                                    },
                                );
                                buttonCtrRef.current = 0;
                                dispatch({
                                    type: 'CHANGE_TABLE_STATE',
                                    payload: {
                                        ...tableState,
                                        data: newTableData,
                                    },
                                });
                            })
                            .catch((e) => {
                                console.error('Failed!', { e });
                                setTimeout(() => {
                                    setActionedErrors(e);
                                });
                            });
                    },
                },
            ]}
            modalValidate={() => {
                const newErrorState = {
                    operatorConnectCallingProfileID:
                        modalState?.trunkType === 'MS Operator Connect' &&
                        !modalState?.operatorConnectCallingProfileID,
                    operatorConnectUsageID:
                        modalState?.trunkType === 'MS Operator Connect' &&
                        !modalState?.operatorConnectUsageID,
                    attribute1:
                        modalState?.attribute1?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                    attribute2:
                        modalState?.attribute2?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                    attribute3:
                        modalState?.attribute3?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(
                    newErrorState.operatorConnectCallingProfileID ||
                    newErrorState.operatorConnectUsageID ||
                    newErrorState.attribute1 ||
                    newErrorState.attribute2 ||
                    newErrorState.attribute3
                );
            }}
            noTitle
            modalContent={
                <>
                    <Tabs
                        value={modalTab}
                        onChange={(e, i) => {
                            dispatch({
                                type: 'CHANGE_MODAL_TAB_VIEW',
                                payload: i,
                            });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        aria-label="full width tabs example">
                        <Tab
                            label="General"
                            data-walkthroughid={`${walkthroughIds.editNumbersGeneral.tab}/general`}
                        />
                        <Tab
                            label="Custom"
                            data-walkthroughid={`${walkthroughIds.editNumbersGeneral.tab}/custom`}
                        />
                        {currentCompanyData?.vendorService && (
                            <Tab
                                label="Services"
                                data-walkthroughid={`${walkthroughIds.editNumbersGeneral.tab}/services`}
                            />
                        )}
                    </Tabs>
                    <TabPanel value={modalTab} index={0}>
                        <GeneralForm />
                    </TabPanel>
                    <TabPanel value={modalTab} index={1}>
                        <CustomFieldsForm />
                    </TabPanel>
                    <TabPanel value={modalTab} index={2}>
                        <ChakraProvider resetCSS={true} theme={theme}>
                            <ChakraBox mt="1%">
                                <ServicesProvider>
                                    <Services numberID={modalData?.id} />
                                </ServicesProvider>
                            </ChakraBox>
                        </ChakraProvider>
                    </TabPanel>
                </>
            }
            selectionProps={(rowData) => ({
                hidden: rowData.status !== 7 || rowData.overStampNumber,
                color: 'primary',
            })}
            noSaveButton={modalTab == 2}
            showSelectAllCheckbox={false}
            selection
            tableRef={tableRef}
        />
    );
};

export default NumberTable;
