import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@/components/v4';
import { Button } from '@chakra-ui/react';

export default function DeletePermissionsSetModal({
    isOpen,
    onClose,
    deleteOnClick,
    finalRef,
}) {
    return (
        <Modal
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Permission Sets</ModalHeader>
                <ModalBody>
                    Are you sure you would like to delete this Permission Set?
                    Before deleting, please ensure that this Permission Set is
                    not in use.
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant="ghost"
                        bg="transparent"
                        mr={3}
                        colorScheme="black"
                        onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        variant="solid"
                        colorScheme="red"
                        onClick={deleteOnClick}>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
