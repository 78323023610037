import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { toSentenceCase } from 'utils/utils';
import { Box, Tooltip, forwardRef } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import Button from 'components/v4/Button';
import Input from 'components/v4/Input';
import FormLabel from 'components/v4/FormLabel';
import updateServiceProfiles from 'API/ServiceProfiles/updateServiceProfiles';
import createServiceProfile from 'API/ServiceProfiles/createServiceProfile';

import { Select as _Select } from 'chakra-react-select';
// import Select from 'components/v4/Select';
import style from './styles';
import FooterContainer from './FooterContainer';
import Alert from 'components/v4/Alert';
import AlertDescription from 'components/v4/AlertDescription';
import AlertIcon from 'components/v4/AlertIcon';
import Heading from 'components/v4/Heading';
import LoadingIndicator from 'components/v4/LoadingIndicator';
import { validationSchemaTPM } from './schema';
import useFieldValidation from 'hooks/useFieldValidation';
import FieldValidation from 'components/v4/FieldValidation';

import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';

import walkthroughIds from '../../walkthroughIds';
import config from '@/config.json';

const Select = forwardRef((props, ref) => (
    <_Select selectedOptionColor="brand" ref={ref} {...props} />
));
const Zoom = ({ mode, data, handleBackButton, level, visibleScopeOptions }) => {
    const {
        currentWholesaler: wholesalerID,
        currentPartner: partnerID,
        companiesAndPartners,
        wholesalers,
    } = useSelector((state) => state.navigationLists);
    const { requiredScope } = useSelector((state) => state.login);
    const { darkMode } = useSelector((state) => state.settings);

    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const DEFAULT_ZOOM_PAYLOAD = {
        name: 'Zoom PEX Wholesaler Profile',
        serviceType: 'ZoomPEX',
        partnerID: level === 'partner' ? partnerID : undefined,
        wholesalerID: level === 'partner' ? undefined : wholesalerID,
        visibleScope: level === 'partner' ? 20 : 60,
    };

    const { ...ufElements } = walkthroughIds.serviceProfiles;

    const chakraSelectDarkStyle = chakraSelectDarkMode(darkMode);

    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDarkStyle,
    };
    useEffect(() => {
        if (mode !== 'EDIT' && level !== 'partner' && wholesalers?.length > 0) {
            const wsName = wholesalers.find(
                ({ id }) => id === wholesalerID,
            )?.name;
            handleChangeState({
                name: `${wsName} - Zoom PEX Wholesaler Profile`,
            });
        } else if (
            mode !== 'EDIT' &&
            level === 'partner' &&
            wholesalers?.length > 0
        ) {
            const partnerName = companiesAndPartners.find(
                ({ id }) => id === partnerID,
            )?.name;
            handleChangeState({
                name: `${partnerName} - Zoom PEX Partner Profile`,
            });
        }
    }, [mode, companiesAndPartners, wholesalers, level]);

    const [modalState, setModalState] = useState(data || DEFAULT_ZOOM_PAYLOAD);

    const { validationErrors, validatefn } = useFieldValidation(
        modalState,
        validationSchemaTPM,
    );

    // function to set modal state with a given payload
    const handleChangeState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    const handleSubmit = async () => {
        setError(null);
        //validate
        if (!validatefn()) {
            setError({
                response: {
                    data: {
                        message:
                            'There are some validation errors in the form. Please check and try again.',
                    },
                },
            });
            return;
        }

        setIsLoading(true);
        try {
            if (mode === 'EDIT') {
                await updateServiceProfiles({
                    type: 'Zoom',
                    payload: modalState,
                    level,
                    scope: requiredScope,
                });
                handleBackButton();
            } else {
                await createServiceProfile({
                    type: 'Zoom',
                    payload: modalState,
                    level,
                    scope: requiredScope,
                });
                handleBackButton();
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box sx={{ maxWidth: '2xl', minWidth: 'xl', margin: 'auto' }}>
            <Heading fontSize="3xl" as="h2" sx={{ marginBottom: '10px' }}>
                {`${toSentenceCase(mode)} ${modalState.name || data?.name}`}
            </Heading>
            {error && (
                <Alert status="error" sx={{ marginTop: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}
            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>Service name</FormLabel>
                <Input
                    value={modalState.name}
                    onChange={(e) =>
                        handleChangeState({ name: e.target.value })
                    }
                    sx={style.inputField}
                    data-walkthroughid={ufElements.ufServiceNameInput}
                />
                <FieldValidation errors={validationErrors?.name} />
            </Box>

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>
                    Visible Scope &nbsp;
                    <Tooltip
                        label="Determine visibility and service creation level"
                        hasArrow
                        placement="top-end">
                        <InfoIcon />
                    </Tooltip>
                </FormLabel>
                {modalState.visibleScope <= requiredScope ? (
                    <Select
                        defaultValue={visibleScopeOptions.find(
                            (option) =>
                                option.value === modalState.visibleScope,
                        )}
                        options={visibleScopeOptions}
                        chakraStyles={chakraStyles}
                        onChange={(selectedOption) => {
                            handleChangeState({
                                visibleScope: selectedOption.value,
                            });
                        }}
                        selectedOptionColor="brand"
                    />
                ) : (
                    <Input
                        isDisabled
                        value={config.scope.json[modalState.visibleScope]}
                    />
                )}
            </Box>

            <FooterContainer>
                <Button
                    variant="outline"
                    sx={{ background: 'white' }}
                    w={{ base: 'full', md: '80px' }}
                    onClick={handleBackButton}
                    data-walkthroughid={ufElements.ufCloseButton}>
                    Close
                </Button>
                <Button
                    onClick={handleSubmit}
                    w={{ base: 'full', md: '80px' }}
                    data-walkthroughid={ufElements.ufUpdateButton}>
                    {mode === 'ADD' ? 'Add' : 'Update'}
                </Button>
            </FooterContainer>
        </Box>
    );
};

export default Zoom;
