import {
    VgEdit,
    VisibilityGroupsIndex,
    VisibilityGroupsProvider,
} from '@/pages/organisations/';
// eslint-disable-next-line no-unused-vars
import { loader as vgLoader, vgMembersLoader } from '@tcap/data-router/loaders';

/**
 * @param {import('@tanstack/react-query').QueryClient} [ queryClient ]
 * @returns {import('react-router-dom').RouteObject[]}*/
const VisibilityGroupsRoutes = (queryClient) => [
    {
        Component: VisibilityGroupsProvider,
        children: [
            {
                index: true,
                element: <VisibilityGroupsIndex />,
                loader: vgLoader(queryClient),
            },
            {
                path: ':vgId/edit',
                element: <VgEdit />,
                // loader: vgMembersLoader(queryClient),
            },
        ],
    },
];

export default VisibilityGroupsRoutes;
