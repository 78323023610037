import { axios } from '@/services/axios';

import {
    getCurrentStoreState,
    requiredLoggedIn,
} from '@tcap/data-router/utils/helpers';

const getJobs = async (params) => {
    try {
        const response = await axios.get('jobs', {
            params,
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching jobs: ', error);
    }
};

export const getJobsQuery = (params) => ({
    queryKey: [
        'jobs',
        Object.values(params).filter((param) => Boolean(param))[0],
    ],
    queryFn: () => {
        requiredLoggedIn();
        const jobs = getJobs(params);
        if (!jobs) {
            throw new Response('', { status: 404, statusText: 'Not Found' });
        }
        return jobs;
    },
});

/**
 * @param {import('@tanstack/react-query').QueryClient} queryClient
 */
export const loader = (queryClient) => async () => {
    const currentState = getCurrentStoreState();
    const { currentScope, loggedIn } = currentState.login;
    const { currentWholesaler, currentPartner, currentCompany } =
        currentState.navigationLists;
    const params = {
        wholesalerID: currentScope === 60 ? currentWholesaler : null,
        partnerID: currentScope === 40 ? currentPartner : null,
        companyID: currentScope === 20 ? currentCompany : null,
    };
    const { queryKey, queryFn } = getJobsQuery(params);
    const jobs = loggedIn
        ? await queryClient.ensureQueryData({
              queryKey,
              queryFn,
              staleTime: 1000 * 60 * 2,
          })
        : null;

    return { jobs };
};
