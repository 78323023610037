import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { axios } from 'services/axios';
import {
    TRUNK_USAGE_VALUES,
    FIELDS_TO_EXPORT,
    FIELDS_CHANGE_NEED_VALIDATION,
    FIELDS_CHANGE_NO_NEED_VALIDATION,
    TEMPLATE_FILENAME,
    CSV_TYPE,
    CSV_TYPE_ERROR,
    NUMBERS_DNE_ERROR,
    NUMBER_NOT_OK_ERROR,
    NO_DATA_FOUND_ERROR,
    INVALID_CP_ERROR,
    INVALID_TNU_ERROR,
    TRUNKS_DNE_ERROR,
    ADDRESS_DNE_ERROR,
    OUTBOUND_DNE_ERROR,
    DEFAULT_VERIFICATION_RESULT,
    VERIFICATION_CHECKS,
    IGNORED_MESSAGE,
    SUCCESS_MESSAGE,
    haveErrorsFilter,
    INVALID_FILENAME,
    SHARED_FIELDS_TO_EXPORT,
    ADDRESS_WRONG_ERROR,
} from './constants';
import Papa from 'papaparse';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import partition from 'lodash/partition';
import concat from 'lodash/concat';
import delay from 'lodash/delay';
import differenceBy from 'lodash/differenceBy';
import orderBy from 'lodash/orderBy';

import { downloadCSV } from 'utils/utils';
import useAddress from 'hooks/useAddress';
import { CSV_TYPE_FX } from 'pages/accounts/users/csv/useCSVUsers';
import appConfig from '../../config.json';

export default function useCSVNumbersUpdate() {
    const { currentCompany: companyID } = useSelector(
        (state) => state.navigationLists,
    );
    const [, setData] = useState([]);
    const [uploadData, setUploadData] = useState(null);
    const [jsonData, setJSONData] = useState(null);
    const [error, setError] = useState(null);
    const [csvLimit, setCSVLimit] = useState(false);
    const [templateLoading, setTemplateLoading] = useState(false);
    const [lookup, setLookup] = useState({
        currentNumbersLookup: {},
        addressesLookup: {},
        presentableNumbersLookup: {},
        trunksLookup: {},
    });

    const [addressDescription, setAddressDescription] = useState('');
    const [, setAddresses] = useState([]);
    const [, setAddressesId] = useState([]);

    const { concatAddressFields } = useAddress();

    const [payloads, setPayloads] = useState(null);

    const [verificationChecks, setVerificationChecks] =
        useState(VERIFICATION_CHECKS);
    const [verificationResult, setVerificationResult] = useState(
        DEFAULT_VERIFICATION_RESULT,
    );
    /**
     * for UI Validation progress checks, sets the next index to be in progress.
     * @param {*} i index in VERIFICATION_CHECKS that needs to be updated.
     */
    const changeVerificationChecks = (i) => {
        delay(
            (index) => {
                setVerificationChecks((prev) => {
                    const newVerificationChecks = [...prev];
                    newVerificationChecks[index].check = true;
                    if (index < newVerificationChecks.length - 1) {
                        newVerificationChecks[index + 1].current = true;
                    }
                    return newVerificationChecks;
                });
            },
            750,
            i,
        );
    };

    /**
     * exports data that didnt pass validations.
     */
    const handleExportInvalidData = () => {
        const exportInvalidData = verificationResult.failed.data.map(
            (number) => {
                return Object.keys(FIELDS_TO_EXPORT).reduce((acc, key) => {
                    const value = number[key];
                    const newKey = FIELDS_TO_EXPORT[key];
                    acc[newKey] = value;
                    return acc;
                }, {});
            },
        );
        const res = Papa.unparse(exportInvalidData);
        downloadCSV(res, INVALID_FILENAME);
    };

    /**
     * for UI Validation progress checks, increments current index's completionCounter.
     * @param {*} i index in VERIFICATION_CHECKS that needs to be updated.
     */
    const changeVerificationProgresses = (i) => {
        delay(
            (index) => {
                setVerificationChecks((prev) => {
                    const newVerificationChecks = [...prev];
                    newVerificationChecks[index].completionCounter += 1;
                    return newVerificationChecks;
                });
            },
            750,
            i,
        );
    };

    const dataIsUnchangedFilter = (originalData, uploadedData) =>
        isEqual(originalData, uploadedData);

    /**
     * transforms JSON Data from BE fields into more human readable CSV heading columns
     * @param {*} data
     * @returns
     */
    const transformJSONData = (data) => {
        return data.map((number) => {
            return Object.keys(SHARED_FIELDS_TO_EXPORT).reduce((acc, key) => {
                const value = number[key];
                const newKey = SHARED_FIELDS_TO_EXPORT[key];
                acc[newKey] = value;
                return acc;
            }, {});
        });
    };

    const handleGetCSVData = async () => {
        try {
            setTemplateLoading(true);
            const response = await axios.get(`/company/${companyID}/numbers`);
            const addressesResult = await axios.get(`/addresses/${companyID}`);
            const addressesLookup = keyBy(addressesResult.data, 'id');
            response.data.forEach((number) => {
                number.address = addressesLookup[number.addressID]?.description
                    ? `${
                          addressesLookup[number.addressID].description
                      } - ${concatAddressFields(
                          addressesLookup[number.addressID],
                      )}`
                    : `${concatAddressFields(
                          addressesLookup[number.addressID],
                      )}`;
            });
            setData(response.data);
            setJSONData(transformJSONData(response.data));
        } catch (e) {
            setError(e);
            console.error('error', { e });
            setTemplateLoading(false);
        }
    };

    /** @note this function invokes and call API when uploading csv */
    const handleFetchLookupData = async () => {
        try {
            const allNumbersResult = await axios.get(
                `/company/${companyID}/numbers`,
            );
            changeVerificationProgresses(1);
            const addressesResult = await axios.get(`/addresses/${companyID}`);

            const addresses = addressesResult.data.map((v) => v.description);
            const newAddressById = addressesResult.data.map((item) => ({
                id: item.id,
                description: item.description,
            }));
            setAddresses(addresses);
            setAddressesId(newAddressById);
            //* default address
            setAddressDescription(addressesResult?.data[0]?.description);
            changeVerificationProgresses(1);
            const presentableNumbersResult = await axios.get(
                `/company/${companyID}/numbers/presentable`,
            );
            changeVerificationProgresses(1);
            const trunksResult = await axios.get(`/trunks/${companyID}`);

            const newLookup = {
                currentNumbersLookup: keyBy(allNumbersResult.data, 'id'),
                addressesLookup: keyBy(addressesResult.data, 'description'),
                // addressesLookup: keyBy(addressesResult.data, 'id'),
                presentableNumbersLookup: keyBy(
                    presentableNumbersResult.data,
                    'telephoneNumber',
                ),
                trunksLookup: keyBy(trunksResult.data, 'sipHeader'),
            };

            newLookup.presentableNumbersLookup['Restricted'] = 'Restricted';
            changeVerificationProgresses(1);
            changeVerificationChecks(1);
            setLookup(newLookup);
        } catch (e) {
            setError(e);
            console.error(e);
        }
    };

    /**
     * converts the template from JSON into CSV
     */
    const processTemplate = () => {
        const res = Papa.unparse(jsonData);
        downloadCSV(res, TEMPLATE_FILENAME);
        setTemplateLoading(false);
    };

    const handleDownloadCSVTemplate = async () => {
        await handleGetCSVData();
    };

    //handle Download CSV template
    React.useEffect(() => {
        if (jsonData) {
            processTemplate();
        }
    }, [jsonData]);

    /**
     * use papaparse to convert CSV -> JSON (with webworker).
     * @param {*} data CSV data to be converted to JSON.
     * @returns JSON data
     */
    const convertCSVDataToJSON = async (data) => {
        return new Promise((resolve) => {
            const papaparseConfig = {
                dynamicTyping: true, //for numbers type
                header: true, //CSV has headers
                worker: true, //use worker thread
                complete: function (results) {
                    return resolve(results);
                },
            };
            Papa.parse(data, papaparseConfig);
        });
    };

    /**
     * aggregates data with 0 errors with unlicensedUsersChanged into allSucceedData,
     * aggregates all data with errors & sort them by UPN,
     * aggregates all ignored users with allSucceedData & sort them by (verificationStatus, UPN)
     * @param {data, unlicensedUsersChanged, ignored} param0
     * @returns {allSucceedData}
     */
    const reportResult = ({
        noNeedValidationWithErrors,
        needValidationWithErrors,
        unchanged,
    }) => {
        const failed = concat(
            noNeedValidationWithErrors.filter(haveErrorsFilter),
            needValidationWithErrors.filter(haveErrorsFilter),
        );
        const success = differenceBy(
            concat(noNeedValidationWithErrors, needValidationWithErrors),
            failed,
            'id',
        );
        setVerificationResult((prev) => {
            const newVerificationResult = { ...prev };
            newVerificationResult.passed.data = orderBy(
                concat(unchanged, success).map((v) => ({
                    ...v,
                    verificationStatus: v.ignored
                        ? IGNORED_MESSAGE
                        : SUCCESS_MESSAGE,
                })),
                ['verificationStatus', 'telephoneNumber'],
                ['desc', 'asc'],
            );
            newVerificationResult.failed.data = orderBy(
                failed,
                ['telephoneNumber'],
                ['asc'],
            );
            return newVerificationResult;
        });
        return { allSucceedData: success };
    };

    const handleUploadData = async (e) => {
        if (
            e.target.files[0].type === CSV_TYPE ||
            e.target.files[0].type === CSV_TYPE_FX
        ) {
            const data = e.target.files[0];

            const { data: newDataJSON } = await convertCSVDataToJSON(data);

            // check for csv limits
            if (newDataJSON.length > appConfig.csvLimit) {
                setCSVLimit(true);
                setVerificationChecks((prev) => {
                    const newVerificationChecks = [...prev];
                    newVerificationChecks.forEach((v, index) => {
                        v.check = false;
                        v.current = index === 0; // NOTE: restart at Received CSV check
                        v.completionCounter = 0;
                    });
                    return newVerificationChecks;
                });
                return;
            }

            if (csvLimit) setCSVLimit(false);

            //save data
            // setLoading(true);
            setUploadData(newDataJSON);
            changeVerificationProgresses(0);
            changeVerificationChecks(0);

            //fetch data
            handleFetchLookupData();
        } else {
            window.alert(CSV_TYPE_ERROR);
        }
    };

    const handleMock = () => {
        handleDownloadCSVTemplate();
    };

    //convert CSV columns into friendlier backend's JSON field
    const convertCSVHeaders = (csv) => {
        return csv
            .filter((v) => v['Number ID'])
            .map((row) => {
                let newRow = { errors: [] };
                Object.entries(SHARED_FIELDS_TO_EXPORT).forEach(
                    ([beField, csvColumn]) => {
                        newRow[beField] = row[csvColumn];
                    },
                );

                const originalRow = lookup.currentNumbersLookup[newRow.id];
                if (!newRow.address && originalRow.addressID) {
                    // NOTE: case when user delete address
                    newRow.addressID = newRow.address || '';
                } else {
                    const splitAddress = newRow.address?.split(' - ');
                    const description = splitAddress?.[0];

                    newRow.addressID = newRow.address
                        ? // lookup.addressesLookup[newRow.address]?.id ||
                          lookup.addressesLookup[description]?.id || 'INVALID'
                        : originalRow.addressID;
                }
                newRow.overstampNumber = String(newRow.overstampNumber || '');
                newRow.telephoneNumber = String(newRow.telephoneNumber || '');
                newRow.cliPresentation = String(newRow.cliPresentation || '');
                newRow.trunk = newRow.trunk || '';
                newRow.operatorConnectCallingProfile =
                    newRow.operatorConnectCallingProfile || '';
                return newRow;
            });
    };

    const filterChangedData = (data) => {
        const [unchanged, changed] = partition(data, (row) => {
            const fieldsToCompare = FIELDS_CHANGE_NEED_VALIDATION.concat(
                FIELDS_CHANGE_NO_NEED_VALIDATION,
            );
            const pickedRow = pick(row, fieldsToCompare);
            const originalRow = lookup.currentNumbersLookup[row.id];
            const pickedOriginalRow = pick(originalRow, fieldsToCompare);
            return dataIsUnchangedFilter(pickedRow, pickedOriginalRow);
        });

        const [noNeedValidation, needValidation] = partition(changed, (row) => {
            const pickedRow = pick(row, FIELDS_CHANGE_NEED_VALIDATION);
            const originalRow = lookup.currentNumbersLookup[row.id];
            const pickedOriginalRow = pick(
                originalRow,
                FIELDS_CHANGE_NEED_VALIDATION,
            );
            return dataIsUnchangedFilter(pickedRow, pickedOriginalRow);
        });
        return {
            noNeedValidation,
            needValidation,
            unchanged: unchanged.map((v) => ({
                ...v,
                ignored: true,
            })),
        };
    };

    /**
     *  make sure number is OK and Exists.
     *
     * @param {*} data
     * @returns
     */
    const verifyNumbers = (data) => {
        const [numbersExist, numbersNotExist] = partition(data, (row) => {
            const originNumber = lookup.currentNumbersLookup[row.id];
            return originNumber;
        });
        const [numbersOKAndExist, numbersNotOK] = partition(
            numbersExist,
            (v) => lookup.currentNumbersLookup[v.id].canEdit,
        );

        return {
            numbersNotExist,
            numbersNotOK,
            numbersOKAndExist,
        };
    };

    /**
     * verifies Trunks exists & OC fields are edited properly.
     * TODO: prefill OC fields.
     * @param {*} data
     * @returns
     */
    const verifyTrunks = (data) => {
        const [trunksExist, trunksNotExist] = partition(data, (row) => {
            const originTrunk = lookup.trunksLookup[row.trunk];
            return row.trunk ? Boolean(originTrunk) : true;
        });
        const withOCTrunks = trunksExist.filter(
            (row) =>
                lookup.trunksLookup[row.trunk]?.trunkType?.name ===
                'MS Operator Connect',
        );
        changeVerificationProgresses(4);
        const ocTrunksHaveInvalidCP = withOCTrunks.filter((number) => {
            const trunk = lookup.trunksLookup[number.trunk];
            const callingProfileList = trunk?.callingProfileList ?? [];
            const cpExists = callingProfileList.find(
                (v) => v.name === number.operatorConnectCallingProfile,
            );
            return !cpExists;
        });

        const ocTrunksHaveInvalidTNU = withOCTrunks.filter(
            (number) =>
                !TRUNK_USAGE_VALUES.includes(number.operatorConnectUsageID),
        );
        changeVerificationProgresses(4);
        changeVerificationChecks(4);
        return {
            ocTrunksHaveInvalidCP,
            ocTrunksHaveInvalidTNU,
            trunksNotExist,
        };
    };

    const verifyAddress = (data) => {
        const invalidAddress = data.filter((v) => v.addressID === 'INVALID');

        return {
            invalidAddress,
        };
    };

    const verifyOutboundCallerID = (data) => {
        const invalidOutboundCallerID = data.filter(
            (v) => !lookup.presentableNumbersLookup[v.cliPresentation],
        );

        return {
            invalidOutboundCallerID,
        };
    };

    const flagDataWithErrors = ({
        noValidationNumbersDNE = [],
        noValidationNumbersNotOK = [],
        validationNumbersDNE = [],
        validationNumbersNotOK = [],
        ocTrunksHaveInvalidCP = [],
        ocTrunksHaveInvalidTNU = [],
        trunksNotExist = [],
        invalidAddress = [],
        invalidOutboundCallerID = [],
        data = [],
    }) => {
        NUMBERS_DNE_ERROR;
        NUMBER_NOT_OK_ERROR;
        NO_DATA_FOUND_ERROR;
        INVALID_CP_ERROR;
        INVALID_TNU_ERROR;
        TRUNKS_DNE_ERROR;
        ADDRESS_DNE_ERROR;
        OUTBOUND_DNE_ERROR;
        const errors = concat(
            noValidationNumbersDNE.map((v) => ({
                ...v,
                error: NUMBERS_DNE_ERROR,
            })),
            noValidationNumbersNotOK.map((v) => ({
                ...v,
                error: NUMBER_NOT_OK_ERROR,
            })),
            validationNumbersDNE.map((v) => ({
                ...v,
                error: NUMBERS_DNE_ERROR,
            })),
            validationNumbersNotOK.map((v) => ({
                ...v,
                error: NUMBER_NOT_OK_ERROR,
            })),
            ocTrunksHaveInvalidCP.map((v) => ({
                ...v,
                error: INVALID_CP_ERROR,
            })),
            ocTrunksHaveInvalidTNU.map((v) => ({
                ...v,
                error: INVALID_TNU_ERROR,
            })),
            trunksNotExist.map((v) => ({ ...v, error: TRUNKS_DNE_ERROR })),
            invalidAddress.map((v) => {
                if (v.address.split(',')?.length > 1) {
                    return {
                        ...v,
                        error: 'Cannot identify addresses based on the description.',
                    };
                }
                // description is not provided || lack a space
                if (v.address.length < addressDescription?.length) {
                    return {
                        ...v,
                        error: 'Please check your address description',
                    };
                }
                return {
                    ...v,
                    error:
                        // TODO: need to check the case if user enter ID instead of description
                        v.address.length === addressDescription?.length &&
                        v.address !== addressDescription
                            ? ADDRESS_WRONG_ERROR
                            : ADDRESS_DNE_ERROR,
                };
            }),
            invalidOutboundCallerID.map((v) => ({
                ...v,
                error: OUTBOUND_DNE_ERROR,
            })),
        );
        //temp lookup for numbers data
        const dataJSONTmp = {};

        //make a lookup of errors using UPN as key
        errors.forEach(({ id, error }) => {
            (dataJSONTmp[id] || (dataJSONTmp[id] = [])).push(error);
        });
        //append errors from temp lookup to each number data
        const newDataWithErrors = data.map((v) => ({
            ...v,
            errors: dataJSONTmp[v.id] || [],
        }));

        return { newDataWithErrors };
    };

    const processCSVData = async () => {
        changeVerificationProgresses(2);
        const convertedData = convertCSVHeaders(uploadData);
        changeVerificationProgresses(2);
        const { noNeedValidation, needValidation, unchanged } =
            filterChangedData(convertedData);
        changeVerificationProgresses(2);
        changeVerificationChecks(2);
        if (noNeedValidation.length < 1 && needValidation.length < 1) {
            setError(NO_DATA_FOUND_ERROR);
        }
        const {
            numbersNotExist: noValidationNumbersDNE,
            numbersNotOK: noValidationNumbersNotOK,
            numbersOKAndExist: noValidationNumbersOKAndExist,
        } = verifyNumbers(noNeedValidation);
        changeVerificationProgresses(3);
        const {
            numbersNotExist: validationNumbersDNE,
            numbersNotOK: validationNumbersNotOK,
            numbersOKAndExist: validationNumbersOKAndExist,
        } = verifyNumbers(needValidation);
        changeVerificationProgresses(3);
        changeVerificationChecks(3);
        const {
            ocTrunksHaveInvalidCP,
            ocTrunksHaveInvalidTNU,
            trunksNotExist,
        } = verifyTrunks(validationNumbersOKAndExist);
        const { invalidAddress } = verifyAddress(validationNumbersOKAndExist);
        changeVerificationProgresses(5);
        changeVerificationChecks(5);
        const { invalidOutboundCallerID } = verifyOutboundCallerID(
            validationNumbersOKAndExist,
        );
        changeVerificationProgresses(6);
        changeVerificationChecks(6);

        const { newDataWithErrors: noNeedValidationWithErrors } =
            flagDataWithErrors({
                noValidationNumbersDNE,
                noValidationNumbersNotOK,
                data: noNeedValidation,
            });
        const { newDataWithErrors: needValidationWithErrors } =
            flagDataWithErrors({
                validationNumbersDNE,
                validationNumbersNotOK,
                ocTrunksHaveInvalidCP,
                ocTrunksHaveInvalidTNU,
                trunksNotExist,
                invalidAddress,
                invalidOutboundCallerID,
                data: needValidation,
            });
        const { allSucceedData } = reportResult({
            noNeedValidationWithErrors,
            needValidationWithErrors,
            unchanged,
        });

        transformDataToPayload({
            data: allSucceedData,
            noValidationNumbersOKAndExist,
        });
    };

    const transformDataToPayload = ({
        data,
        noValidationNumbersOKAndExist,
    }) => {
        const newPayload = data.map((v) => {
            const apiData = lookup.currentNumbersLookup[v.id];
            const numbersData = {
                ...apiData,
                ...v,
            };
            const doesNotNeedLookup = noValidationNumbersOKAndExist.find(
                (number) => number.id === v.id,
            );
            if (doesNotNeedLookup) {
                return numbersData;
            }
            const trunkObject = lookup.trunksLookup[v.trunk] || {};
            const isOCTrunk =
                trunkObject?.trunkType?.name === 'MS Operator Connect';
            const operatorConnectCallingProfileID = isOCTrunk
                ? (trunkObject?.callingProfileList ?? []).find(
                      (cp) => cp.name === v.operatorConnectCallingProfile,
                  )?.id
                : v.operatorConnectCallingProfileID;

            return {
                ...numbersData,
                //trunks fields
                trunkID: trunkObject?.id, //can be null
                sipHeader: v.trunk,
                trunkType: trunkObject?.trunkType?.name,
                operatorConnectCallingProfileID,
            };
        });
        setPayloads(newPayload);
    };

    /**
     * once lookups are available, process our uploaded CSV.
     */
    useEffect(() => {
        processCSVData();
    }, [lookup]);

    return {
        handleDownloadCSVTemplate,
        handleMock,
        handleUploadData,
        templateLoading,
        verificationChecks,
        uploadData,
        verificationResult,
        payloads,
        handleExportInvalidData,
        error,
        csvLimit,
    };
}
