import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { initialState } from '../constants';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useEditAPIUser,
    useGetAPIUser,
    useDeleteAPIUser,
    useResetAPIUser,
} from '../queries';
import { LoadingIndicator } from '@/components/v4';
import walkthroughIds from '../../walkthroughIds';
import FormBase from 'components/forms/new/FormBase';
import APIUserPermissionSetDropdown from '../formFields/APIUserPermissionSetDropdown';
import APIUserName from '../formFields/APIUserName';
import APIUserExpiration from '../formFields/APIUserExpiration';
import APIUserManageAccess from '../formFields/APIUserManageAccess';
import DeleteModal from '../modals/DeleteModal';
import ResetModal from '../modals/ResetModal';
import APIKeyModal from '../modals/APIKeyModal';

export default function ApiUserEdit() {
    const { ufBack, ufCreate } = walkthroughIds.adminApiUsers;
    const { darkMode } = useSelector((state) => state.settings);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { apiUserId } = useParams();

    const date = new Date();

    const { currentCompany } = useSelector((state) => state.navigationLists);
    const { requiredScope: accountScope } = useSelector((state) => state.login);

    const [apiUser, setApiUser] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteBusy, setIsDeleteBusy] = useState(false);
    const [isResetBusy, setIsResetBusy] = useState(false);
    const [apiKey, setApiKey] = useState('');

    const setLoadingFalse = () => {
        setIsLoading(false);
    };
    const setLoadingTrue = () => {
        setIsLoading(true);
    };

    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose,
    } = useDisclosure();

    const {
        isOpen: isResetOpen,
        onOpen: onResetOpen,
        onClose: onResetClose,
    } = useDisclosure();

    const {
        isOpen: isApiOpen,
        onOpen: onApiOpen,
        onClose: onApiClose,
    } = useDisclosure();

    const {
        data: apiUserData,
        status: apiUserStatus,
        isFetching: apiUserLoading,
        isRefetching,
    } = useGetAPIUser(
        apiUserId,
        setApiUser,
        setLoadingFalse,
        setLoadingTrue,
        setApiKey,
    );

    const { mutate: editAPIUser } = useEditAPIUser(apiUser, dispatch, navigate);

    const { mutate: deleteAPIUser } = useDeleteAPIUser(
        apiUserId,
        dispatch,
        navigate,
        setIsDeleteBusy,
    );

    const { mutate: resetAPIUser } = useResetAPIUser(
        apiUserId,
        dispatch,
        navigate,
        setIsResetBusy,
        onResetClose,
        setApiKey,
        onApiOpen,
    );

    useEffect(() => {
        if (!(apiUserLoading || isRefetching)) {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchingData =
        apiUserStatus === 'loading' || apiUserLoading || isRefetching;

    if (fetchingData) return <LoadingIndicator />;

    const handleFormSwitchChange = (e) => {
        const { name, checked } = e.target;
        setApiUser((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const finalFormSubmit = (value) => {
        if (apiUser.expirationDate.toISOString() > date.toISOString()) {
            let selectedExpiration;
            if (apiUser?.expirationTime) {
                const selectedDate = moment(apiUser.expirationDate);
                selectedDate.set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                });
                selectedExpiration = moment(selectedDate);
            } else {
                const resetTime = moment(apiUser.expirationDate).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                });
                selectedExpiration = moment(resetTime);
            }
            const finalSubmit = {
                ...value,
                expiration: selectedExpiration,
                permissionSetID: apiUser?.permissionSetID,
            };

            setApiUser(finalSubmit);
            editAPIUser();
        }
    };

    const handleBack = () => {
        navigate('/admins', { state: { index: 1 } });
    };

    if (apiUserData?.companyID !== currentCompany) {
        handleBack();
    }

    const breadCrumbTrail = [
        { label: 'Admin Accounts', path: ['/admins', { index: 1 }] },
        { label: 'Edit API User', path: '' },
    ];

    const header = 'API User Details';

    if (isLoading) return <LoadingIndicator />;

    return (
        <>
            <FormBase
                breadCrumbTrail={breadCrumbTrail}
                header={header}
                initialValues={apiUser}
                finalFormSubmit={finalFormSubmit}
                backlink={['/admins', { state: { index: 1 } }]}
                ufBackBtn={ufBack}
                ufSaveBtn={ufCreate}>
                {({ errors, touched, values }) => [
                    <APIUserName
                        key={'apiUserName'}
                        darkMode={darkMode}
                        apiUser={apiUser}
                        errors={errors}
                        touched={touched}
                    />,
                    <APIUserPermissionSetDropdown
                        key={'apiUserPermissionSetDropdown'}
                        permissionSetID={apiUser.permissionSetID}
                        currentCompany={currentCompany}
                        accountScope={accountScope}
                        setApiUser={setApiUser}
                        errors={errors}
                        touched={touched}
                        darkMode={darkMode}
                        values={values}
                    />,
                    <APIUserExpiration
                        key={'apiUserExpiration'}
                        darkMode={darkMode}
                        apiUser={apiUser}
                        setApiUser={setApiUser}
                        errors={errors}
                        touched={touched}
                    />,
                    <APIUserManageAccess
                        key="manageAccess"
                        apiUser={apiUser}
                        handlFormSwitchChange={handleFormSwitchChange}
                        darkMode={darkMode}
                        onResetOpen={onResetOpen}
                        onDeleteOpen={onDeleteOpen}
                        apiUserId={apiUserId}
                    />,
                ]}
            </FormBase>
            <DeleteModal
                isDeleteOpen={isDeleteOpen}
                onDeleteClose={onDeleteClose}
                apiUser={apiUser}
                isDeleteBusy={isDeleteBusy}
                apiUserLoading={apiUserLoading}
                deleteAPIUser={deleteAPIUser}
            />
            <ResetModal
                isResetOpen={isResetOpen}
                onResetClose={onResetClose}
                isResetBusy={isResetBusy}
                apiUserLoading={apiUserLoading}
                handleReset={resetAPIUser}
            />
            <APIKeyModal
                apiKey={apiKey}
                setApiKey={setApiKey}
                onClose={onApiClose}
                isOpen={isApiOpen}
                handleBack={handleBack}
                darkMode={darkMode}
            />
        </>
    );
}
